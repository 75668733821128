import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserRoles } from "@models/UserRoles";

@Injectable()
export class RouteRedirectionService {
    constructor(private router: Router) {}

    redirect(roles: UserRoles[]) {
        if (roles.indexOf(UserRoles.SUPER_ADMIN) !== -1) {
            void this.router.navigate(["/admin-dashboard"]);
        } else if (roles.indexOf(UserRoles.SALES_MANAGER) !== -1) {
            void this.router.navigate(["/sales-dashboard"]);
        } else {
            void this.router.navigate(["/dashboard"]);
        }
    }
}
