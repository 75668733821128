import { CampaignLanguage } from "@models/CampaignLanguage";
import { Asset } from "@builder-models/Asset";
import { UtilService } from "src/app/core/util-services/util.service";
import * as constants from "../../constants";
import { Outcome } from "./Outcome";

const util = new UtilService();

export enum QuizGameDataInteractionType {
    CLICK = "click",
    SWIPE = "swipe",
}

export class QuizGameDataFactory {
    static getInitial(
        quizType: QuizGameDataInteractionType,
        images: Asset[],
        languages: CampaignLanguage[]
    ): QuizGameData {
        const thankYouImage = images.find((elem) => elem.key === "thank-you");
        images = images.slice(0, 3);
        return {
            interactionType: quizType,
            correctAnswerColor: "rgba(111, 189, 51, 1)",
            incorrectAnswerColor: "rgba(255, 95, 109, 1)",
            questions: images.map((image, idx) => ({
                id: null,
                orderNumber: idx,
                questions: languages.map((l) => ({
                    id: null,
                    parentId: null,
                    languageId: l.id,
                    text: `${l.name} Question ${idx} Text`,
                    imageUrl: image.url,
                    correctPopupText: null,
                    correctPopupTitle: null,
                    incorrectPopupText: null,
                    incorrectPopupTitle: null,
                })),
                answers: [
                    {
                        id: null,
                        answers: languages.map((l) => ({
                            id: null,
                            parentId: null,
                            imageUrl: "",
                            languageId: l.id,
                            text: `${l.name} Answer 1${idx} Text`,
                        })),
                    },
                    {
                        id: null,
                        answers: languages.map((l) => ({
                            id: null,
                            parentId: null,
                            imageUrl: "",
                            languageId: l.id,
                            text: `${l.name} Answer 2${idx} Text`,
                        })),
                    },
                ],
                correctAnswerId: null,
                correctAnswerIndex: null,
            })),
            outcomes: [
                {
                    id: null,
                    downThreshold: 0,
                    upThreshold: 0,
                    outcomes: [
                        {
                            id: null,
                            title: null,
                            text: null,
                            languageId: null,
                            image: thankYouImage.url,
                        },
                    ],
                },
            ],
            popupsEnabled: false,
            questionsLimit: 1,
            questionsLimitEnabled: false,
            shuffleAnswers: false,
            shuffleQuestions: false,
        };
    }
}

export class QuizGameData {
    interactionType: QuizGameDataInteractionType;
    correctAnswerColor: string;
    incorrectAnswerColor: string;
    questions: Question[];
    outcomes: QuizOutcome[];
    popupsEnabled: boolean;
    questionsLimit: number;
    questionsLimitEnabled: boolean;
    shuffleAnswers: boolean;
    shuffleQuestions: boolean;
}

export class Question {
    id: string = null;
    orderNumber: number = null;
    questions: DQuestion[] = [];
    answers: Answer[] = [];
    correctAnswerId: string = null;
    correctAnswerIndex: any = null;

    static getDefault(languages: CampaignLanguage[]): Question {
        return {
            id: "NEWID_" + util.generateRandomString(30),
            orderNumber: 0,
            questions: languages.map((language) =>
                DQuestion.getDefault(language)
            ),
            answers: [],
            correctAnswerId: null,
            correctAnswerIndex: null,
        };
    }

    /** normal is without translation objects,
     * is used just for rendering question on page */
    static toNormal(q: Question, languageId: string): NormalQuestion {
        return {
            id: q.id,
            image: q.questions.find((qq) => qq.languageId === languageId)
                .imageUrl,
            text: q.questions.find((qq) => qq.languageId === languageId).text,
            answers: q.answers.map((a) => Answer.toNormal(a, languageId)),
        };
    }
}
/** normal is without translation objects,
 * is used just for rendering question on page */
export class NormalQuestion {
    id: string;
    image: string;
    text: string;
    answers: NormalAnswer[];
}

export class DQuestion {
    id: string;
    languageId: string;
    parentId: string;
    text: string;
    imageUrl: string;
    correctPopupText: string = null;
    correctPopupTitle: string = null;
    incorrectPopupText: string = null;
    incorrectPopupTitle: string = null;

    constructor(id, languageId, parentId, text, image) {
        this.id = id;
        this.languageId = languageId;
        this.parentId = parentId;
        this.text = text;
        this.imageUrl = image;
    }

    static getDefault(language: CampaignLanguage) {
        return {
            id: null,
            languageId: language.id,
            parentId: null,
            text: "Dummy question",
            imageUrl: constants.defaultImage,
            correctPopupText: null,
            correctPopupTitle: null,
            incorrectPopupText: null,
            incorrectPopupTitle: null,
        };
    }
}

export class Answer {
    id: string;
    answers: DAnswer[];

    static getDefault(languages: CampaignLanguage[]): Answer {
        return {
            id: null,
            answers: languages.map((lang) => DAnswer.getDefault(lang)),
        };
    }

    /** normal is without translation objects,
     * is used just for rendering question on page */
    static toNormal(a: Answer, languageId: string): NormalAnswer {
        return {
            id: a.id,
            text: a.answers.find((aa) => aa.languageId === languageId).text,
        };
    }
}

/** normal is without translation objects,
 * is used just for rendering question on page */
export class NormalAnswer {
    id: string;
    text: string;
}

export class DAnswer {
    id: string;
    languageId: string;
    parentId: string;
    text: string;

    constructor(id, languageId, parentId, text) {
        this.id = id;
        this.languageId = languageId;
        this.parentId = parentId;
        this.text = text;
    }

    static getDefault(language: CampaignLanguage) {
        return {
            id: null,
            languageId: language.id,
            parentId: null,
            text: "Dummy answer",
        };
    }
}

export class QuizOutcome {
    id: string;
    downThreshold: number;
    upThreshold: number;
    outcomes: Outcome[];
    protected?: boolean;
    orderNumber?: number;
}
