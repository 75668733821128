
export enum CampaignStatus {
    ALL = "ALL",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    DELETED = "DELETED",
    SCHEDULED = "SCHEDULED",
    DRAFT = "DRAFT",
}

export class CampaignStatusFactory {
    static getDefaultTypes(): Array<{ id: string; text: string }> {
        return [
            {
                id: CampaignStatus.ALL,
                text: "All",
            },
            {
                id: CampaignStatus.ACTIVE,
                text: "Active",
            },
            {
                id: CampaignStatus.DRAFT,
                text: "Draft",
            },
            {
                id: CampaignStatus.INACTIVE,
                text: "Inactive",
            },
            {
                id: CampaignStatus.SCHEDULED,
                text: "Scheduled",
            },
        ];
    }

    static getAdminTypes(): Array<{ id: string; text: string }> {
        return [
            {
                id: CampaignStatus.ALL,
                text: "All",
            },
            {
                id: CampaignStatus.ACTIVE,
                text: "Active",
            },
            {
                id: CampaignStatus.DRAFT,
                text: "Draft",
            },
            {
                id: CampaignStatus.INACTIVE,
                text: "Inactive",
            },
            {
                id: CampaignStatus.SCHEDULED,
                text: "Scheduled",
            },
            {
                id: CampaignStatus.DELETED,
                text: "Deleted",
            },
        ];
    }
}
