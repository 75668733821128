import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "app-number-input",
    templateUrl: "number-input.component.html",
    styleUrls: ["number-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        },
    ],
})
export class NumberInputComponent implements ControlValueAccessor {

    @Input() labelText: string;
    @Input() options: string;
    @Input() placeholder = "";

    @Input() showClear = false;
    @Input() clearCallback: () => void = void this.defaultClear;
    @Input() minValue = 0;
    @Input() maxValue: string | number = "infinity";
    @Input() stepValue = "1";
    @Input() isInfinity = false;
    @Input() variant = 1;
    @Input() isDisabled = false;

    constructor() { }
    val = ""; // this is the updated value that the class accesses
    disabled = false;

    onChange: any = () => { };
    onTouch: any = () => { };


    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val;
        this.onChange(this.val);
        this.onTouch(this.val);
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        if (this.isInfinity) {
            this.value = value;
            return;
        }
        let val = value;
        if (this.maxValue !== "infinity" && Number(value) > Number(this.maxValue)) {
            val = this.maxValue;
        }
        if (Number(value) < Number(this.minValue)) {
            val = this.minValue;
        }

        this.value = val;
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    setDisabledState(isDisabled: boolean){
        this.disabled = isDisabled;
    }

    onKeyPressed($event){
        return !isNaN($event);
    }

    increment() {
        if (Number(this.val) + Number(this.stepValue) <= Number(this.maxValue)) {
            this.value = parseFloat((Number(this.val) + Number(this.stepValue)).toFixed(1));
        }
    }

    decrement() {
        if (Number(this.val) - Number(this.stepValue) >= Number(this.minValue)) {
            this.value = parseFloat((Number(this.val) - Number(this.stepValue)).toFixed(1));
        }
    }

    private defaultClear(){
        this.val = "";
        this.onChange("");
    }
}
