import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { of } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ModalService {
    private templates = [];

    constructor(private modalService: NgbModal) {}

    open(
        modal_name: string,
        component = null,
        size = "lg",
        input = null,
        customClass = "",
        returnComponentInstance = false,
        ignoreFormats = "",
        title = "",
        isRemoveModal = false,
        options?: { inputs?: { [key: string]: any } }
    ) {
        const modalConf = {
            id: modal_name,
            modal: null,
        };

        if (modal_name === "unsaved-changes" && component == null) {
            // TODO
            return of(
                confirm(
                    "You have unsaved changes \n\n Press OK to save or Cancel to dismiss"
                )
            );
        }

        const modalRef: NgbModalRef = this.modalService.open(component, {
            centered: true,
            size,
            backdrop: "static",
            keyboard: false,
            windowClass: customClass,
        });

        // bind to @Input
        if (input !== undefined && input !== null) {
            modalRef.componentInstance.data = input;
            modalRef.componentInstance.ignoreFormats = ignoreFormats;
        }

        if (title) {
            modalRef.componentInstance.title = title;
        }

        modalRef.componentInstance.isRemoveModal = isRemoveModal;

        if (options && options.inputs) {
            Object.assign(modalRef.componentInstance, options.inputs);
        }

        modalConf.modal = modalRef;

        this.templates.push(modalConf);

        // return @Output
        return returnComponentInstance
            ? modalRef.componentInstance
            : modalRef.componentInstance.output;
    }

    close(modal_name: string) {
        if (modal_name === "all") {
            this.templates.forEach((el) => {
                el.modal.close();
            });
            this.templates = [];
        }

        const idx = this.templates.findIndex((el) => el.id === modal_name);
        const modalConf = this.templates[idx];

        if (modalConf && modalConf.modal) {
            modalConf.modal.close();
            this.templates.splice(idx, 1);
        }
    }
}
