import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {LocalStorageKeys} from "@models/LocalStorageKeys";
import {UserType} from "@models/UserTypes";
import {UserRoles} from "@models/UserRoles";
import {LocalStorageService} from "src/app/core/util-services/local-storage.service";
import {Me} from "@models/Me";

@Injectable()
export class ContextService {
    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService
    ) {}

    getUserDetails(userType: string): Observable<any> {
        if (userType === UserType.USER) {
            return this.getLoggedInUserDetails();
        } else {
            console.warn("context - unknown user type in when asking for user details");
        }
    }

    // #TODO, return value should not be any
    getLoggedInUsersType(): Observable<any> {
        try {
            // #TODO logging - console.log("fetching user type from LOCAL STORAGE")
            return of(this.getLoggedInUsersTypeFromLocalStorage());
        } catch (e) {
            // #TODO logging - console.log("fetching user type from BACKEND")
            return this.getLoggedInUsersTypeFromBackend().pipe(map((response: { data: string }) => response.data));
        }
    }

    getLoggedInUsersTypeFromBackend() {
        return this.http.get(environment.apiUrlPrefix + "/user-management/users/logged-in/type");
    }

    getLoggedInUsersTypeFromLocalStorage() {
        return this.localStorage.getItem(LocalStorageKeys.USER_TYPE);
    }

    getLoggedInUserDetails() {
        return this.http.get(environment.apiUrlPrefix + "/user-management/user-details/logged-in");
    }

    getLoggedInUserRole() {
        return this.http.get(environment.apiUrlPrefix + "/user-management/users/logged-in/roles");
    }

    isOtpEnabledOrRequired(username: string) {
        return this.http
            .get(environment.apiUrlPrefix + "/user-management/users/is-otp-set/" + username)
            .pipe(map((response: { data: boolean }) => response.data));
    }

    getMe() {
        try {
            return this.localStorage.getItem(environment.contextKeyName);
        } catch (e) {
            return null;
        }
    }

    canManageUsers() {
        const ALLOWED_ACCESS_ROLES = [
            UserRoles.USER_ADMIN,
            UserRoles.SUPER_USER_ADMIN,
        ];

        return (
            this.getLoggedInUserRole() as Observable<{ data: UserRoles[] }>
        ).pipe(
            map(({ data }) => data),
            map((roles) => roles.some((role) => ALLOWED_ACCESS_ROLES.includes(role)))
        );
    }

    hasAccessToV2: (me: Me) => boolean = (me) => {
        const company = me?.company;
        return company?.tags?.includes("builder2access") || false;
    };

}
