import { Component, OnInit } from "@angular/core";
import { Me } from "@models/Me";
import { Router } from "@angular/router";
import { ContextService } from "@services/context.service";
import { CampaignService } from "@services/campaign.service";
@Component({
    selector: "app-create-campaign-button",
    templateUrl: "./create-campaign-button.component.html",
    styleUrls: ["./create-campaign-button.component.scss"],
})
export class CreateCampaignButtonComponent implements OnInit {
    me: Me;

    constructor(
        private router: Router,
        private context: ContextService,
        private campaignService: CampaignService,
    ) {}

    ngOnInit() {
        this.me = this.context.getMe();
    }

    hasPermissionAndAccess() {
        return this.context.hasAccessToV2(this.me) && this.campaignService.checkCampaignPermission();
    }

    openWizard() {
        void this.router.navigate(["/wizard"]);
    }
}
