import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validators } from "@angular/forms";

@Directive({
    selector: "[customValidator]",
    providers: [{ provide: NG_VALIDATORS, useExisting: CustomValidatorDirective, multi: true }],
})
export class CustomValidatorDirective {
    updateValidator = () => {};

    private pattern = ".*";

    @Input("customValidator") set customValue(value: string){
        this.setCustomValue(value);
    }

    constructor() {}

    validate(control: AbstractControl): { [key: string]: any } {
        return Validators.pattern(this.pattern)(control);
    }

    private setCustomValue(value: any) {
        this.pattern = value;
        this.updateValidator();
    }
}
