import { forkJoin, Observable, of, throwError } from "rxjs";
import { CampaignDataService } from "src/app/core/services/campaign-data/campaign-data.service";
import { CampaignService } from "src/app/core/services/campaign.service";
import { AssetsService } from "src/app/core/services/campaign-data/assets.service";
import { catchError, defaultIfEmpty, map, switchMap } from "rxjs/operators";
import { GameDataService } from "@services/campaign-data/game-data/game-data.service";
import { UtilService } from "src/app/core/util-services/util.service";
import { Injectable } from "@angular/core";
import { CampaignLanguage } from "@models/CampaignLanguage";
import { ConstantsService } from "@services/constants.service";
import { CampaignMetadata } from "@models/CampaignMetadata";
import { ResourcesService } from "@services/campaign-data/resources.service";
import { Page } from "../../models/Page";
import { BuilderData } from "../../models/BuilderData";
import { BuilderAssets } from "../../models/BuilderAssets";
import { LocalizationData } from "../../models/LocalizationData";
import { PageTemplate } from "../../models/PageTemplate";
import { AdventCalendarParsingService } from "../../game-settings/advent-calendar-settings/services/advent-calendar-parsing.service";

@Injectable()
export class FetchDataService {
    constructor(
        private constants: ConstantsService,
        private campaignData: CampaignDataService,
        private campaignService: CampaignService,
        private assetsService: AssetsService,
        private gameDataService: GameDataService,
        private resourcesService: ResourcesService,
        private adventParsingService: AdventCalendarParsingService,
        private util: UtilService
    ) {}

    fetchAllData(campaignId: string): Observable<BuilderData> {
        // check existance
        return this.campaignService
            .exists(campaignId)
            .pipe(
                switchMap((data) =>
                    data
                        ? this.fetchAll(campaignId)
                        : throwError("Campaign not found")
                )
            );
    }

    fetchAll(campaignId: string): Observable<any> {
        const builderData = new BuilderData();

        builderData.campaignId = campaignId;

        return of("start").pipe(
            switchMap(() =>
                forkJoin([
                    this.campaignData.getCampaignConfiguration(campaignId),
                    this.campaignService.getCampaign(campaignId),
                ])
            ),
            map((response) => {
                const config: any = response[0];
                const metadata: CampaignMetadata = response[1];

                builderData.config = config;
                builderData.metadata = metadata;
            }),

            switchMap(() =>
                this.assetsService.fetchBuilderAssets(
                    campaignId,
                    builderData.config.campaignType,
                    builderData.config.templateId
                )
            ),
            map(
                (builderAssets: BuilderAssets) =>
                    (builderData.assets = builderAssets)
            ),

            switchMap(() =>
                this.fetchPageTemplates(campaignId, builderData.config.pages)
            ),
            map(
                (pageTemplates: PageTemplate[]) =>
                    (builderData.pageTemplates = pageTemplates)
            ),

            switchMap(() =>
                this.fetchLocalizationFiles(
                    campaignId,
                    this.constants.languages.filter((l) =>
                        builderData.config.languageOptions.languages.includes(
                            l.shortName
                        )
                    )
                )
            ),
            map((i18n: LocalizationData[]) => (builderData.i18n = i18n)),

            switchMap(() =>
                this.campaignData.getPageHTMLData(campaignId, "index")
            ),
            map((htmlFile) => (builderData.indexHtmlTemplate = htmlFile)),

            switchMap(() => this.fetchCssData(campaignId)),
            map(([indexCss, mainCssTemplate]) => {
                builderData.indexCss = indexCss;
                builderData.mainCssTemplate = mainCssTemplate;
            }),

            switchMap(() =>
                this.campaignData.getControllerTemplate(
                    campaignId,
                    builderData.config.campaignType
                )
            ),
            map((htmlData: string) => {
                builderData.controllerTemplate = htmlData;

                /** for advent calendar default tile config is embeded in HTML text
                 * extract it and save to localstorage
                 * it is used when reseting to default styles when doing direct tile editing
                 */
                if (builderData.config.campaignType.includes("calendar")) {
                    const { templateId } = builderData.config;

                    const defaultTileConfig =
                        this.adventParsingService.parseHtmlForDefaultStyles(
                            htmlData
                        );

                    const jsonString = JSON.stringify(defaultTileConfig);

                    if (!localStorage.getItem(templateId)) {
                        localStorage.setItem(templateId, jsonString);
                    }
                }
                /** */
            }),

            switchMap(() =>
                this.gameDataService.getGameData(
                    builderData.config.campaignType,
                    builderData.campaignId
                )
            ),
            map((gameData: any) => (builderData.gameData = gameData)),
            switchMap(() => of(builderData))
        );
    }

    fetchLocalizationFiles(campaignId: string, languages: CampaignLanguage[]) {
        return forkJoin(
            ...languages.map((lang) =>
                this.campaignData.getLanguageFile(campaignId, lang.shortName)
            )
        ).pipe(
            defaultIfEmpty([]),
            catchError(() => throwError("Error fetching assets")),
            map((languageFiles: string[]) =>
                this.util
                    .zip(languages, languageFiles)
                    .map(
                        ([lang, langFile]) =>
                            new LocalizationData(lang, langFile)
                    )
            )
        );
    }

    fetchPageTemplates(campaignId: string, pages: Page[]) {
        return forkJoin(
            ...pages.map((page) =>
                this.campaignData.getPageHTMLData(campaignId, page.key)
            )
        ).pipe(
            defaultIfEmpty([]),
            catchError(() => throwError("Error fetching page templates")),
            map((pageFiles: string[]) =>
                this.util
                    .zip(pages, pageFiles)
                    .map(
                        ([page, pageFile]) =>
                            new PageTemplate(page.key, pageFile, page.type)
                    )
            )
        );
    }

    fetchCssData(campaignId: string) {
        return forkJoin([
            this.campaignData.getCssData(campaignId),
            this.resourcesService.getMainCssTemplate(),
        ]).pipe(catchError(() => throwError("Error fetching css files")));
    }
}
