import { Mail } from "@models/Mail";
import { Outcome } from "./game-data/Outcome";

export enum EmailSettingsType {
    ONE_TO_EVERYONE = 1,
    ONE_PER_OUTCOME = 2,
    NO_MAILS = 3
}

export class EmailSettings {
    type: EmailSettingsType;
    templates: EmailTemplate[];
    outcomes: Outcome[];

    // for EmailType.ONE_TO_EVERYONE
    selectedEmailTemplate?: EmailTemplate;
}

export class EmailSettingsFactory {

    static getDefaultTypes(): Array<{id: number; text: string}> {
        return [
            {
                id: EmailSettingsType.ONE_TO_EVERYONE,
                text: "Same email for all results",
            },
            {
                id: EmailSettingsType.ONE_PER_OUTCOME,
                text: "Different email for each result",
            },
            {
                id: EmailSettingsType.NO_MAILS,
                text: "Don't send e-mails",
            },
        ];
    }

    static getCalendarTypes(): Array<{id: number; text: string}> {
        return [
            {
                id: EmailSettingsType.ONE_PER_OUTCOME,
                text: "Different email for each result",
            },
            {
                id: EmailSettingsType.NO_MAILS,
                text: "Don't send e-mails",
            },
        ];
    }

    static getSurveyTypes(): Array<{id: number; text: string}> {
        return [
            {
                id: EmailSettingsType.ONE_TO_EVERYONE,
                text: "Send e-mail",
            },
            {
                id: EmailSettingsType.NO_MAILS,
                text: "Don't send e-mails",
            },
        ];
    }
}

export class Sender {
    id: number;
    name: string;
    email: string;
    uuid: string;
    campaignId: string;
}

export class EmailTemplate {
    id: string;
    mails: Mail[];

    constructor(id: string, mails: Mail[] = []) {
        this.id = id;
        this.mails = mails;
    }
}

export class EmailFileData {
    emailTemplateId: string;
    language: string;
    url: string;
}

export class EmailAttachment {
    filename: string;
    url: string;
}

export const DEFAULT_EMAIL_TEMPLATE = "default-email";

export const DEFAULT_EMAIL_SUBJECT = "Default";
