import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UtilService } from "src/app/core/util-services/util.service";
import { Service } from "@builder-services/core/service";
import { CampaignStatus } from "@models/CampaignStatus";

@Component({
    selector: "app-item-list",
    templateUrl: "item-list.component.html",
    styleUrls: ["item-list.component.scss"],
})
export class ItemListComponent {
    @Input() items = [];
    @Input() options = [];
    @Input() showAddIcon = false;
    @Input() maxWordLength = 40;
    @Output() onItemCreateClick = new EventEmitter();
    @Output() onItemSelectClick = new EventEmitter();
    @Output() onItemDeleteClick = new EventEmitter();
    @Output() onItemUpdateClick = new EventEmitter();

    constructor(private util: UtilService, private service: Service) {}

    isUpdateAble = false;
    campaignStatus: CampaignStatus;

    ngOnInit() {
        this.isUpdateAble = this.onItemUpdateClick.observers.length > 0;
        this.campaignStatus = this.service.metadata.status;
        if (this.campaignStatus === CampaignStatus.ACTIVE) {
            this.items.map((item) => (item.protected = true));
        }
    }

    ngOnChanges(changes) {
        if (
            changes.items.currentValue &&
            changes.items.currentValue.length > 0
        ) {
            this.items = this.util
                .copy(this.items)
                .map((el) => this.setProtectedFlag(el));
        }
    }

    // TODO
    // THIS SHOULD BE REMOVED AND NEW CONFIGURATION SHOULD BE IMPLEMENTED TO SUPPORT THIS
    setProtectedFlag(item) {
        // memory outcomes
        if (item.upTimeThreshold && item.upTimeThreshold === -1) {
            item.protected = true;
        }

        // emails
        if (item.key && item.key === "default-email") {
            item.protected = true;
        }

        return item;
    }
    /// //

    arrayToString(array: any[], field: string) {
        const filtered = [];
        array.map((item) => {
            filtered.push(item[field]);
        });

        return filtered.join(", ");
    }

    onSelectClick(e, item) {
        this.onItemSelectClick.emit(item);
        e.stopPropagation();
    }

    onDeleteClick(e, item) {
        this.onItemDeleteClick.emit(item);
        e.stopPropagation();
    }

    onUpdateClick(e, item) {
        this.onItemUpdateClick.emit({
            ...item,
            clientX: e.clientX,
            clientY: e.clientY,
        });
        e.stopPropagation();
    }

    onAddClick(e) {
        this.onItemCreateClick.emit(true);
        e.stopPropagation();
    }
}
