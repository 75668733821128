
import { throwError as observableThrowError,  Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";


import { AuthService } from "../auth/auth.service";

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

    constructor(
        public auth: AuthService,
        private router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has("No-Auth")) {
            return next.handle(request.clone({
                headers: request.headers.delete("No-Auth"),
            }));
        }

        if (request.url.startsWith("http")
            && request.url.split(".com/")[0] !== "https://brame-client.s3.eu-central-1.amazonaws"
            && this.auth.getToken()
            // B20-13273 fix
            && !request.url.includes("/user-management/users/log-in")
            && !request.url.includes(environment.apiUrlPrefix + "/user-management/users/check-otp/")) {
            request = request.clone({
                url: request.url,
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`,
                },
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const authorizationHeader = event.headers.get("Authorization");
                    if (authorizationHeader) {
                        const token = authorizationHeader.replace("Bearer ", "");
                        this.auth.setCookie(environment.cookies.brame_at, token);
                    }
                }
                return event;
            }),
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        this.auth.logout();
                        void this.router.navigate(["/login"]);
                    }
                    /// case when user token is invalid
                    if (err.status === 440) {
                        this.auth.logout();
                        void this.router.navigate(["/login"]);
                    }

                    // solves B20-5682
                    if (err.status === 403 && (err.url.endsWith("time-zone/offset") || err.url.endsWith("time-zone/changes"))) {
                        this.auth.logout();
                        void this.router.navigate(["/login"]);
                    }

                    return observableThrowError(err);
                }
            })
        );
    }
}
