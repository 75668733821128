import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
    providedIn: "root",
})
export class LanguageStorageService {
    storageKey = "language";
    localLanguage = "en";

    constructor(private localStorageService: LocalStorageService) {}

    getLanguage(): string {
        try {
            this.localLanguage = this.localStorageService.getItem(
                this.storageKey
            );
            return this.localLanguage;
        } catch (err) {
            this.localStorageService.setItem(
                this.storageKey,
                this.localLanguage
            );
            return this.localLanguage;
        }
    }
}
