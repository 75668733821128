import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    Output,
    ViewChild,
    EventEmitter,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UtilService } from "src/app/core/util-services/util.service";
import { ModalService } from "../../modal/modal.service";
import { WarningModalComponent } from "../warning-dialog/warning-dialog.component";

@Component({
    selector: "app-file-upload",
    templateUrl: "file-upload.component.html",
    styleUrls: ["file-upload.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileInputComponent),
            multi: true,
        },
    ],
})
export class FileInputComponent implements ControlValueAccessor {
    @Input() labelText: string;
    @Input() imageName = "image";
    @Input() placeholderText: string = null;
    @Input() defaultIcon: string = null;
    @Input() typeAllowed =
    "image/png, image/jpeg, image/jpg, application/pdf, image/gif, image/webp, pdf, png, jpeg, jpg, gif, jfif, webp";
    @Input() isButton = false;
    @ViewChild("file") file: ElementRef;
    @Output() fileName: EventEmitter<string> = new EventEmitter();

    fileReader: FileReader = new FileReader();
    fileLoaded = false;
    allowedFileSize = 5; // in megabytes

    val: any = ""; // this is the updated value that the class accesses
    filename = "";
    name = "";

    onChange: any = () => {};
    onTouch: any = () => {};

    constructor(private modalService: ModalService, public util: UtilService) {
        this.fileReader.onloadend = () => {
            this.val = this.fileReader.result;

            this.filename = this.imageName;

            // set base64 string as value
            this.onChange(this.val);

            // to avoid on change to be called
            this.fileLoaded = true;
            setTimeout(() => (this.fileLoaded = false), 1000);
        };
    }

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    get value() {
        return this.val;
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.val = value;
        if (value) {
            this.filename = this.imageName;
        } else {
            this.filename = "";
        }
    }

    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = (e) => {
            if (this.fileLoaded) {
                return;
            }
            const files = this.file.nativeElement.files;
            if (this.checkFileSize(files)) {
                this.modalService.open(
                    WarningModalComponent.dialogName,
                    WarningModalComponent,
                    "md",
                    "The size of asset should be smaller or equal then 5MB. Please compress the asset and try again."
                );
                return;
            }
            const extension = files[0].name.split(".").pop();
            if (
                this.typeAllowed.indexOf(files[0].type) === -1 ||
                this.typeAllowed.indexOf(extension) === -1
            ) {
                this.modalService.open(
                    WarningModalComponent.dialogName,
                    WarningModalComponent,
                    "md",
                    "Unsupported file extension. Please upload file with one of the following extensions .pdf, .png, .jpeg, .jpg, .gif, .webp"
                );
                return;
            }

            this.name = files[0].name;
            this.fileName.next(this.name);
            if (files.length > 0) {
                this.fileReader.readAsDataURL(files[0]);
            }

            fn(e);
        };
    }

    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    checkFileSize(files) {
        const fileSize = files[0].size / (1024 * 1024);
        return fileSize >= this.allowedFileSize;
    }

    checkIfBase64(filePath: string) {
        return this.util.isBase64(filePath);
    }

    isPdf(filePath: string) {
        return (
            (this.checkIfBase64(filePath) &&
                this.util.getFileType(filePath) === "application/pdf") ||
            (!this.checkIfBase64(filePath) &&
                this.util.getFileExtension(filePath) === "pdf")
        );
    }
}
