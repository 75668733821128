import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validators } from "@angular/forms";

@Directive({
    selector: "[integerValue]",
    providers: [{ provide: NG_VALIDATORS, useExisting: IntegerValueDirective, multi: true }],
})
export class IntegerValueDirective {
    updateValidator = () => {};

    private integer = "^[1-9][0-9]*$";

    @Input("integerValue") set integerValue(value: string){
        this.setIntegerValue(value);
    }

    constructor() {}

    validate(control: AbstractControl): { [key: string]: any } {
        return Validators.pattern(this.integer)(control);
    }

    private setIntegerValue(value: any) {
        this.integer = value;
        this.updateValidator();
    }
}
