import { Component } from "@angular/core";
import { VersionNotificationService } from "./version-notification.service";

@Component({
    selector: "app-version-notification",
    templateUrl: "version-notification.component.html",
    styleUrls: ["version-notification.component.scss"],
})
export class VersionNotificationComponent {
    isVisible = false;

    constructor(
        private versionNotificationService: VersionNotificationService,
    ) {
        this.versionNotificationService
            .returnAsObservable()
            .subscribe((val) => {
                this.isVisible = val;
            });
    }

    reload() {
        this.versionNotificationService.hide();
        window.location.reload();
    }
}
