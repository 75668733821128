<label class="big-switch" *ngIf="!withConfirmation">
    <span *ngIf="isChecked" class="big-switch__text">ON</span>
    <input type="checkbox" [checked]="isChecked" (change)="toggle()" [disabled]="isDisabled">
    <span class="big-switch__slider"></span>
    <span *ngIf="!isChecked" class="big-switch__text">OFF</span>
</label>

<label class="big-switch" *ngIf="withConfirmation">
  <span *ngIf="isChecked" class="big-switch__text">ON</span>
  <input type="checkbox" [checked]="isChecked" (click)="clickToggle($event)" [disabled]="isDisabled">
  <span class="big-switch__slider"></span>
  <span *ngIf="!isChecked" class="big-switch__text">OFF</span>
</label>