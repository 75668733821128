import { Component, Input, OnInit } from "@angular/core";
import { CampaignLanguage } from "@models/CampaignLanguage";
import { CampaignLanguageService } from "@services/http/campaign-languages.service";
import { UtilService } from "src/app/core/util-services/util.service";
import { Service } from "@builder-services/core/service";
import { environment } from "src/environments/environment";
import { CampaignDataService } from "@services/campaign-data/campaign-data.service";
import { saveAs } from "file-saver";

@Component({
    selector: "app-campaign-link",
    templateUrl: "./campaign-link.component.html",
    styleUrls: ["./campaign-link.component.scss"],
})
export class CampaignLink implements OnInit {
    @Input() linkData;
    languageFlag;
    downloadLink: string;
    successMessage: boolean;

    constructor(
        private campaignDataService: CampaignDataService,
        private util: UtilService,
        private campaignLanguageService: CampaignLanguageService,
        public service: Service
    ) {}

    ngOnInit() {
        const campaignLanguage: CampaignLanguage = (this.languageFlag =
            this.campaignLanguageService.getByShortName(
                this.linkData.language
            ));
        this.languageFlag = campaignLanguage.flagImageUrl;
        // this.campaignStatus = this.service.metadata.status;
        this.generateDownloadLink();
    }

    copyLinkToClipboard(val) {
        this.util.copyValueToClipboard(val);
        this.successMessage = true;
        setTimeout(() => {
            this.successMessage = false;
        }, 3000);
    }

    closeSuccessMessage() {
        this.successMessage = false;
    }

    goToLink(url: string) {
        window.open(url, "_blank");
    }

    generateDownloadLink() {
        this.downloadLink = `${environment.campaignDataUrlPrefix}/api/download/campaignData/${this.service.campaignId}`;
    }

    download() {
        const campaignId =
            this.downloadLink.split("/")[
                this.downloadLink.split("/").length - 1
            ];
        this.campaignDataService
            .downloadCampaignData(campaignId)
            .subscribe((data) => saveAs(data, `${campaignId}.zip`));
    }

    copyCodeToClipboard(val) {
        this.util.copyValueToClipboard(val);
        this.successMessage = true;
        setTimeout(() => {
            this.successMessage = false;
        }, 3000);
    }
}
