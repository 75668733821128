import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS } from "@angular/forms";

@Directive({
    selector: "[minTrimmedCharacters]",
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MinTrimmedCharactersDirective,
            multi: true,
        },
    ],
})
export class MinTrimmedCharactersDirective {
    updateValidator = () => {};
    private min = 0;

    @Input("minTrimmedCharacters") set minTrimmedCharacters(value: number) {
        this.setMinTrimmedCharacters(value);
    }

    constructor() {}

    validate(control: AbstractControl): { [key: string]: any } {
        const trimmedValue = control.value ? control.value.trim() : "";
        if (trimmedValue.length >= this.min) {
            return null;
        } else {
            return { minTrimmedCharacters: { min: this.min } };
        }
    }

    private setMinTrimmedCharacters(value: number) {
        this.min = value;
        this.updateValidator();
    }
}
