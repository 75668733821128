import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-search-input",
    templateUrl: "./search-input.component.html",
    styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent {
    @Input() customClass?: string;
    @Output() getSearchTerm = new EventEmitter<string>();

    searchTerm: string;
    constructor() { }

    search() {
        this.getSearchTerm.next(this.searchTerm);
    }

    clear() {
        this.searchTerm = "";
        this.getSearchTerm.next(this.searchTerm);
    }
}
