export class NewEmail {
    key: string;
    name: string;
    attachmentFile: any;
    subject: string;
    attachmentFilename?: string;
}

export const imageTypes = {
    jpeg: "jpg",
    jpg: "jpg",
    png: "png",
    pdf: "pdf",
    gif: "gif",
};
