import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Service } from "@builder-services/core/service";
import { CampaignType } from "@models/CampaignTypes";
import { ErrorMessage } from "@models/ErrorMessage";

import { ModalService } from "../../modal/modal.service";

@Component({
    selector: "app-error-modal",
    templateUrl: "./error-modal.component.html",
    styleUrls: ["./error-modal.component.scss"],
})
export class ErrorModalComponent implements OnInit {
    @Input() data: ErrorMessage;

    static readonly dialogName: string = "error-modal";
    campaignType: CampaignType;
    showCloseBtn: boolean;

    constructor(
        private router: Router,
        private modalService: ModalService,
        private service: Service
    ) { }

    ngOnInit(): void {
        this.campaignType = this.service.config.campaignType;
        this.showCloseBtn = this.campaignType === CampaignType.PERSONALITY_TEST || this.campaignType === CampaignType.GUESS_THE_PICTURE;
    }

    onOpenDashboard() {
        this.modalService.close(ErrorModalComponent.dialogName);

        if (this.showCloseBtn) {
            void this.router.navigateByUrl(`/campaign/details/${this.service.campaignId}`);
        } else {
            void this.router.navigateByUrl("/dashboard");
        }

    }

    onClose() {
        this.modalService.close(ErrorModalComponent.dialogName);
    }
}
