<div class="link-holder">
    <span class="icon-holder"><img src={{languageFlag}} class="icon"/></span>
    <span class="url-holder">{{linkData.link | trimWord:40}}</span>
    <span class="button-wrapper">

    <span class="button-holder"
        (click)="download()"
        placement="bottom"
        ngbTooltip="Download game files"
          [attr.data-testid]="'download-files-btn'"
        tooltipClass="green-tooltip">
        <img src="assets/builder/svg/download-game-files-icon.svg" alt="Download files"/>
    </span>

    <span class="button-holder"
        (click)="copyCodeToClipboard(linkData.iframe)"
        placement="bottom"
        ngbTooltip="Get iFrame embeded code"
        tooltipClass="green-tooltip">
        <img src="assets/builder/svg/get-iFrame-code-icon.svg" alt="Get iFrame code"/>
    </span>

    <span class="button-holder"
        (click)="copyLinkToClipboard(linkData.link)"
        placement="bottom"
        ngbTooltip="Copy link"
          [attr.data-testid]="'copy-link-btn'"
        tooltipClass="green-tooltip">
        <img src="assets/builder/svg/copy-text-icon.svg" alt="Copy link"/>
    </span>

    <span class="button-holder"
        (click)="goToLink(linkData.link)"
        placement="bottom"
        ngbTooltip="Open in new tab"
        tooltipClass="green-tooltip"
          [attr.data-testid]="'open-new-tab-btn'">
        <img src="assets/builder/svg/open-link-icon.svg" alt="Open link"/>
    </span>
</span>
</div>
<ngb-alert
    class="alert"
    *ngIf="successMessage"
    type="success"
    (close)="closeSuccessMessage()"
>
Successfully copied to clipboard!
</ngb-alert>