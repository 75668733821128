import {Component, Input} from "@angular/core";

@Component({
    selector: "app-stepper",
    templateUrl: "stepper.component.html",
    styleUrls: ["stepper.component.scss"],
})

export class StepperComponent {
    @Input() steps: string[]; // ex. ["Company details", "User details", "Offer details"];
    @Input() activeStep: number;
    constructor() {
    }
}
