import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class BuildHashService {
    buildFilePath = "assets/build.json";
    buildHash: string;

    constructor() {
        this.buildHash = null;
    }
}
