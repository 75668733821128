<div class="app-modal-header">
  <h1 class="app-modal-title">Create email</h1>
</div>

<form class="app-modal-content" #form="ngForm">
  <app-select-input
    [labelText]="'E-mail Template'"
    [options]="data"
    [(ngModel)]="emailData.key"
    name="emailTemplate"
  ></app-select-input>

  <app-text-input
    [labelText]="'Email name'"
    [showClear]="false"
    [(ngModel)]="emailData.name"
    name="emailName"
    [customValidator]="customValidator"
    required
    [controlErrorAnchor]="anchorEmailName"
    >
    <ng-template controlErrorAnchor #anchorEmailName="controlErrorAnchor"></ng-template>
  </app-text-input>

  <app-text-input
    [labelText]="'Email subject'"
    [showClear]="false"
    [(ngModel)]="emailData.subject"
    name="emailSubject"
    required
    [controlErrorAnchor]="anchorEmailSubject"
    >
      <ng-template controlErrorAnchor #anchorEmailSubject="controlErrorAnchor"></ng-template>
  </app-text-input>

  <app-file-upload
          [(ngModel)]="emailData.attachmentFile"
          [labelText]="'Attachment Upload'"
          name="attachmentFile"
          typeAllowed="image/png, image/jpeg, image/jpg, application/pdf, image/gif, image/webp, pdf, png, jpeg, jpg, gif, bmp, jfif, webp"
          imageName="fileName"
  ></app-file-upload>

  <app-text-input
    *ngIf="emailData.attachmentFile != null"
    [labelText]="'Attachment name'"
    [showClear]="false"
    [(ngModel)]="emailData.attachmentFilename"
    name="attachmentFilename"
    [customValidator]="'^[0-9 A-Z a-z _]+$'"
    required
    [controlErrorAnchor]="anchorEmailAttachment"
    >
    <ng-template controlErrorAnchor #anchorEmailAttachment="controlErrorAnchor"></ng-template>
  </app-text-input>

</form>

<div class="app-modal-footer">
  <app-button
    [text]="'Cancel'"
    [styles]="'light'"
    (output)="onCloseClick()"
  >
  </app-button>
  <app-button
    [text]="'Save'"
    [styles]="'primary'"
    (output)="onSaveClick()"
    [isDisabled]="form.invalid"
  >
  </app-button>
</div>