<div class="app-modal-header">
    <h1 class="app-modal-title">{{ data.title }}</h1>
</div>

<div class="app-modal-content">
    <p class="text-center">{{ data.message }}</p>
</div>

<div class="app-modal-footer justify-content-center">
    <app-button
        (output)="onCloseClick()"
        text="Close"
        [styles]="data.btnClass ? data.btnClass : 'light'"
    ></app-button>
</div>
