import {Injectable} from "@angular/core";
import {CampaignType} from "@models/CampaignTypes";
import {Observable, of} from "rxjs";
import {MemoryService} from "@services/game-data/memory.service";
import {switchMap} from "rxjs/operators";
import {Asset} from "@builder-models/Asset";
import {QuizGameData, QuizGameDataFactory, QuizGameDataInteractionType} from "@models/game-data/QuizGameData";
import {
    GuessThePictureGameData,
    GuessThePictureGameDataFactory,
    GuessThePictureGameDataInteractionType,
} from "@models/game-data/GuessThePictureGameData";
import {QuizService} from "@services/game-data/quiz.service";
import {Campaign} from "@models/Campaign";
import {GuessThePictureService} from "@services/game-data/guess-the-picture.service";
import {PersonalityTestService} from "@services/game-data/personality-test.service";
import {
    PersonalityTestGameData,
    PersonalityTestGameDataFactory,
    PersonalityTestGameDataInteractionType,
} from "@models/game-data/PersonalityTestGameData";
import {AdventCalendarService} from "@services/game-data/advent-calendar.service";
import {AdventCalendarGameData, AdventCalendarGameDataFactory} from "@models/game-data/AdventCalendarGameData";
import {SpinTheWheelService} from "@services/game-data/spin-the-wheel.service";
import {PennySpinTheWheelGameDataFactory} from "@models/game-data/PennySpinTheWheelGameData";
import {SpinTheWheelGameDataFactory, SpinTheWheelInitialData} from "@models/game-data/SpinTheWheelGameData";
import {SurveyGameData, SurveyGameDataFactory} from "@models/game-data/SurveyGameData";
import {SurveyService} from "@services/game-data/survey.service";
import {ScratchCardService} from "@services/game-data/scratch-card.service";
import {ScratchCardGameDataFactory, ScratchCardInitialData} from "@models/game-data/ScratchCardGameData";
import {PennySpinTheWheelService} from "@services/game-data/penny-spin-the-wheel.service";
import {PennySlotMachineService} from "@services/game-data/penny-slot-machine.service";
import {PennySlotMachineGameDataFactory} from "@models/game-data/PennySlotMachineGameData";
import {BipaSpinTheWheelGameDataFactory} from "@models/game-data/BipaSpinTheWheelGameData";
import {InitialMemoryGameData, MemoryGameDataFactory} from "@models/game-data/MemoryGameData";
import {CampaignLanguage} from "@models/CampaignLanguage";
import {AdvancedSpinTheWheelService} from "@services/game-data/advanced-spin-the-wheel.service";
import {AdvancedSpinTheWheelGameDataFactory} from "@models/game-data/AdvancedSpinTheWheelGameData";
import {PennyPathService} from "@services/game-data/penny-path.service";
import {PennyPathGameDataFactory} from "@models/game-data/PennyPathGameData";
import {PennyPenaltyService} from "@services/game-data/penny-penalty.service";
import {PennyPenaltyGameDataFactory} from "@models/game-data/PennyPenaltyGameData";
import {ResourcesService} from "../resources.service";

@Injectable({
    providedIn: "root",
})
export class GameDataService {
    constructor(
        private memoryService: MemoryService,
        private quizService: QuizService,
        private quessThePictureService: GuessThePictureService,
        private personalityTestService: PersonalityTestService,
        private adventCalendarService: AdventCalendarService,
        private scratchCardService: ScratchCardService,
        private spinTheWheelService: SpinTheWheelService,
        private advancedSpinTheWheelService: AdvancedSpinTheWheelService,
        private surveyService: SurveyService,
        private pennySpinTheWheelService: PennySpinTheWheelService,
        private pennySlotMachineService: PennySlotMachineService,
        private resources: ResourcesService,
        private pennyPathService: PennyPathService,
        private pennyPenaltyService: PennyPenaltyService
    ) {}

    getGameData(
        campaignType: CampaignType,
        campaignId: string
    ): Observable<any> {
        switch (campaignType) {
        case CampaignType.MEMORY:
            return this.memoryService.getGameData(campaignId);
        case CampaignType.QUIZ:
            return this.quizService.getGameData(campaignId);
        case CampaignType.GUESS_THE_PICTURE:
            return this.quessThePictureService.getGameData(campaignId);
        case CampaignType.PERSONALITY_TEST:
            return this.personalityTestService.getGameData(campaignId);
        case CampaignType.ADVENT_CALENDAR:
        case CampaignType.EASTER_CALENDAR:
        case CampaignType.WEEKLY_CALENDAR:
        case CampaignType.MONTHLY_CALENDAR:
            return this.adventCalendarService.getGameData(campaignId);
        case CampaignType.SPIN_THE_WHEEL:
            return this.spinTheWheelService.getGameData(campaignId);
        case CampaignType.ADVANCED_SPIN_THE_WHEEL:
            return this.advancedSpinTheWheelService.getGameData(campaignId);
        case CampaignType.PENNY_SPIN_THE_WHEEL:
        case CampaignType.BIPA_SPIN_THE_WHEEL:
            return this.pennySpinTheWheelService.getGameData(campaignId);
        case CampaignType.SURVEY:
            return this.surveyService.getGameData(campaignId);
        case CampaignType.SCRATCH_CARD:
            return this.scratchCardService.getGameData(campaignId);
        case CampaignType.PENNY_SLOT_MACHINE:
            return this.pennySlotMachineService.getGameData(campaignId);
        case CampaignType.PENNY_PATH:
            return this.pennyPathService.getGameData(campaignId);
        case CampaignType.PENNY_PENALTY:
            return this.pennyPenaltyService.getGameData(campaignId);
        }
    }

    saveInitialGameData(
        campaignType: CampaignType,
        campaignId: string,
        templateId: string,
        languages: CampaignLanguage[],
        wizardData: Campaign,
        templateConfig: any,
        companyId: string
    ): Observable<any> {
        let requests = null;

        switch (campaignType) {
        case CampaignType.MEMORY: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((data: Asset[]) => {
                        const initialMemoryGameData: InitialMemoryGameData =
                                MemoryGameDataFactory.getInitial(
                                    data,
                                    languages
                                );
                        return this.memoryService.createMemoryGame(
                            campaignId,
                            initialMemoryGameData
                        );
                    })
                );
            break;
        }

        case CampaignType.QUIZ: {
            const gameMode =
                    wizardData.modeId as QuizGameDataInteractionType;

            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((data: Asset[]) => {
                        const initialData: QuizGameData =
                                QuizGameDataFactory.getInitial(
                                    gameMode,
                                    data,
                                    languages
                                );
                        return this.quizService.createGameData(
                            campaignId,
                            initialData
                        );
                    })
                );
            break;
        }

        case CampaignType.GUESS_THE_PICTURE: {
            const gameMode =
                    wizardData.modeId as GuessThePictureGameDataInteractionType;

            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((data: Asset[]) => {
                        const initialData: GuessThePictureGameData =
                                GuessThePictureGameDataFactory.getInitial(
                                    gameMode,
                                    data,
                                    languages
                                );
                        return this.quessThePictureService.createGameData(
                            campaignId,
                            initialData
                        );
                    })
                );
            break;
        }

        case CampaignType.PERSONALITY_TEST: {
            const gameMode =
                    wizardData.modeId as PersonalityTestGameDataInteractionType;

            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((data: Asset[]) => {
                        const initialData: PersonalityTestGameData =
                                PersonalityTestGameDataFactory.getInitial(
                                    gameMode,
                                    data,
                                    languages
                                );
                        return this.personalityTestService.createGameData(
                            campaignId,
                            initialData
                        );
                    })
                );
            break;
        }

        case CampaignType.MONTHLY_CALENDAR: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap(() => {
                        const initialData: AdventCalendarGameData =
                                AdventCalendarGameDataFactory.getInitial(
                                    languages,
                                    campaignId,
                                    "monthly"
                                );
                        return this.adventCalendarService.createGameData(
                            initialData
                        );
                    })
                );
            break;
        }

        case CampaignType.ADVENT_CALENDAR: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap(() => {
                        const initialData: AdventCalendarGameData =
                                AdventCalendarGameDataFactory.getInitial(
                                    languages,
                                    campaignId,
                                    "advent"
                                );
                        return this.adventCalendarService.createGameData(
                            initialData
                        );
                    })
                );
            break;
        }
        case CampaignType.EASTER_CALENDAR:
        case CampaignType.WEEKLY_CALENDAR: {
            const initialData: AdventCalendarGameData =
                    AdventCalendarGameDataFactory.getInitial(
                        languages,
                        campaignId,
                        "weekly"
                    );
            return this.adventCalendarService.createGameData(initialData);
        }

        case CampaignType.SPIN_THE_WHEEL: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((data: Asset[]) => {
                        const initialData: SpinTheWheelInitialData =
                                SpinTheWheelGameDataFactory.getInitial(
                                    campaignId,
                                    languages,
                                    templateConfig,
                                    data
                                );
                        return this.spinTheWheelService.createGameData(
                            initialData
                        );
                    })
                );
            break;
        }
        case CampaignType.PENNY_SPIN_THE_WHEEL: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((assets: Asset[]) =>
                        this.pennySpinTheWheelService.createGameData(
                            PennySpinTheWheelGameDataFactory.getInitial(
                                campaignId,
                                languages,
                                templateConfig,
                                assets
                            )
                        )
                    )
                );
            break;
        }
        case CampaignType.PENNY_PATH: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((assets: Asset[]) =>
                        this.pennyPathService.createGameData(
                            PennyPathGameDataFactory.getInitial(
                                campaignId,
                                languages,
                                templateConfig,
                                assets
                            )
                        )
                    )
                );
            break;
        }
        case CampaignType.PENNY_PENALTY: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((assets: Asset[]) =>
                        this.pennyPenaltyService.createGameData(
                            PennyPenaltyGameDataFactory.getInitial(
                                campaignId,
                                languages,
                                templateConfig,
                                assets
                            )
                        )
                    )
                );
            break;
        }
        case CampaignType.SURVEY: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((data: Asset[]) => {
                        const initialData: SurveyGameData =
                                SurveyGameDataFactory.getInitial(
                                    data,
                                    languages,
                                    campaignId
                                );
                        return this.surveyService.createGameData(
                            initialData,
                            companyId
                        );
                    })
                );
            break;
        }
        case CampaignType.SCRATCH_CARD: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((data: Asset[]) => {
                        const initialData: ScratchCardInitialData =
                                ScratchCardGameDataFactory.getInitial(
                                    campaignId,
                                    languages,
                                    companyId,
                                    data,
                                    templateConfig
                                );
                        return this.scratchCardService.createGameData(
                            campaignId,
                            initialData
                        );
                    })
                );
            break;
        }
        case CampaignType.PENNY_SLOT_MACHINE: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((assets: Asset[]) =>
                        this.pennySlotMachineService.createGameData(
                            PennySlotMachineGameDataFactory.getInitial(
                                campaignId,
                                languages,
                                templateConfig,
                                assets
                            )
                        )
                    )
                );
            break;
        }
        case CampaignType.BIPA_SPIN_THE_WHEEL: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((assets: Asset[]) =>
                        this.pennySpinTheWheelService.createGameData(
                            BipaSpinTheWheelGameDataFactory.getInitial(
                                campaignId,
                                languages,
                                templateConfig,
                                assets
                            )
                        )
                    )
                );
            break;
        }
        case CampaignType.ADVANCED_SPIN_THE_WHEEL: {
            requests = this.resources
                .getDefaultAsset(campaignType, templateId)
                .pipe(
                    switchMap((assets: Asset[]) =>
                        this.advancedSpinTheWheelService.createGameData(
                            AdvancedSpinTheWheelGameDataFactory.getInitial(
                                campaignId,
                                assets
                            )
                        )
                    )
                );
            break;
        }
        }

        return requests || of("todo handle this no campaign type");
    }
}
