import { Component, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ModalService } from "../../modal/modal.service";
import { imageTypes, NewEmail } from "./models/newEmail";

@Component({
    selector: "app-email-template-modal",
    templateUrl: "./email-template-modal.component.html",
    styleUrls: ["./email-template-modal.component.scss"],
})
export class EmailTemplateModalComponent implements OnInit {
    @Input() data: string[];
    @Output() output: Subject<NewEmail> = new Subject<NewEmail>();
    emailData: NewEmail;
    customValidator = "^[\\w&.\\-]+$";

    constructor(
        private modalService: ModalService) { }

    ngOnInit() {
        this.emailData = {
            key: this.data[0],
            name: "",
            attachmentFile: null,
            subject: "",
            attachmentFilename: "",
        };
    }

    onCloseClick() {
        this.modalService.close("email-template-modal");
    }

    onSaveClick() {
        this.updateFileName();
        this.output.next(this.emailData);
        this.modalService.close("email-template-modal");
    }

    private updateFileName() {
        if (this.emailData.attachmentFilename === "") {
            return;
        }

        const fileExtension = this.emailData.attachmentFile.split(";")[0].split("/")[1];
        this.emailData.attachmentFilename += "." + String(imageTypes[fileExtension]);
    }
}
