import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ResizeAndOrientationService {
    notSupported = false;
    orientationPortrait = false;
    minimumWidth = 992;
    minimumHeight = 540;

    resizeOrentationChange: Subject<any> = new Subject<any>();

    constructor() {
        this.getOrentation();
        this.checkIfSupported();

        window.addEventListener("resize", () => {
            this.getOrentation();
            this.checkIfSupported();
            this.resizeOrentationChange.next({ orientationPortrait: this.orientationPortrait, notSupported: this.notSupported });
        });

        window.addEventListener("orientationchange", () => {
            this.getOrentation();
            this.checkIfSupported();
            this.resizeOrentationChange.next({ orientationPortrait: this.orientationPortrait, notSupported: this.notSupported });
        });
    }

    /*
    check if screen width and height are not smaller then minimum values
    if they are -> set appropriate flag value
    */
    private checkIfSupported() {
        this.notSupported = window.innerWidth < this.minimumWidth || window.innerHeight < this.minimumHeight;
    }

    /* check orientation on screen*/
    private getOrentation() {
        this.orientationPortrait = window.innerHeight > window.innerWidth;
    }

    returnAsObservable() {
        return this.resizeOrentationChange.asObservable();
    }
}
