import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedModule } from "../../shared/shared.module";

import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { VersionNotificationComponent } from "./version-notification/version-notification.component";
import { TutorialsNotificationPopupComponent } from "./tutorials-notification-popup/tutorials-notification-popup.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        LoadingSpinnerComponent,
        VersionNotificationComponent,
        TutorialsNotificationPopupComponent,
    ],
    exports: [
        LoadingSpinnerComponent,
        VersionNotificationComponent,
        TutorialsNotificationPopupComponent,
    ],
})
export class CoreComponentsModule { }
