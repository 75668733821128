import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {SurveyGameData} from "@models/game-data/SurveyGameData";
import {environment} from "../../../../environments/environment";

@Injectable({ providedIn: "root" })
export class SurveyService {
    apiPrefix = environment.surveyApiUrlPrefix + "/game-settings/";

    constructor(private http: HttpClient) {
    }

    getGameData(campaignId: string) {
        return this.http.get(this.apiPrefix + campaignId);
    }

    createGameData(gameData: SurveyGameData, companyId: string) {
        const data = { ...gameData, companyId };
        return this.http.post(this.apiPrefix, data);
    }

    updateGameData(data: SurveyGameData) {
        return this.http.put(this.apiPrefix, data);
    }
}
