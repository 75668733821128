<button 
    *ngIf="type === 'button'" 
    (click)="onBtnClick($event)" 
    class="btn {{ customClass ? customClass : '' }}" 
    [ngClass]="getClass()" 
    [disabled]="isDisabled"
    [class.loading]="isLoading"
    [attr.data-testid]="dataTestId" 
>
    <svg class="spinner" viewBox="0 0 50 50" *ngIf="isLoading">
        <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
    <svg-icon *ngIf="icon" [src]="icon" class="btn-icon"></svg-icon>
    {{ !isLoading ? text : ""}}
</button>

<a
    *ngIf="type === 'link'"
    [href]="url"
    class="btn {{ customClass ? customClass : '' }}"
    [ngClass]="getClass()"
    [attr.data-testid]="dataTestId" 
>
    {{ text }}
</a>
