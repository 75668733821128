import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { QuizGameData, QuizOutcome } from "@models/game-data/QuizGameData";

@Injectable({ providedIn: "root" })
export class QuizService {

    apiPrefix = environment.quizApiBaseUrl;

    constructor(private http: HttpClient) { }

    /**
     * Creates complete game data
     * intended to be used only once, during campaign creation
     *
     * @param campaignId
     * @param data
     */
    createGameData(campaignId: string, data) {
        return this.http.post(this.apiPrefix + `/game-settings/${campaignId}`, data);
    }

    getGameData(campaignId: string) {
        return this.http.get(this.apiPrefix + `/game-settings/${campaignId}`);
    }

    /**
     * @updateData is QuizGameData without question and outcome arrays
     * @questionChanges is object with arrays of created , updated and deleted questions
     * so u know which request to send
     */
    updateGameData(
        campaignId: string,
        data: QuizGameData,
        questionsLimitChanged: boolean,
        numOfQuestionsChanged: boolean
    ) {
        const updateData = { ...data, numOfQuestionsChanged, questionsLimitChanged };

        return this.http.put(`${this.apiPrefix}/game-settings/${campaignId}`, { ...updateData });
    }

    updateOutcomes(campaignId: string, outcomes: QuizOutcome[]) {
        return this.http.put(`${this.apiPrefix}/game-settings/${campaignId}`, { outcomes });
    }
}
