import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CampaignLanguage } from "@models/CampaignLanguage";

@Component({
    selector: "app-languages-dropdown",
    templateUrl: "./languages-dropdown.component.html",
    styleUrls: ["./languages-dropdown.component.scss"],
})
export class LanguagesDropdownComponent {
    private langs: CampaignLanguage[];
    showAllLabel = false;
    @Input()
    set languages(value: CampaignLanguage[]) {
        if (value) {
            this.langs = value.sort((a, b) => a.name.localeCompare(b.name));
            if (this.languages.length > 1) {
                this.showAllLabel = true;
                this.selectedLanguage = null;
            } else {
                this.selectedLanguage = this.langs[0];
            }
        }
    }

    get languages(): CampaignLanguage[] {
        if (this.langs) {
            return this.langs;
        }
        return [];
    }

    @Output() languageSelected = new EventEmitter<CampaignLanguage | null>();
    selectedLanguage?: CampaignLanguage;

    constructor() { }

    onLanguageSelected(language: CampaignLanguage | null): void {
        if (this.selectedLanguage !== language) {
            this.selectedLanguage = language;
            this.languageSelected.next(language);
        }
    }

    get isValidLanguageSelected(): boolean {
        return !!this.selectedLanguage;
    }
}
