<div class="wrapper {{ customClass ? customClass : '' }}">
    <label *ngIf="label"> {{label}} </label>

    <input
        class="input"
        [class.full-width]="!label"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [autoFocus]="autoFocus"
        [class.invalid]="invalid"
        ngx-google-places-autocomplete
        (onAddressChange)="changePlace($event)"
        [value]="val ? val.formattedAddress : ''"
        (focus)="onTouch()"
    >

    <div class="error {{ customErrorClass ? customErrorClass : ''}}">
        <ng-content></ng-content>
    </div>
</div>