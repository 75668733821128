import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserRoles } from "@models/UserRoles";
import { ContextService } from "@services/context.service";
import { map, tap } from "rxjs/operators";
import { WIZARD_PAGE_ACCESS } from "../constants";

@Injectable()
export class WizardGuard implements CanActivate {
    constructor(
        private router: Router,
        private context: ContextService
    ) {}

    canActivate() {
        return this.context.getLoggedInUserRole().pipe(
            map((response: { data: UserRoles[] }) => response.data.some((role) => WIZARD_PAGE_ACCESS.indexOf(role) !== -1)),
            tap((allowed) => !allowed && void this.router.navigate(["/"]))
        );
    }
}
