<div class="multi-select">
    <ng-multiselect-dropdown
            [settings]="settings"
            [placeholder]="placeholder"
            [data]="data"
            [(ngModel)]="selected"
            (onSelect)="onItemSelect($event)"
            (onDropDownClose)="onDropdownClose()"
            (onDeSelect)="onItemDeSelect($event)"
            (onFilterChange)="onFilterChange($event)"
    >
    </ng-multiselect-dropdown>
</div>
