import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map, tap } from "rxjs/operators";
import { ResponseDTO } from "@models/ResponseDTO";


@Injectable({
    providedIn: "root",
})
export class ConstantsService {

    languages: any[];

    constructor(private http: HttpClient) {
        const x = this.getLanguages().subscribe(() => x.unsubscribe());
    }


    getLanguages() {
        return this.http.get(environment.apiUrlPrefix + "/campaign/languages/").pipe(
            map((response: ResponseDTO) => response.data),
            tap((data) => this.languages = data)
        );
    }
}
