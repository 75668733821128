import { Component, Directive, Input } from "@angular/core";

@Directive({
    selector: "[appSimpleAnalyticsCardTitle]",
})
export class SimpleAnalyticsCardTitleDirective {
    constructor() { }
}

@Directive({
    selector: "[appSimpleAnalyticsCardContent]",
})
export class  SimpleAnalyticsCardContentDirective {
    constructor() { }
}

@Component({
    selector: "app-simple-analytics-card",
    templateUrl: "./simple-analytics-card.component.html",
    styleUrls: ["./simple-analytics-card.component.scss"],
})
export class SimpleAnalyticsCardComponent {
    @Input() contentPadding;

    constructor() { }
}
