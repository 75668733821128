import { Injectable } from "@angular/core";
import { HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {tap} from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import {UtilService} from "../../util-services/util.service";

@Injectable({
    providedIn: "root",
})
export class SpendingPointsService {
    private readonly apiBaseUrl = `${environment.serverlessApiBaseUrl}/loyalty/spending-points`;
    private spendingPointsSource = new BehaviorSubject<any>(null);
    spendingPoints$ = this.spendingPointsSource.asObservable();

    constructor(
        private http: HttpClient,
        private utils: UtilService
    ) {
    }
    createSpendingPoints(campaignId: string, data: any): Observable<any> {
        const url = `${this.apiBaseUrl}/${campaignId}`;
        return this.http.post(url, data, this.utils.getHeaders());
    }

    deleteSpendingPoints(campaignId: string): Observable<any> {
        const url = `${this.apiBaseUrl}/${campaignId}`;
        return this.http.delete(url, this.utils.getHeaders());
    }

    getSpendingPoints(campaignId: string): Observable<any> {
        const url = `${this.apiBaseUrl}/${campaignId}`;
        return this.http.get<any>(url, this.utils.getHeaders()).pipe(
            tap((data) => this.spendingPointsSource.next(data))
        );
    }

    updateSpendingPoints(campaignId: string, data: any): Observable<any> {
        const url = `${this.apiBaseUrl}/${campaignId}`;
        return this.http.put(url, data, this.utils.getHeaders());
    }
}
