export const analyticEvents = {
    100: {
        name: "App Init",
    },
    101: {
        name: "User Logged In",
    },
    102: {
        name: "Login Failed",
    },
    103: {
        name: "User Logged Out",
    },
    201: {
        name: "Dashboard Opened",
    },
    202: {
        name: "Search Triggered",
    },
    203: {
        name: "Search Returned",
    },
    204: {
        name: "New Campaign Started",
    },
    205: {
        name: "Campaign Published",
    },
    206: {
        name: "Campaign Updated",
    },
    207: {
        name: "Games Library Opened",
    },
    208: {
        name: "Campaign Deleted",
    },
    209: {
        name: "Campaign Duplicated",
    },
    210: {
        name: "Analytics Opened",
    },
    211: {
        name: "Campaign Opened",
    },
    212: {
        name: "Campaign Stopped",
    },
    400: {
        name: "Cookies Displayed",
    },
    401: {
        name: "Cookies Accepted",
    },
    604: {
        name: "Analytics Reset",
    },
    605: {
        name: "Analytics Leads Removal",
    },
    607: {
        name: "Analytics Leads Export",
    },
};
