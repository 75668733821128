<div class="app-modal-header">
  <h1 class="app-modal-title">Render failed</h1>
</div>

<div class="app-modal-content">
  <p class="text-center">
    <img src="assets/shared/svg/wiz-failure.svg" alt="">
  </p>
  <p class="text-center" [innerHTML]="data.text"></p>
</div>

<div class="app-modal-footer justify-content-center">
  <app-button
      [text]="'Reload page'"
      [styles]="'primary'"
      (output)="onRefresh()"
  >
  </app-button>
</div>