import { EmailSettingsType } from "./EmailSettings";

export class MailType {
    campaignId: string;
    emailType: string;

    constructor(campaignId: string, emailType: EmailSettingsType) {
        this.campaignId = campaignId;
        this.emailType = MailType.mapEmailTypes(emailType);
    }

    static mapEmailTypes(emailType: EmailSettingsType) {
        switch (emailType) {
        case EmailSettingsType.NO_MAILS: {
            return "NOEMAIL";
        }
        case EmailSettingsType.ONE_PER_OUTCOME: {
            return "ONEPEROUTCOME";
        }
        default: {
            return "ONEFORALLPLAYERS";
        }
        }
    }

    static reverseMapEmailTypes(emailType: string) {
        switch (emailType) {
        case "NOEMAIL": {
            return 3;
        }
        case "ONEPEROUTCOME": {
            return 2;
        }
        default: {
            return 1;
        }
        }
    }
}
