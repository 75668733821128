import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS } from "@angular/forms";

@Directive({
    selector: "[maxChars]",
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: MaxCharsDirective,
            multi: true,
        },
    ],
})
export class MaxCharsDirective {
    updateValidator = () => { };
    private max = 0;

    @Input("maxChars") set maxChars(value: string) {
        this.setMaxChars(Number(value));
    }

    constructor() { }

    validate(control: AbstractControl): { [key: string]: any } {
        const chars = control.value;
        if (!chars) {
            return null;
        }

        if (chars.length <= this.max) {
            return null;
        }

        return { maxChars: { max: this.max } };
    }

    private setMaxChars(value: number) {
        this.max = value;
        this.updateValidator();
    }

}
