import {
    Directive,
    HostBinding,
    HostListener,
    Input,
} from "@angular/core";

@Directive({
    selector: "[appImgEnlargeOnHover]",
})
export class ImgEnlargeOnHoverDirective {
    @Input() transformTime = 0.4;
    @Input() transformScale = 3;

    @Input() @HostBinding("style.transition") get transition() {
        return `transform ${this.transformTime}s`;
    }

    @HostBinding("style.transform") transform = "scale(1)";
    @HostBinding("style.-ms-transform") transformMs = "scale(1)";
    @HostBinding("style.-webkit-transform") transformWebKit = "scale(1)";

    constructor() {}

    @HostListener("mouseover") onMouseOver() {
        this.transform = `scale(${this.transformScale})`;
        this.transformMs = `scale(${this.transformScale})`;
        this.transformWebKit = `scale(${this.transformScale})`;
    }

    @HostListener("mouseout") onMouseOut() {
        this.transform = "scale(1)";
        this.transformMs = "scale(1)";
        this.transformWebKit = "scale(1)";
    }
}
