import { Injectable } from "@angular/core";
import { OutcomeService } from "@services/http/outcome.service";
import { map } from "rxjs/operators";
import { Observable, forkJoin } from "rxjs";
import { Mail } from "@models/Mail";
import {
    EmailSettingsType,
    EmailSettings,
    EmailTemplate,
} from "@models/EmailSettings";
import { MailType } from "@models/MailType";
import { Outcome } from "@models/game-data/Outcome";
import { CampaignType } from "@models/CampaignTypes";
import { UtilService } from "src/app/core/util-services/util.service";
import { EmailSlsService } from "./email-sls.service";

@Injectable({
    providedIn: "root",
})
export class EmailSettingsService {
    // whatever

    constructor(
        private emailSlsService: EmailSlsService,
        private outcomeService: OutcomeService,
        private util: UtilService
    ) {}

    setMailSettings(
        campaignId: string,
        campaignType: CampaignType,
        settings: EmailSettings,
        backupSettings: EmailSettings
    ) {
        // eslint-disable-next-line
        const { created, updated, deleted } = this.util.compareArrays(
            backupSettings.outcomes,
            settings.outcomes
        );
        const requests = [
            CampaignType.SURVEY,
            CampaignType.MEMORY,
            CampaignType.SCRATCH_CARD,
            CampaignType.QUIZ,
        ].includes(campaignType)
            ? [
                this.outcomeService.updateAllOutcomes(
                    campaignId,
                    campaignType,
                    settings.outcomes
                ),
            ]
            : updated.map((outcome) =>
                this.outcomeService.update(campaignId, campaignType, outcome)
            );
        const request2 = this.emailSlsService.updateMailType(
            new MailType(campaignId, settings.type)
        );
        requests.push(request2);
        return forkJoin(requests);
    }

    getMailSettings(
        campaignId: string,
        campaignType: CampaignType
    ): Observable<EmailSettings> {
        return (
            forkJoin([
                // figure out mail settings by looking at outcome list
                this.outcomeService
                    .getAllByCampaignId(campaignId, campaignType)
                    .pipe(map((outcomes: Outcome[]) => ({ outcomes }))),
                // fetch all mails and group them by template in EmailTemplate class
                this.emailSlsService.getAllByCampaignId(campaignId).pipe(
                    map((mails: Mail[]) => {
                        const templates: EmailTemplate[] = [];
                        mails.forEach((m) => {
                            let template = templates.find(
                                (t) => t.id === m.emailTemplateId
                            );
                            if (!template) {
                                template = new EmailTemplate(
                                    m.emailTemplateId,
                                    []
                                );
                                templates.push(template);
                            }
                            template.mails.push(m);
                        });
                        return templates;
                    })
                ),
                // get emailSettings type
                this.emailSlsService
                    .getMailType(campaignId)
                    .pipe(
                        map((mailType: any) =>
                            MailType.reverseMapEmailTypes(mailType.emailType)
                        )
                    ),
            ])
                // put all together in EmailSettings
                .pipe(
                    map((response: any) => {
                        const ms = new EmailSettings();
                        ms.outcomes = response[0].outcomes as Outcome[];
                        ms.templates = response[1] as EmailTemplate[];
                        ms.type = response[2] as EmailSettingsType;

                        // if EmailSettingsType.ONE_TO_EVERYONE find which one EmailTemplate
                        ms.selectedEmailTemplate =
                            ms.type === EmailSettingsType.ONE_TO_EVERYONE
                                ? ms.templates.find((el: EmailTemplate) =>
                                    el.mails
                                        .map((e: Mail) => e.id)
                                        .includes(ms.outcomes[0].mailId)
                                )
                                : null;

                        // if EmailSettingsType.ONE_PER_OUTCOME find Array of EmailTemplates per outcome
                        // TODO
                        return ms;
                    })
                )
        );
    }
}
