import { FeatureFlagsService } from "@services/feature-flags/feature-flags.service";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";

@Injectable()
export class FeatureFlagsGuard implements CanActivate {
    constructor(
        private router: Router,
        private featureFlagsService: FeatureFlagsService
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const feature: FeatureFlagConfig = route.data.feature;
        if (this.featureFlagsService.isFeatureFlagEnabled(feature)) {
            return true;
        }

        void this.router.navigate(["/dashboard"]);
        return false;
    }
}

