import {Asset} from "@builder-models/Asset";
import {CampaignLanguage} from "@models/CampaignLanguage";
import {SurveyQuestion} from "../../../features/builder/shared/game-settings/survey-settings/models/Question";
import {AnswerTypeFactory} from "../../../features/builder/shared/game-settings/survey-settings/models/AnswerType";

export class SurveyGameData {
    campaignId: string;
    shuffleQuestions: boolean;
    questions: SurveyQuestion[];
    outcomes: [{
        outcomes: Array<{
            image: string;
            text: string;
            title: string;
            mailId: string;
            languageId: string;
        }>;
    }];
}


export class SurveyGameDataFactory {

    static getInitial(images: Asset[], languages: CampaignLanguage[], campaignId: string): SurveyGameData {
        const thankYouImage = images.find((elem) => elem.key === "thank-you");
        const answerTypes = AnswerTypeFactory.getAnswerTypes();
        images = images.slice(0, 4);

        return {
            campaignId,
            shuffleQuestions: false,
            questions: images.map((image, index) => (
                {
                    type: answerTypes[index].id,
                    imageUrl: image.url,
                    questionTexts: languages.map((language) => (
                        {
                            text: `${language.name} Question ${index} Text`,
                            languageId: language.id,
                        }
                    )),
                    answers: [
                        {
                            answerTexts: languages.map((language) => (
                                {
                                    text: `${language.name} Answer 1${index} Text`,
                                    languageId: language.id,
                                }
                            )),
                            orderNumber: 0,
                        },
                        {
                            answerTexts: languages.map((language) => (
                                {
                                    text: `${language.name} Answer 2${index} Text`,
                                    languageId: language.id,
                                }
                            )),
                            orderNumber: 1,
                        },
                        {
                            answerTexts: languages.map((language) => (
                                {
                                    text: `${language.name} Answer 3${index} Text`,
                                    languageId: language.id,
                                }
                            )),
                            orderNumber: 2,
                        },
                    ],
                    orderNumber: index,
                    answerMandatory: true,
                    minAnswersTreshold: 1,
                    maxAnswersTreshold: 3,
                    showImage: true,
                    shuffleAnswers: false,
                }
            )),
            outcomes: [{
                outcomes: languages.map((language) => (
                    {
                        image: thankYouImage.url,
                        text: `${language.name} outcome text`,
                        title: `${language.name} outcome title`,
                        mailId: null,
                        languageId: language.id,
                    }
                )),
            }],
        };

    }

    static getDefault(campaignId) {
        return {
            campaignId,
            questions: [],
            outcomes: [],
        };
    }

}
