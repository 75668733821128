import {CampaignLanguage} from "@models/CampaignLanguage";
import {Asset} from "@builder-models/Asset";
import {Outcome} from "./Outcome";

const assetOrder: (asset: Asset) => number = (asset) => Number(asset.key.split("-")[0].replace("prize", ""));

const getAssets: (allAssets: Asset[], keySuffix: string) => Asset[] = (allAssets, keySuffix) => {
    const assets = allAssets.filter((asset) => asset.key.endsWith(keySuffix));
    assets.sort((a1, a2) => assetOrder(a1) - assetOrder(a2));
    return assets;
};

const getLegendAssets: (assets: Asset[]) => Asset[] = (assets) => getAssets(assets, "-legend");

const getOutcomeAssets: (assets: Asset[]) => Asset[] = (assets) => getAssets(assets, "-outcome");

export class ScratchCardGameDataFactory {
    static getInitial(id: string, languages: CampaignLanguage[], companyId: string, assets: Asset[], templateConfig: any) {
        return {
            campaignId: id,
            closedCardBorderColour: templateConfig.scratchCardConfig.closedCardBorderColour,
            closedCardColour: templateConfig.scratchCardConfig.closedCardColour,
            /** todo: replace assets.scratch-cover with templateConfig.scratchCardConfig.closedCardImage after template config is fixed
             * currently it's returning invalid url
             **/
            closedCardImageUrl: assets.find((elem) => elem.key === "scratch-cover").url,
            cardAppearance: templateConfig.scratchCardConfig.cardAppearance,
            outcomes: getOutcomeAssets(assets).map((asset) => ({
                outcomes: languages.map((language) => ({
                    image: asset.url,
                    text: `${language.name} outcome text`,
                    title: `${language.name} outcome title`,
                    mailId: null,
                    languageId: language.id,
                })),
            })),
            prizes: getLegendAssets(assets).map((asset) => ({
                prizeImageUrl: asset.url,
                prizeBackgroundColour: "#ffffff",
            })),
            companyId,
        };
    }
}


export class ScratchCardInitialData {
    campaignId: string;
    closedCardBorderColour: string;
    closedCardColour: string;
    closedCardImageUrl: string;
    cardAppearance: string;
    outcomes: Array<{
        outcomes: Array<{
            image: string;
            text: string;
            title: string;
            mailId: string;
            languageId: string;
        }>;
    }>;
    prizes: Array<{
        prizeImageUrl: string;
        prizeBackgroundColour: string;
    }>;
}

export class ScratchCardGameData {
    alwaysWin: boolean;
    campaignId: string;
    cardAppearance: string;
    companyId: string;
    createdAt: any;
    id: string;
    legendEnabled: boolean;
    outcomes: Array<{
        id: string;
        outcomes: Array<{
            id: string;
            image: string;
            languageId: string;
            mailId: any;
            parentOutcomeId: string;
            text: string;
            title: string;
        }>;
    }>;
    prizes: Prize[];
    retryEnabled: boolean;
    closedCardBorderColour: string;
    closedCardColour: string;
    closedCardImageUrl: string;
    playsPerDay: number;
    multiRegistration: boolean;
}

export class Prize {
    id: string;
    index: number;
    isDefault: boolean;
    isRetry: boolean;
    isUnlimited = false;
    orderNumber: number;
    prizeBackgroundColour: any;
    prizeDistributionGive: number;
    prizeDistributionPerSpins: number;
    prizeImageUrl: string;
    prizeLimit: number;
    prizeTitle: string;
}

export class OutcomePrize {
    prize: Prize;
    outcome: Outcome;
}
