export class Outcome {
    id?: string = "";
    languageId: string = null;
    languageCode?: string = null;
    title: string = null;
    text: string = null;
    image = "https://brame-static.s3.eu-central-1.amazonaws.com/game-placeholders/821-200x300.jpg";
    parentOutcomeId ?= null;
    mailId ?= null;
    orderNumber ?= null;
    ctaList?: any;
    socialMediaShare?: any;
    dayNumber?: number;
    uuid?: string;

    constructor(title: string, text: string, image: string) {
        this.title = title;
        this.text = text;
        this.image = image;
    }
}
