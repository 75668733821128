import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ListItem} from "ng-multiselect-dropdown/multiselect.model";
import {SelectSettings} from "./SelectSettings";

@Component({
    selector: "app-multi-select",
    templateUrl: "multi-select.component.html",
})

export class MultiSelectComponent implements OnInit {
    @Input() data: ListItem[];
    @Input() settings: SelectSettings;
    @Input() placeholder = "";
    @Input() selected?: ListItem[] = null;

    @Output() itemSelected: EventEmitter<ListItem> = new EventEmitter<ListItem>();
    @Output() itemDeSelected: EventEmitter<ListItem> = new EventEmitter<ListItem>();
    @Output() search: EventEmitter<string> = new EventEmitter<string>();

    dropdownBtn: HTMLElement;

    constructor() {
    }

    ngOnInit() {
        this.dropdownBtn = document.querySelector(".dropdown-btn");
        this.setPlaceholderText();
        this.setFocus();
    }

    onItemSelect(item: ListItem) {
        this.itemSelected.next(item);
    }

    onItemDeSelect(item: ListItem) {
        this.itemDeSelected.next(item);
    }

    onDropdownClose() {
        this.setPlaceholderText();
    }

    onFilterChange(query: any) {
        this.search.next(query);
    }

    private setFocus() {
        this.dropdownBtn.addEventListener("click", () => {
            const input: HTMLElement = document.querySelector(".filter-textbox input");
            if (input) {
                input.focus();
            }
        });
    }

    private setPlaceholderText() {
        this.dropdownBtn.innerHTML = `<span>${this.placeholder}</span>`;
    }

}
