<div class="app-icon"
    placement="bottom"
    ngbTooltip="{{ tooltipText }}"
    tooltipClass="green-tooltip show"
    [disableTooltip]="!isChecked"
    container="body"
    (click)="resetStyles()"
    [ngClass]="!isChecked ? 'not-checked' : null">
    <svg-icon [src]="iconSrc"></svg-icon>
</div>
