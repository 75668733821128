<div class="stepper">
    <div class="stepper__step" *ngFor="let step of steps; let i = index;"
         [class.active]="activeStep === i + 1"
         [class.checked]="activeStep > i + 1"
    >
        <div class="stepper__step-num">
            <span *ngIf="activeStep <= i + 1">{{ i + 1 }}</span>
            <span *ngIf="activeStep > i + 1">
                <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.35821 15.821L2.08955 9.5523L0 11.6419L8.35821 20.0001L26.2687 2.08961L24.1791 6.10352e-05L8.35821 15.821Z"
                      fill="#F5F5F5"/>
                </svg>
            </span>
        </div>
        <p>{{ step }}</p>
        <div *ngIf="activeStep !== 1 && i !== 0 && activeStep >= i + 1" class="stepper__dash">
            <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
        </div>
    </div>
</div>