import { Component, ElementRef, EventEmitter, Input, Output } from "@angular/core";

import { AccordionItem as IAccordionItem } from "../accordion-item";
import { AccordionComponent } from "../accordion.component";

@Component({
    selector: "app-accordion-item",
    templateUrl: "./accordion-item.component.html",
    styleUrls: ["./accordion-item.component.scss"],
})
export class AccordionItemComponent implements IAccordionItem {
    @Input("title") title = "";

    @Output("closed") closed = new EventEmitter<HTMLElement>();

    expanded = false;

    hide() {
        this.expanded = false;
        this.closed.emit(this.element.nativeElement);
    }

    constructor(
        private element: ElementRef,
        private accordion: AccordionComponent
    ) {}

    scrollIntoView(element: HTMLElement) {
        let elHeight: any;
        window.requestAnimationFrame(() => {
            elHeight = this.element.nativeElement.offsetTop;
            // 289 is the offsetTop of the first element
            element.scrollTo({left:0, top: elHeight - 289, behavior: "smooth"});
        });
    }

    toggle() {
        this.expanded = !this.expanded;
        if (this.expanded) {
            this.accordion.open(this);
            this.scrollIntoView(document.getElementById("inner-container"));
        } else {
            this.accordion.close(this);
            this.closed.emit(this.element.nativeElement);
        }
    }
}
