import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {GuessThePictureGameData, Question, GuessThePictureOutcome} from "@models/game-data/GuessThePictureGameData";
import {forkJoin} from "rxjs";

@Injectable({providedIn: "root"})
export class GuessThePictureService {

    apiPrefix = environment.apiUrlPrefix + "/guess-the-picture";

    constructor(private http: HttpClient) {}

    /**
     * Creates complete game data
     * intended to be used only once, during campaign creation
     *
     * @param campaignId
     * @param data
     */
    createGameData(campaignId: string, data: GuessThePictureGameData) {
        return this.http.post(this.apiPrefix + `/game-settings/${campaignId}`, data);
    }

    getGameData(campaignId: string) {
        return this.http.get(this.apiPrefix + `/game-settings/${campaignId}`);
    }

    updateGameData(
        campaignId: string,
        data: GuessThePictureGameData,
        questionChanges: { created: any[]; deleted: any[]; updated: any[] },
        questionsLimitChanged: boolean,
        numOfQuestionsChanged: boolean
    ) {
        /**
         * @updateData is GuessThePictureGameData without question and outcome arrays
         * @questionChanges is object with arrays of created , updated and deleted questions
         * so u know which request to send
         */

        const updateData = {...data, numOfQuestionsChanged, questionsLimitChanged};
        const response = this.http.put(this.apiPrefix + `/game-settings/${campaignId}`, updateData);
        const createResponse = questionChanges.created.map((q: Question) => this.createQuestion(campaignId, q));
        const deleteResponse = questionChanges.deleted.map((q: Question) => this.deleteQuestion(q));
        const updateResponse = questionChanges.updated.map((q: Question) => this.updateQuestion(campaignId, q));

        return forkJoin([response, ...createResponse, ...deleteResponse, ...updateResponse]);

    }

    createQuestion(campaignId: string, question: Question) {
        return this.http.post(this.apiPrefix + `/question/${campaignId}`, question);
    }

    updateQuestion(campaignId: string, question: Question) {
        return this.http.put(this.apiPrefix + `/question/${campaignId}`, question);
    }

    deleteQuestion(question: Question) {
        return this.http.delete(this.apiPrefix + `/question/${question.id}`);
    }

    createOutcome(campaignId: string, outcome: GuessThePictureOutcome) {
        const data = {
            ...outcome,
            id: undefined,
            outcomes: outcome.outcomes.map((el) => ({...el, id: undefined, uuid: el.uuid} )),
        };
        return this.http.post(this.apiPrefix + `/outcome/${campaignId}`, data);
    }

    updateOutcome(outcome: GuessThePictureOutcome, campaignId: string) {
        return this.http.put(this.apiPrefix + `/outcome/${campaignId}`, outcome);
    }

    deleteOutcome(outcomeId: string) {
        return this.http.delete(this.apiPrefix + `/outcome/${outcomeId}`);
    }
}
