import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";
import {HttpClient} from "@angular/common/http";
import {AdventCalendarGameData} from "@models/game-data/AdventCalendarGameData";
import {map} from "rxjs/operators";
import {forkJoin} from "rxjs";
import {Outcome} from "@models/game-data/Outcome";

@Injectable({
    providedIn: "root",
})
export class AdventCalendarService {
    apiPrefix = environment.serverlessApiBaseUrl + "/calendar-game";

    constructor(private http: HttpClient) { }

    createGameData(data: AdventCalendarGameData) {
        return this.http.post(this.apiPrefix + "/game-settings/", data)
            .pipe();
    }

    getGameData(campaignId: string) {

        return forkJoin([
            this.http.get(this.apiPrefix + `/game-settings/${campaignId}`),
            this.http.get(this.apiPrefix + `/outcomes_sorted?campaign_id=${campaignId}`),
        ]).pipe(
            map((response) => {
                const gameData: any = response[0];
                const sortedOutcomes = response[1] as Outcome[];

                const map = {};
                sortedOutcomes.forEach((o) => {

                    if (map[o.parentOutcomeId]) {
                        map[o.parentOutcomeId].outcomes.push(o);
                    } else {
                        map[o.parentOutcomeId] = { id: o.parentOutcomeId, outcomes: [o] };
                    }

                });

                gameData.outcomes = Object.values(map).reverse();

                return gameData;
            }));
    }

    updateGameData(campaignId: string, data: AdventCalendarGameData) {
        return this.http.put(this.apiPrefix + `/game-settings/${campaignId}`, data).pipe();
    }
}
