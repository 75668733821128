export interface ColumnRule {
    columnName: string;
}

export enum SortingDirection {
    ASC = "ASC",
    DESC = "DESC"
}

export interface SortingRule extends ColumnRule {
    direction: SortingDirection;
}

export interface SearchRule extends ColumnRule {
    searchTerm: string;
}

export interface FilterRule extends ColumnRule {
    value: any;
}

export interface TableFilters {
    search: SearchRule[];
    filter: FilterRule[];
    sorting: SortingRule;
    page: number;
}
