import {Directive, OnInit, ElementRef, Input} from "@angular/core";

@Directive({
    selector: "[autoFocus]",
})
export class AutoFocusDirective implements OnInit {
    @Input() autoFocus;
    constructor(private elementRef: ElementRef) { }

    ngOnInit(): void {
        if (this.autoFocus) {
            this.elementRef.nativeElement.focus();
        }
    }

}
