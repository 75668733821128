<div class="wrapper {{ customClass ? customClass : '' }}">

    <label class="label"> {{labelText}} </label>

    <input class="input"
        [value]="val"
        (input)="onMyChange($event)"
        (blur)="onTouch()"
        type="text"
        autocomplete="off"
        [disabled]="disabled"
        [placeholder]="placeholder">

    <div *ngIf="showClear" class="clear-btn" (click)="clearCallback()">
        <img src="assets/builder/close.png" alt="Close">
    </div>

    <div class="error">
        <ng-content></ng-content>
    </div>
</div>