import { CampaignLanguage } from "@models/CampaignLanguage";

export class LocalizationData {

    language: CampaignLanguage;
    pairs: LocalizationPair[];

    constructor(language = null, languageObject = {}, pairs?: LocalizationPair[]) {
        this.language = language;
        this.pairs = Object.keys(languageObject).map((key) => new LocalizationPair(key, languageObject[key]));
        if (pairs) {
            this.pairs = pairs;
        }
    }

    toLanguageDocument() {
        const jsonDocument = {};
        this.pairs.forEach((pair: LocalizationPair) => jsonDocument[pair.key] = pair.value);
        return jsonDocument;
    }

}

export class LocalizationPair {

    key: string;
    value: string;

    constructor(key = "", value = "") {
        this.key = key;
        this.value = value;
    }

}
