<div class="step-btns">
    <div class="step-btns__btn previous" >
        <div *ngIf="activeStep !== 1 || showBack">
            <span>{{ previousText }}</span>
            <button class="step-btns__previous" (click)="changeStep('previous')">
                <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5854 2.5848L5.18878 10.9998L13.5854 19.4148L11.0004 21.9998L0.000447877 10.9998L11.0004 -0.000196375L13.5854 2.5848Z" fill="#4D9877"/>
                </svg>
            </button>
        </div>
    </div>
    <div class="step-btns__btn next">
        <button class="step-btns__next" (click)="changeStep('next')" [disabled]="isDisabled" data-testid="step-next-button">
            <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.414551 19.4148L8.81122 10.9998L0.414551 2.58483L2.99955 -0.000167847L13.9996 10.9998L2.99955 21.9998L0.414551 19.4148Z" fill="#F5F5F5"/>
            </svg>
        </button>
        <span [ngClass]="{'disabled': isDisabled}" *ngIf="activeStep !== stepCount">{{ nextText }}</span>
        <span [ngClass]="{'disabled': isDisabled}" *ngIf="activeStep === stepCount" data-last-step-text="true">{{ lastStepText }}</span>
    </div>
</div>