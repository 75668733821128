<table class="table">
    <thead>
        <tr>
            <th *ngFor="let o of options" scope="col"> <span class="title-text" [innerHTML]="o.title"></span></th>
            <th scope="col" *ngIf="isUpdateAble"></th>
            <th scope="col">
                <img *ngIf="showAddIcon"
                    src="assets/builder/svg/plus-icon.svg"
                    (click)="onAddClick($event)"
                    class="app-icon-btn pull-right mr-2">
            </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of items" (click)="onSelectClick($event, item)" class="myhover">
            <td *ngFor="let opt of options;let i = index">

                <div *ngIf="opt.type === 'image+title'">
                    <img style="width: 2rem; height: 2rem;" [src]="item[opt.fieldName][opt.languageIdx].image">
                    <ng-template #thirdTipContent>{{item?.title || item[opt.fieldName][opt.languageIdx].title}}</ng-template>
                    <span style="margin-left: 2rem;" [ngbTooltip]="thirdTipContent">{{item?.title || item[opt.fieldName][opt.languageIdx].title | trimWord:maxWordLength}}</span>
                </div>

                <span *ngIf="opt.type === 'image+text'">
                    <img style="width: 2rem; height: 2rem;" [src]="item.image">
                    <ng-template #firstTipContent>{{item.text}}</ng-template>
                    <span class="text-span" style="margin-left: 2rem;"  [ngbTooltip]="firstTipContent">{{item.text | trimWord:maxWordLength}}</span>
                </span>

                <span *ngIf="opt.type === 'image'">
                    <img style="width: 2rem; height: 2rem;" [src]="item.image">
                </span>

                <ng-template #secondTipContent>{{item[opt.fieldName]}}</ng-template>
                <span class="text-span" *ngIf="opt.type === 'text'" [ngbTooltip]="secondTipContent">
                    {{ item[opt.fieldName] | trimWord:maxWordLength }}
                </span>

                <span *ngIf="opt.type === 'number'">
                    {{ item[opt.fieldName] < 0 ? 'infinity' :  item[opt.fieldName] | number: '1.2-2' }}
                </span>

                <span *ngIf="opt.type === 'array'" class="array-span" title="{{ arrayToString(item[opt.fieldName], 'text') }}">
                    {{ arrayToString(item[opt.fieldName], 'text') }}
                </span>
            </td>

            <td class="action-buttons" *ngIf="isUpdateAble">
                <img (click)="onUpdateClick($event, item)" class="myhide"
                    src="assets/builder/svg/icn-edit-icon.svg" alt="Update Item">
            </td>

            <td class="action-buttons">
                <img *ngIf="!item.protected"
                    (click)="onDeleteClick($event, item)" class="myhide"
                    src="assets/builder/close.png" alt="Remove Item">
            </td>
        </tr>
    </tbody>
</table>