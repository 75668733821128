import { Component, Input, Output, EventEmitter } from "@angular/core";

import { ModalService } from "../../modal/modal.service";

@Component({
    selector: "app-warning-dialog",
    templateUrl: "./warning-dialog.component.html",
    styleUrls: ["./warning-dialog.component.scss"],
})
export class WarningModalComponent {
    @Input() data: string;
    @Input() title = "Warning";
    @Input() isRemoveModal = false;
    @Output() output: EventEmitter<boolean> = new EventEmitter();

    static readonly dialogName: string = "warning-dialog";

    constructor(private modalService: ModalService) { }

    onCancelClick() {
        this.modalService.close(WarningModalComponent.dialogName);
        this.output.emit(false);
    }

    onConfirmClick() {
        this.modalService.close(WarningModalComponent.dialogName);
        this.output.emit(true);
    }


}
