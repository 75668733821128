import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { concatMap, map, switchMap, toArray } from "rxjs/operators";
import { Mail } from "@models/Mail";
import { ResponseDTO } from "@models/ResponseDTO";
import { Observable, of, forkJoin } from "rxjs";
import { MailType } from "@models/MailType";
import { Page } from "@builder-models/Page";
import { EmailAttachment, DEFAULT_EMAIL_TEMPLATE, DEFAULT_EMAIL_SUBJECT, Sender } from "@models/EmailSettings";
import { BuildService } from "./build.service";
import { CampaignLanguageService } from "./campaign-languages.service";

interface EmailData {
    languageId: string;
    attachmentUrl: string;
    attachmentFilename: string;
    subject: string;
}

@Injectable({
    providedIn: "root",
})
export class EmailSlsService {

    apiPrefix: string = environment.serverlessApiBaseUrl + "/mailsls/";
    mailTypePrefix: string = this.apiPrefix + "mailType/";

    constructor(
        private http: HttpClient,
        private languages: CampaignLanguageService,
        private build: BuildService,
    ) { }

    create(mail: Mail) {
        const endpoint = `${this.apiPrefix}mailsls`;
        return this.http.post(endpoint, mail).toPromise();
    }

    update(mail: Mail) {
        const endpoint = `${this.apiPrefix}mailsls`;
        return this.http.put(endpoint, mail).pipe();
    }

    getAllByCampaignId(campaignId: string) {
        return this.http.get(this.apiPrefix + `getAll/${campaignId}`);
    }

    deleteAllByCampaignId(campaignId: string) {
        return this.http.delete(this.apiPrefix + `deleteAll/${campaignId}`);
    }

    updateMailType(mailType: MailType) {
        return this.http.put(this.mailTypePrefix, mailType);
    }

    getMailType(campaignId: string) {
        return this.http.get(this.mailTypePrefix + `${campaignId}`);
    }

    deleteMailType(campaignId: string) {
        return this.http.delete(this.mailTypePrefix + `${campaignId}`);
    }

    createEmailTemplate(
        campaignId: string,
        templateKey: string,
        data: EmailData = null,
        sourceTemplateKey: string = DEFAULT_EMAIL_TEMPLATE,
    ) {
        return this.createEmailPage(campaignId, templateKey, sourceTemplateKey).pipe(
            switchMap((page) => this.build.generateEmails(campaignId, page)),
            switchMap((files) => {
                const emails: Mail[] = files.map((file) => ({
                    campaignId,
                    contentUrl: file.url,
                    emailTemplateId: file.emailTemplateId,
                    language: this.languages.getByShortName(file.language).id,
                    subject: DEFAULT_EMAIL_SUBJECT,
                    attachmentUrl: data && this.languages.getByShortName(file.language).id === data.languageId ? data.attachmentUrl : "",
                    attachmentFilename: data && this.languages.getByShortName(file.language).id === data.languageId ? data.attachmentFilename : "",
                }));

                const requests = emails.map((e) => {
                    if (!data || data.languageId !== e.language) {
                        return this.create(e);
                    }
                    if (!data.attachmentUrl) {
                        return this.create({ ...e, subject: data.subject });
                    }
                    const { attachmentFilename, attachmentUrl, subject } = data;
                    return this.createEmailAttachment(campaignId, attachmentUrl, attachmentUrl).pipe(
                        switchMap(({ url }) => this.create({...e, attachmentUrl: url, attachmentFilename, subject }))
                    );
                });

                return forkJoin(requests);
            })
        );
    }

    createEmailTemplates(campaignId: string, payload: Array<{ templateKey: string; data: EmailData }>) {
        return of(...payload).pipe(
            concatMap((data) => this.createEmailTemplate(campaignId, data.templateKey, data.data)),
            toArray()
        );
    }

    createEmailPage(campaignId: string, emailTemplateId: string, originalEmialTemplateId: string): Observable<Page> {
        const data = {
            campaignId,
            emailTemplateId,
            originalEmialTemplateId,
        };

        return this.http.post(environment.campaignDataUrlPrefix + "/api/email/", data)
            .pipe(
                map((response: ResponseDTO) => response.data)
            );
    }

    createEmailAttachment(campaignId: string, attachment: string, fileName: string): Observable<EmailAttachment> {
        const data = { attachment, fileName };
        return this.http.post(environment.campaignDataUrlPrefix + `/api/email/attachment/${campaignId}`, data) as Observable<EmailAttachment>;
    }

    getSender(campaignId: string): Observable<Sender> {
        return this.http.get<Sender>(this.apiPrefix + `sender/${campaignId}`);
    }

    updateSender(campaignId: string, data: { [key: string]: string}) {
        return this.http.put(this.apiPrefix + `sender/${campaignId}`, data);
    }

    deleteSender(campaignId: string) {
        return this.http.delete(this.apiPrefix + `sender/${campaignId}`);
    }
}
