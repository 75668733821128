import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { DeployType } from "@models/PublishOptions";
import { Page } from "@builder-models/Page";
import { Observable } from "rxjs";
import { EmailFileData } from "@models/EmailSettings";
import { CampaignType } from "@models/CampaignTypes";
import { UtilService } from "src/app/core/util-services/util.service";
import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";
import { FeatureFlagsService } from "@services/feature-flags/feature-flags.service";

@Injectable({
    providedIn: "root",
})
export class BuildService {
    /** service for communication with build-microservice */

    apiPrefix = `${environment.buildServiceApiUrlPrefix}/api`;

    constructor(
        private http: HttpClient,
        private utils: UtilService,
        private featureFlagsService: FeatureFlagsService,
    ) {}

    publish(
        companyId: string,
        campaignId: string,
        campaignType: CampaignType,
        mode: DeployType,
        preciseGeolocation: boolean,
        cookieless: boolean,
        analyticsTrackingLevel: number,
    ) {
        const endpoint = this.utils.resolveCampaignEndpoint(campaignType);

        const data = {
            campaignId,
            campaignType,
            publishOptions: {
                mode,
                endpoint,
                companyId,
                cookieless,
                analytics: {
                    geolocation: preciseGeolocation,
                    endpoint: environment.analyticsEndpoint,
                    eventsEndpoint: environment.eventsApiBaseUrl,
                    analyticsTrackingLevel,
                    events: [],
                    anonymousAnalyticsEnabled: this.featureFlagsService.isFeatureFlagEnabled(FeatureFlagConfig.CAMPAIGN_COUNTER_EVENTS),
                    GAMES_WITH_BACKEND_EVENTS:
                        environment.backendAnalyticsEvents.SUPPORTED_GAMES,
                    SUPPORTED_BACKEND_EVENTS:
                        environment.backendAnalyticsEvents.SUPPORTED_EVENTS,
                },
            },
        };

        let publishEndpoint =
            this.apiPrefix +
            (this.utils.isPennyCampaign(campaignType)
                ? "/publish-penny"
                : "/publish");
        if (campaignType === CampaignType.BIPA_SPIN_THE_WHEEL) {
            publishEndpoint = this.apiPrefix + "/publish-bipa-spin-the-wheel";
        }
        return this.http.post(publishEndpoint, data);
    }

    generateEmails(
        campaignId: string,
        page: Page
    ): Observable<EmailFileData[]> {
        const data = {
            campaignId,
            emailTemplateId: page.key,
        };

        return this.http.post(
            this.apiPrefix + "/generate-emails",
            data
        ) as Observable<EmailFileData[]>;
    }

    deactivate(campaignId: string, mode: DeployType) {
        const data = {
            campaignId,
            publishOptions: {
                mode,
            },
        };

        return this.http.put(this.apiPrefix + "/deactivate", data);
    }
}
