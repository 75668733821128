import { Directive, ElementRef, Input } from "@angular/core";

type Overflow = "ellipsis" | "clip";

@Directive({
    selector: "[appTextOverflow]",
})
export class TextOverflowDirective {
    @Input() set appTextOverflow(overflow: Overflow) {
        this.apply(overflow);
    }

    constructor(private element: ElementRef) {}

    private apply(overflow: Overflow) {
        const element = this.element.nativeElement as HTMLElement;
        element.style.overflow = "hidden";
        element.style["max-width"] = "100%";
        element.style["white-space"] = "nowrap";
        element.style["text-overflow"] = overflow;
    }
}
