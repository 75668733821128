<div class="wrapper" *ngIf="!isButton">
  <label class="label">{{ labelText }}</label>

  <div class="input" style="padding-top: 0.7rem;">
    {{ placeholderText || filename || ('sharedComponents.logoUpload' | translate) }}
  </div>
  <input
    #file
    name="x"
    type="file"
    class="test input"
    (input)="onChange($event.target.value)"
    accept={{typeAllowed}}
    [ngbTooltip]="placeholderText"
  />

  <div *ngIf="defaultIcon && !filename" class="clear-btn">
    <img [src]="defaultIcon" alt="Icon">
  </div>

  <div *ngIf="filename" class="clear-btn">
    <img [src]="val" alt="File" *ngIf="!isPdf(val)">
    <img [src]="'assets/shared/pdf.png'" *ngIf="isPdf(val)" alt="File"/>
  </div>
</div>

<div class="wrapper btn-file-upload" *ngIf="isButton">
  <input
      #file
      name="x"
      type="file"
      (input)="onChange($event.target.value)"
      accept="{{typeAllowed}}"
      [ngbTooltip]="placeholderText"
  />
  <button class="btn btn-attach elipsis {{ name ? 'text-center d-inline-block' : '' }}">
      <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="!imageName">
          <path d="M6 20.4615C2.68364 20.4615 -1.12907e-07 18.011 -2.52433e-07 14.9827L-7.34351e-07 4.52309C-8.35784e-07 2.32159 1.95273 0.538471 4.36364 0.538471C6.77454 0.538471 8.72727 2.32159 8.72727 4.52309L8.72727 12.9904C8.72727 14.3651 7.50545 15.4808 6 15.4808C4.49455 15.4808 3.27273 14.3651 3.27273 12.9904L3.27273 5.51924L5.45454 5.51924L5.45455 13.08C5.45455 13.6279 6.54545 13.6279 6.54545 13.08L6.54545 4.52309C6.54545 3.42732 5.56364 2.53078 4.36364 2.53078C3.16364 2.53078 2.18182 3.42732 2.18182 4.52309L2.18182 14.9827C2.18182 16.9053 3.89455 18.4692 6 18.4692C8.10545 18.4692 9.81818 16.9053 9.81818 14.9827L9.81818 5.51924L12 5.51924L12 14.9827C12 18.011 9.31636 20.4615 6 20.4615Z" fill="black" fill-opacity="0.3"/>
      </svg>
      {{ imageName ? imageName : labelText}}
  </button>
</div>