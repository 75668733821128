import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {LoyaltyOutcomeSettings} from "@models/LoyaltyProgram";
import {tap} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import {UtilService} from "../../util-services/util.service";

@Injectable({
    providedIn: "root",
})
export class LoyaltyProgramService {
    private readonly apiBaseUrl = `${environment.serverlessApiBaseUrl}/loyalty`;

    private loyaltyOutcomeSource = new BehaviorSubject<any>(null);
    loyaltyOutcome$ = this.loyaltyOutcomeSource.asObservable();

    constructor(
        private http: HttpClient,
        private utils: UtilService
    ) {
    }

    getCompanyIntegrations(companyId: string): Observable<any> {
        const url = `${this.apiBaseUrl}/company-integrations/${companyId}`;
        return this.http.get<any>(url, this.utils.getHeaders());
    }

    createLoyaltyOutcomeSettings( data: LoyaltyOutcomeSettings): Observable<any> {
        const url = `${this.apiBaseUrl}/outcome-settings/`;
        return this.http.post(url, data, this.utils.getHeaders());
    }

    deleteLoyaltyOutcomeSetting(integrationId: string): Observable<any> {
        const url = `${this.apiBaseUrl}/outcome-settings/${integrationId}`;
        return this.http.delete(url, this.utils.getHeaders());
    }

    getLoyaltyOutcomeSettings(campaignId: string): Observable<any> {
        const url = `${this.apiBaseUrl}/outcome-settings/${campaignId}`;
        return this.http.get<any>(url, this.utils.getHeaders()).pipe(
            tap((data) => this.loyaltyOutcomeSource.next(data))
        );
    }

    updateLoyaltyOutcomeSettings( data: LoyaltyOutcomeSettings): Observable<any> {
        const url = `${this.apiBaseUrl}/outcome-settings/`;
        return this.http.put(url, data, this.utils.getHeaders());
    }
}
