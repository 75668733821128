export class BuilderAssets {

    constructor(images = [], icons = [], fonts = [], defaults = [], gameAssets =[]) {
        this.images = images;
        this.icons = icons;
        this.fonts = fonts;
        this.defaults = defaults;
        this.gameAssets = gameAssets;
    }

    images;
    icons;
    fonts;
    defaults;
    gameAssets;

}
