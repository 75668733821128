import { CampaignMetadata } from "@models/CampaignMetadata";
import {BuilderDataConfig} from "@builder-models/BuilderDataConfig";
import { BuilderAssets } from "./BuilderAssets";
import { LocalizationData } from "./LocalizationData";
import { PageTemplate } from "./PageTemplate";

/**
 *
 */
export class BuilderData {

    campaignId: string;

    metadata: CampaignMetadata;
    config: BuilderDataConfig;

    assets: BuilderAssets;
    i18n: LocalizationData[];
    pageTemplates: PageTemplate[];

    indexHtmlTemplate: string;
    indexCss: string;
    mainCss: string;
    mainCssTemplate: string;
    controllerTemplate: string; // maybe extend to support other templates for other campaign types , might be useful when switching campaign type in builder

    // game settings
    gameData: any;
    // outcomes: Array<Outcome> ?? TODO
}
