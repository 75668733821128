export class PrizeManagementAccounts {
    static getPrizeManagementAccounts() {
        return [
            "brame",
            "4428c848-06a0-11ec-adbc-f36fd5f2024e",
            "f0c8e5d5-7ce7-11ec-9ba8-f995611ae32e",
            "b5b06757-3356-47a9-80e0-e6d3038fe6cf",
            "246c766e-4425-11ed-9d4f-335193301c4b",
            "46997221-8808-11ec-9a22-f35ead9e6916",
            "74f8a56a-8438-11ec-8d52-35d90a6c27fd",
            "1256a9ba-fb65-11eb-9ae1-3ffde099d822",
            "1b66962b-7cb3-11eb-911c-7dacd934162b",
            "12b2e9d5-aed7-11ed-895e-5ba2518d2069",
            "e61bcb79-9712-11ed-867e-9f5105d802bc",
            "f5a6a8b5-d1d2-11ec-aef5-31529e8f3853",
            "5bf36d11-255d-11ee-92c9-d14b91174b2a",
            "test team companies",
            "5bad004c-918e-11eb-be24-6b7050191601",
            "7d755d28-744d-11ec-ac7c-13f99ee6301f",
            "33be22e3-a76b-11eb-8e51-75a01ba9f2cc",
            "78ffad3f-19f0-11ec-85ba-67c5ed6bd3f8",
            "74eb64ca-2a65-11ec-ad23-01c355eb1ba5",
            "a88bbbcc-a282-11eb-8efc-6d63670321aa",
            "b5b06757-3356-47a9-80e0-e6d3038fe6cf",
            "7cceb9b6-e24b-11ec-bb9b-cd019b7bfcf4",
            "d56ccaef-1fe6-11ee-9e62-47c60a089977",
            "penny environment",
            "06061a7f-19f4-4052-baeb-a95307e51d06",
            "0312aac1-5f25-4293-8b2f-0a262d6c23cf",
            "dff539e3-94f2-11ed-8abf-d9fbb29a71a1",
            "d306a87a-7991-4a13-9218-6772696d6f2e",
            "d194db05-3b39-4990-84c3-c531f20d34f6",
            "1c72e9d5-94f3-11ed-8abf-e9c1de63119e",
            "080b8ab3-6ee2-410d-ab80-234262892194",
            "c3d08c43-0be3-406f-9a31-4fbfc84091b2",
            "b3439393-2b84-4963-a1a8-943f92120db1",
            "3e04f7f7-94f3-11ed-8abf-8d24f83170bc",
            "5a37e6d9-94f3-11ed-8abf-f53c23bdee96",
            "production",
            "ff36bb41-6467-11ee-b635-89188567e6e5",
            "e65f8ae7-2b72-11ec-a8c3-2dfbf96bfcce",
            "ef0c8e17-36b2-11ee-b9ce-535e1635cc09",
            "95625801-45a3-11ee-8429-89d2e6407b6a",
            "652522b3-c5bc-11ee-a390-0d704b22522b",
            "faff66d0-68c2-11ee-aee7-07df19047f30",
            "2539ba60-99e3-11ee-a203-39c424e04661",
            "337bf838-97f8-11ee-92e0-0d606e7a45a0",
            "99844eaf-5e9b-11ed-aac4-cd3b32791765",
            "8c209820-f0d5-11ed-9a7e-a5447ec9b476",
            "329dd1e1-e9d6-11ed-9a7e-95df7ba87f4a",
            "e2e911de-9bbe-11ed-867e-c584c3136a4c",
            "4c43be6f-ffa8-11ed-9a7e-9b999c5bf1b3",
            "13ae1467-6390-11ee-ae96-a59024bfe60b",
            "d1b1e41d-7a2c-11ee-9343-9f7f2482d8eb",
            "e401cfd0-041d-11ed-b82d-5bc7df07bd48",
            "e0566922-cf73-11eb-9d05-c3f3a937a854",
            "b8f9e3eb-fedf-11ed-9a7e-89369a47bae0",
            "f386eec3-47d0-11ee-a9d3-9f52e6aa9e47",
            "8ccecf8c-014c-11ee-9a7e-b5f1dea803e2",
            "35d9e8fc-aa21-11ee-82d3-1793c465f8db",
            "e34b6195-f1ca-11ee-82c4-af84008abbb7",
            "coles",
            "9ffc3a34-b9f4-11ee-9c83-b9fc56934e44",
            "57ffd8cf-cf9e-11ec-b974-1761d3c7c5b6",
        ];
    }
}
