import {Component, Input, forwardRef, AfterViewInit, ViewChild, ElementRef} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: "app-input",
    templateUrl: "input.component.html",
    styleUrls: ["input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
})
export class InputComponent implements AfterViewInit, ControlValueAccessor {
    @Input() type: "text" | "password" | "email" | "date" = "text";
    @Input() label: string;
    @Input() options: string;
    @Input() placeholder = "";
    @Input() disabled = false;
    @Input() clearCallback: () => void = void this.defaultClear;
    @Input() customClass?: string;
    @Input() pattern?: string;
    @Input() customErrorClass?: string;
    @Input() autoFocus?: boolean;
    @Input() invalid? = false;
    @Input() isPassword? = false;
    @Input() minDate?: string;
    @Input() clearText? = false;
    @Input() maxChars = 255;
    @ViewChild("inputElement") inputElement: ElementRef<HTMLInputElement>;

    ngAfterViewInit() {
        if (this.autoFocus) {
            setTimeout(() => this.inputElement.nativeElement.focus());
        }
    }

    constructor() {
    }

    onChange: any = () => {};
    onTouch: any = () => {};
    val = ""; // this is the updated value that the class accesses

    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    private defaultClear() {
        this.val = "";
        this.onChange("");
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.value = value;
    }

    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    onInputChange($event) {
        this.val = $event.target.value;
        this.onChange($event.target.value);
    }

    changeInputType() {
        this.type = this.type === "text" ? "password" : "text";
    }

    clear() {
        this.val = "";
        this.onChange("");
    }
}
