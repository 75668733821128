import {Component, Input, forwardRef, OnInit, Output, EventEmitter} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import intlTelInput from "intl-tel-input";
import * as utilScript from "intl-tel-input/build/js/utils";

@Component({
    selector: "app-phone-input",
    templateUrl: "phone-input.component.html",
    styleUrls: ["phone-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneInputComponent),
            multi: true,
        },
    ],
})
export class PhoneInputComponent implements ControlValueAccessor, OnInit {
    @Input() label: string;
    @Input() options: string;
    @Input() placeholder = "";
    @Input() disabled = false;
    @Input() clearCallback: () => void = void this.defaultClear;
    @Input() customClass?: string;
    @Input() customErrorClass?: string;
    @Input() invalid? = false;
    @Output() error: EventEmitter<string> = new EventEmitter();

    val = ""; // this is the updated value that the class accesses
    inputElement: HTMLElement;
    input: any;
    constructor() {
    }

    ngOnInit() {
        this.inputElement = document.querySelector("#phone");
        this.input = intlTelInput(this.inputElement, {
            utilsScript: utilScript,
            separateDialCode: true,
            initialCountry: "ch",
        });
    }

    onChange: any = () => {
    };
    onTouch: any = () => {
    };

    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val;
        if (this.val) {
            this.input.setNumber(this.val);
        }
        this.onChange(val);
        this.onTouch(val);
    }

    private defaultClear() {
        this.val = "";
        this.onChange("");
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.value = value;
    }

    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    onInputChange() {
        if (this.input.isValidNumber()) {
            this.val = this.input.getNumber();
            this.onChange(this.input.getNumber());
            this.error.next("");
        } else {
            const error = this.input.getValidationError();
            if (error) {
                this.error.next("This phone number is not valid");
            }
        }
    }
}
