import { Component, Input, OnInit, Output } from "@angular/core";
import { WarningService } from "@builder-services/core/warning.service";
import { Mail } from "@models/Mail";
import { Subject } from "rxjs";
import { UtilService } from "src/app/core/util-services/util.service";
import { ModalService } from "../../modal/modal.service";
import { imageTypes } from "../email-template-modal/models/newEmail";

@Component({
    selector: "app-change-email-modal",
    templateUrl: "./change-email-modal.component.html",
    styleUrls: ["./change-email-modal.component.scss"],
})
export class ChangeEmailModal implements OnInit {
    @Input() data: Mail;
    @Output() output: Subject<Mail> = new Subject<Mail>();
    originalData: Mail;

    constructor(
        private modalService: ModalService,
        private warning: WarningService,
        private util: UtilService
    ) {}

    ngOnInit() {
        this.data.attachmentFilename = this.data.attachmentFilename
            ? this.data.attachmentFilename.replace(/\.[^/.]+$/, "")
            : "";
        this.originalData = JSON.parse(JSON.stringify(this.data));
    }

    onCloseClick() {
        this.modalService.close("change-email-subject-modal");
    }

    onSaveClick() {
        if (this.util.compareObjects(this.originalData, this.data)) {
            return this.modalService.close("change-email-subject-modal");
        }
        this.warning.show().subscribe((value) => {
            if (!value) {
                return;
            }
            this.setAttachmentFileName();
            this.output.next(this.data);
            this.modalService.close("change-email-subject-modal");
        });
    }
    /* set correctly filename of attachment */
    private setAttachmentFileName() {
        if (this.data.attachmentUrl == null) {
            return;
        }
        let originalExtension;

        if (this.util.isBase64(this.data.attachmentUrl)) {
            originalExtension = this.data.attachmentUrl
                .split(";")[0]
                .split("/")[1];
        } else {
            originalExtension =
                this.data.attachmentUrl.split(".")[
                    this.data.attachmentUrl.split(".").length - 1
                ];
        }

        const newAttachmentFilename: string =
            this.data.attachmentFilename.replace(/\.[^/.]+$/, "");
        this.data.attachmentFilename =
            newAttachmentFilename + "." + String(imageTypes[originalExtension]);
    }
}
