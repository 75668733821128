import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {NgbInputDatepicker} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-datepicker-input",
    templateUrl: "./datepicker-input.component.html",
    styleUrls: ["./datepicker-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatepickerInputComponent),
            multi: true,
        },
    ],
})
export class DatepickerInputComponent implements ControlValueAccessor {
    @Input() labelText: string;
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() placeholder?: string;
    @Input() name: string;
    @Input() disabled = false;
    @Input() minDate: any;
    @Input() maxDate: any;
    currentDate: string;

    constructor() { }

    onChange: any = () => { };
    onTouch: any = () => { };
    val = "";

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    writeValue(value: any) {
        this.value = value;
        this.val = value;
        this.onChange(value);
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    clearDate(datepicker: NgbInputDatepicker) {
        datepicker.close();
        this.currentDate = "";
        this.val = "";
        this.onChange("");
    }

    onInputChange($event: {year: number; month: number; day: number}) {
        if ($event && $event.year) {
            const day = $event.day >= 1 && $event.day <= 9 ? `0${$event.day}` : $event.day;
            const month = $event.month >= 1 && $event.month <= 9 ? `0${$event.month}` : $event.month;
            const date = `${$event.year}-${month}-${day}`;
            this.val = date;
            this.onChange(date);
        } else {
            this.val = "";
            this.onChange("");
        }
    }

}
