import { Injectable } from "@angular/core";
import {
    AssetCategory,
    Asset,
    ALLOWED_TYPES,
    ALLOWED_EXTENSIONS,
} from "@builder-models/Asset";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, forkJoin, throwError } from "rxjs";
import { BuilderAssets } from "@builder-models/BuilderAssets";
import { catchError, map } from "rxjs/operators";
import { NgxImageCompressService } from "ngx-image-compress";
import { CampaignType } from "@models/CampaignTypes";
import { UtilService } from "src/app/core/util-services/util.service";
import { ResourcesService } from "./resources.service";

@Injectable({
    providedIn: "root",
})
export class AssetsService {
    constructor(
        private http: HttpClient,
        private resources: ResourcesService,
        private imageCompress: NgxImageCompressService,
        private utilService: UtilService
    ) {}

    create(campaignId: string, asset: Asset) {
        return this.http.post(environment.campaignDataUrlPrefix + `/api/assets/${asset.category}/${campaignId}/`, asset);
    }

    update(campaignId: string, asset: Asset) {
        return this.http.post(environment.campaignDataUrlPrefix + `/api/assets/${asset.category}/${campaignId}/`, asset);
    }

    delete(campaignId: string, asset: Asset) {
        return this.http.delete(environment.campaignDataUrlPrefix + `/api/assets/${asset.category}/${campaignId}/${asset.id}`);
    }

    getAllAssetsByCategory(
        campaignId: string,
        category: AssetCategory
    ): Observable<Asset[]> {
        return this.http.get(environment.campaignDataUrlPrefix + `/api/assets/${category}/all/${campaignId}`) as Observable<Asset[]>;
    }

    fetchBuilderAssets(
        campaignId: string,
        campaignType: CampaignType,
        templateId: string
    ): Observable<BuilderAssets> {
        return forkJoin([
            this.getAllAssetsByCategory(campaignId, AssetCategory.IMAGE),
            this.getAllAssetsByCategory(campaignId, AssetCategory.ICON),
            this.getAllAssetsByCategory(campaignId, AssetCategory.FONT),
            this.resources.getDefaultAsset(campaignType, templateId),
            this.resources.getGameAssets(campaignType),
        ]).pipe(
            catchError(() => throwError("Error fetching assets")),
            map(
                ([images, icons, fonts, defaults, gameAssets]) =>
                    new BuilderAssets(
                        images,
                        icons,
                        fonts,
                        defaults,
                        gameAssets
                    )
            )
        );
    }

    async compressAsset(dataURL: string, file: File) {
        if (this.isImage(file)) {
            const dimensions: any = await this.getImageDimensions(dataURL);
            const maxResolution = 2500;
            const maxNum = Math.max(dimensions.height, dimensions.width);
            let ratio = 100;
            if (maxNum > maxResolution) {
                const diff: number = this.utilService.calculatePercentageDiff(
                    maxResolution,
                    maxNum
                );
                ratio = 100 - diff;
            }
            if (file.type === "image/gif") {
                return dataURL;
            }

            return this.imageCompress
                .compressFile(dataURL, 1, ratio, 70)
                .then((compressed: string) => compressed);
        }

        return dataURL;
    }

    isImage(file: File): boolean {
        return file && file.type.split("/")[0] === "image";
    }

    getImageDimensions(dataURL: string) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                resolve({
                    height: img.height,
                    width: img.width,
                });
            };
            img.src = dataURL;
        });
    }

    isFileValid(file: File) {
        if (!file) {
            return false;
        }

        if (!file.type) {
            return (
                ALLOWED_EXTENSIONS.indexOf(file.name.split(".").pop()) !== -1
            );
        }

        return ALLOWED_TYPES.indexOf(file.type) !== -1;
    }
}
