<div class="app-modal-header">
    <h1 class="app-modal-title">
       Edit image
    </h1>
</div>

<div class="app-modal-content">
    <div class="controls">
        <app-button
            styles="switch"
            [text]="'Rotate left'"
            (output)="rotate('left')"
            icon="assets/shared/svg/rotate-left.svg"
            customClass="btn-image-editor"
        >
        </app-button>
        <app-button
            styles="switch"
            text="Rotate right"
            (output)="rotate('right')"
            icon="assets/shared/svg/rotate-right.svg"
            customClass="btn-image-editor"
        >
        </app-button>
    </div>

    <div class="image-container">
        <img id="image" [src]="data.url + '?cacheblock=true'" alt="Image" (load)="initializeCropper()" crossorigin="anonymous">
    </div>

</div>

<div class="app-modal-footer">

    <app-button
        styles="light"
        [text]= "'close' | translate"
        (output)="close()"
    >
    </app-button>

    <app-button
        styles="primary"
        text= "Save"
        (output)="save()"
    >
    </app-button>

</div>
