export enum LocalStorageKeys {
    USER_TYPE = "userType",
    USER_ROLE = "userRole",
    COMPANY_VIEW_ID = "companyViewId", // Used only for super admin, for other users, companyViewId is the default companyId
    CONTEXT = "brame-context",
    TIME_ZONE = "TIME_ZONE",
    COMPANY_TIME_ZONE = "COMPANY_TIME_ZONE",
    HAS_ACCEPTED_TERMS = "hasAcceptedTerms",
    UPCOMING_RELEASE_ALERT = "upcoming_release_alert"
}
