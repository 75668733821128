import { Component, forwardRef, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import {
    NgbCalendar,
    NgbDate,
    NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";

import { UtilService } from "src/app/core/util-services/util.service";

@Component({
    selector: "app-date-range-input",
    templateUrl: "./date-range-input.component.html",
    styleUrls: ["./date-range-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangeInputComponent),
            multi: true,
        },
    ],
})
export class DateRangeInputComponent implements ControlValueAccessor {
    @ViewChild("datepicker", { static: true }) datepicker: any;

    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
    maxDate: NgbDate | null;

    constructor(
        private calendar: NgbCalendar,
        public formatter: NgbDateParserFormatter,
        private utils: UtilService
    ) {
        this.fromDate = calendar.getToday();
        this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
        this.initializeMaxDate();
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (
            this.fromDate &&
            !this.toDate &&
            date &&
            (date.after(this.fromDate) || date.equals(this.fromDate))
        ) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }

        if (this.fromDate && this.toDate) {
            const val = [
                new Date(
                    this.fromDate.year,
                    this.fromDate.month - 1,
                    this.fromDate.day
                ),
                new Date(
                    this.toDate.year,
                    this.toDate.month - 1,
                    this.toDate.day
                ),
            ];
            this.datepicker.close();
            this.onChange(val); // emit only if we have both dates
        }
    }

    isHovered(date: NgbDate) {
        return (
            this.fromDate &&
            !this.toDate &&
            this.hoveredDate &&
            date.after(this.fromDate) &&
            date.before(this.hoveredDate)
        );
    }

    isInside(date: NgbDate) {
        return (
            this.toDate && date.after(this.fromDate) && date.before(this.toDate)
        );
    }

    isRange(date: NgbDate) {
        return (
            date.equals(this.fromDate) ||
            (this.toDate && date.equals(this.toDate)) ||
            this.isInside(date) ||
            this.isHovered(date)
        );
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed))
            ? NgbDate.from(parsed)
            : currentValue;
    }

    onChange: any = () => {};
    onTouch: any = () => {};
    val = null;

    set value(passedValue) {
        if (passedValue && passedValue !== this.val) {
            if (this.val !== null) {
                // do not emit change event on first time
                this.onChange(passedValue);
                this.onTouch(passedValue);
            }
            this.val = { startDate: passedValue[0], endDate: passedValue[1] };
            this.fromDate = new NgbDate(
                this.val.startDate.getFullYear(),
                parseInt(this.val.startDate.getMonth()) + 1,
                this.val.startDate.getDate()
            );
            this.toDate = new NgbDate(
                this.val.endDate.getFullYear(),
                parseInt(this.val.endDate.getMonth()) + 1,
                this.val.endDate.getDate()
            );
        }
    }

    writeValue(newValue: any) {
        this.value = newValue;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    private initializeMaxDate() {
        const today = this.utils.convertToLocalDateTime(
            new Date().toISOString()
        );

        this.maxDate = new NgbDate(
            today.getFullYear(),
            today.getMonth() + 1,
            today.getDate()
        );
    }
}
