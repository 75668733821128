import { Component } from "@angular/core";
import { AccordionItem } from "./accordion-item";

@Component({
    selector: "app-accordion",
    templateUrl: "./accordion.component.html",
    styleUrls: ["./accordion.component.scss"],
})
export class AccordionComponent {
    private active: AccordionItem;

    open(item: AccordionItem) {
        if (this.active) {
            this.active.hide();
        }

        this.active = item;
    }

    close(item: AccordionItem) {
        if (this.active === item) {
            this.active = null;
        }
    }
}
