import { PageType } from "./Page";

export class PageTemplate {
    id: string;
    type: PageType;
    data: string;

    constructor(id = "", data = "", type: PageType) {
        this.id = id;
        this.type = type;
        this.data = data;
    }
}
