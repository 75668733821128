import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SpinTheWheelGameData, SpinTheWheelInitialData, SpinTheWheelUpdateRetryData } from "@models/game-data/SpinTheWheelGameData";

@Injectable({
    providedIn: "root",
})

export class SpinTheWheelService {

    apiPrefix = environment.apiUrlPrefix + "/spin-the-wheel";

    constructor(private http: HttpClient) { }

    createGameData(data: SpinTheWheelInitialData) {
        return this.http.post(this.apiPrefix + "/game-settings/", data);
    }

    getGameData(campaignId: string) {
        return this.http.get(this.apiPrefix + `/game-settings/${campaignId}`);
    }

    updateGameData(campaignId: string, data: SpinTheWheelGameData) {
        return this.http.put(this.apiPrefix + `/game-settings/${campaignId}`, data);
    }

    updateRetry(campaignId: string, data: SpinTheWheelUpdateRetryData) {
        return this.http.put(this.apiPrefix + `/game-settings/${campaignId}`, data);
    }
}
