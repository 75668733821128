export enum PublishOptionType {
    IMMEDIATELY = 1,
    SCHEDULED = 2
}

export enum AnalyticsTrackingLevels {
    NONE = 0,
    GAME_ANALYTICS= 1,
    UNIQUE_VISITORS_ANALYTICS = 2,
    DETAILED_ANALYTICS = 3,
}

// NOTE: artificial value representing unlimited number of plays per day
export const UNLIMITED_NUMBER_OF_PLAYS_TRESHOLD = 10000;
