import { Component, Input, forwardRef, HostBinding } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "app-checkbox",
    templateUrl: "checkbox.component.html",
    styleUrls: ["checkbox.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
})
export class CheckboxComponent implements ControlValueAccessor {

    @Input() labelText: string;
    @Input() disabled: boolean;

    isChecked = false;

    constructor() { }

    @HostBinding("class") get class() {
        if (this.disabled) {
            return "checkbox-disabled";
        } else {
            return "";
        }
    }

    onChanged($event) {
        this.isChecked = $event && $event.target && $event.target.checked;

        this.onChange(this.isChecked);
    }



    onChange: any = () => { };
    onTouch: any = () => { };
    val = false; // this is the updated value that the class accesses

    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.isChecked = val;
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.isChecked = value;
        this.value = value;
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}
