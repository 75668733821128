import { Injectable } from "@angular/core";
import { ModalService } from "src/app/shared/modal/modal.service";
import { WarningModalComponent } from "src/app/shared/components/warning-dialog/warning-dialog.component";

@Injectable()
export class WarningService {
    defaultWarningMessage = "Changing this options will affect live instance of this campaign.\t\n Are you sure you want to proceed ?";
    isRemoveModal = false;

    constructor(private modalService: ModalService) { }

    show(warningMessage = this.defaultWarningMessage, dialogTitle?: string, isRemove?: boolean ) {
        this.isRemoveModal = isRemove;
        return this.modalService
            .open(WarningModalComponent.dialogName, WarningModalComponent, "sm", warningMessage, "", false, "", dialogTitle, this.isRemoveModal);
    }

}
