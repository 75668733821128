<div class="wrapper">
    <div class="dropdown" [ngClass]="{'disabled': languages.length <= 1}" dropdown>
        <div class="btn-group" mdbDropdown mdbDropdownToggle>
            <button type="button" mdbBtn class="waves-light">
                <div *ngIf="isValidLanguageSelected">
                    <img [src]="selectedLanguage.flagImageUrl" class="flag" alt="Language"> <span class="language-name">{{selectedLanguage.name}}</span>
                    <svg-icon src="assets/shared/svg/down.svg" class="down-icon"></svg-icon>
                </div>
                <div *ngIf="!isValidLanguageSelected && showAllLabel">
                    <svg-icon src="assets/shared/svg/globe.svg" class="globe"></svg-icon>
                    <span class="language-name">{{'allLanguagesLabel' | translate}}</span>
                </div>
            </button>
            <div *dropdownMenu class="dropdown-menu">
                <a *ngIf="showAllLabel" class="dropdown-item" href="javascript:void(0)" (click)="onLanguageSelected(null)">
                    <svg-icon src="assets/shared/svg/globe.svg" class="globe"></svg-icon>
                    <span class="language-name">{{'allLanguagesLabel' | translate}}</span>
                </a>
                <a *ngFor="let language of languages" class="dropdown-item" href="javascript:void(0)" (click)="onLanguageSelected(language)">
                    <img [src]="language.flagImageUrl" class="flag" alt="Language"><span class="language-name">{{language.name}}</span>
                </a>
            </div>
        </div>
    </div>
</div>