import { Component, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ModalService } from "../../modal/modal.service";
import { DeleteLeadsConfirmation } from "./models/delete-leads-confirmation";

@Component({
    selector: "app-delete-leads-confirmation",
    templateUrl: "./delete-leads-confirmation.component.html",
    styleUrls: ["./delete-leads-confirmation.components.scss"],
})
export class DeleteLeadsConfirmationDialogComponent {
    @Input() data: DeleteLeadsConfirmation;
    @Output() output: Subject<boolean> = new Subject<boolean>();

    campaignName: string;

    constructor(private modalService: ModalService) { }

    ngOnInit() {
        this.campaignName = "";
    }

    onCloseClick() {
        this.modalService.close("confirmation-modal");
    }

    onSaveClick() {
        this.output.next(true);
        this.modalService.close("confirmation-modal");
    }

    deleteDisabled() {
        return this.data.campaignName !== this.campaignName;
    }
}
