import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: "app-pagination",
    templateUrl: "./pagination.component.html",
    styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent {
    @Input() currentPage: number;
    @Input() totalItems: number;
    @Input() pageSize: number;
    @Input() items: any[];
    @Output() pageChange: EventEmitter<number> = new EventEmitter();

    constructor() { }

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            this.changePage();
        }
    }

    nextPage() {
        if (this.currentPage < this.numPages()) {
            this.currentPage++;
            this.changePage();
        }
    }

    goToPage(page: number) {
        this.currentPage = page;
        this.changePage();
    }

    changePage() {
        // Validate page
        if (this.currentPage < 1) {
            this.currentPage = 1;
        }
        if (this.currentPage > this.numPages()) {
            this.currentPage = this.numPages();
        }

        this.pageChange.next(this.currentPage);
    }

    numPages() {
        return Math.ceil(this.totalItems / this.pageSize);
    }

    getArrayFromPages() {
        if (this.numPages()) {
            return new Array(this.numPages());
        }
    }
}
