import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { ResponseDTO } from "@models/ResponseDTO";
import { Offer } from "@models/Offer";

@Injectable({ providedIn: "root" })
export class DemoService {
    constructor(private http: HttpClient) {}

    createOffer(payload: any) {
        const endpoint = `${environment.apiUrlPrefix}/user-management/offer/demo`;

        return this.http.post<{ data: { uuid: string } }>(endpoint, payload).pipe(
            switchMap(({ data: offer }) => this.http.post(`${endpoint}/send-offer/${offer.uuid}`, {}).pipe(
                map(() => offer)
            ))
        );
    }

    getOffer(offerId: string) {
        const endpoint = `${environment.apiUrlPrefix}/user-management/offer/${offerId}`;

        return this.http.get<{ data: any }>(endpoint).pipe(
            map(({ data }) => data),
            catchError(() => of(null))
        );
    }

    register(payload: any) {
        const endpoint = `${environment.apiUrlPrefix}/user-management/user-details/demo`;

        return this.http.post<{ data: any }>(endpoint, payload).pipe(
            map(({ data }) => data)
        );
    }

    checkEmailExistence(email: string) {
        const endpoint = `${environment.apiUrlPrefix}/user-management/user-details/exists/${email}`;
        return this.http.get<boolean>(endpoint).pipe(
            catchError(() => of(true))
        );
    }

    acceptEula(offerId: string, payload: { clientIpAddress: string; clientJSFingerprint: string }) {
        const endpoint = `${environment.apiUrlPrefix}/user-management/offer/accept-EULA/${offerId}`;

        return this.http.post<ResponseDTO<Offer>>(endpoint, payload)
            .pipe(map(({ data }) => data));
    }
}
