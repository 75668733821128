import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { ModalService } from "../../../shared/modal/modal.service";

const DIALOG_NAME = "TUTORIALS_NOTIFICATION_DIALOG";

@Component({
    selector: "app-tutorials-notification-popup",
    templateUrl: "./tutorials-notification-popup.component.html",
    styleUrls: ["./tutorials-notification-popup.component.scss"],
})
export class TutorialsNotificationPopupComponent {
    static readonly dialogName = DIALOG_NAME;

    constructor(
        private router: Router,
        private modals: ModalService
    ) {}

    close() {
        this.modals.close(DIALOG_NAME);
    }

    redirectToSupportPages() {
        this.close();
        void this.router.navigate(["/support/user-guides"], { queryParams: { starting_guide: "video_tutorials" } });
    }
}
