<div class="brm-c-accordion-item" [class.expanded]="expanded" (click)="toggle()">
    <div class="brm-c-accordion-item__header">
        <div class="header__title">{{ title }}</div>
        <div class="header__icon">
            <svg-icon src="assets/shared/svg/carret-down.svg"></svg-icon>
        </div>
    </div>
    <div class="brm-c-accordion-item__content">
        <ng-content></ng-content>
    </div>
</div>