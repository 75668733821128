import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BuildHashService } from "./build-hash.service";


@Injectable({
    providedIn: "root",
})
export class FetchVersionService {

    constructor(
        private http: HttpClient,
        private buildDetailsService: BuildHashService,
    ) { }

    fetchBuildDetails(): Promise<void> {

        const httpOptions = {
            headers: new HttpHeaders({
                "Cache-Control": "no-cache",
            }),
        };

        return new Promise(
            (resolve) => {
                this.http.get<any>(this.buildDetailsService.buildFilePath, httpOptions)
                    .toPromise()
                    .then((response) => {
                        this.buildDetailsService.buildHash = response.buildHash;
                        resolve();
                    })
                    .catch(() => {
                        console.warn("Can not get build hash, version notification will not work !");
                        this.buildDetailsService.buildHash = null;
                        resolve();
                    });
            }
        );
    }


}
