import {CampaignLanguage} from "@models/CampaignLanguage";
import {Asset} from "@builder-models/Asset";
import {Outcome} from "./Outcome";

export class SpinTheWheelGameDataFactory {
    static getInitial(id: string, languages: CampaignLanguage[], templateConfig: any, assets: Asset[]) {
        const {centerImage, tickerImage, outcomeImage, sectorImages} = this.getImages(assets);

        return {
            campaignId: id,
            legendEnabled: true,
            numberOfSectors: 8,
            numberOfPrizes: 4,
            wheelCenterImageUrl: centerImage,
            wheelBorderColour: "",
            wheelTickerImageUrl: tickerImage,
            outcomeImageUrl: outcomeImage,
            sectors: templateConfig.spinTheWheelConfig.sectors.map((item, index) => ({
                id: "sector-" + String(index),
                sectorColour: item.sectorColour,
                imageUrl: sectorImages[index % 4],
                textColour: item.textColour,
                textFont: item.textFont,
                orderNumber: index,
            })),
        };
    }

    static getImages(assets: Asset[]) {
        let centerImage;
        let tickerImage;
        let outcomeImage;
        const sectorImages = [];

        assets.map((asset: Asset) => {
            if (asset.key === "center") {
                centerImage = asset.url;
            }

            if (asset.key === "thank-you") {
                outcomeImage = asset.url;
            }

            if (asset.key === "ticker") {
                tickerImage = asset.url;
            }

            if (asset.key.startsWith("sector")) {
                sectorImages.push(asset.url);
            }
        });

        return {
            centerImage,
            tickerImage,
            outcomeImage,
            sectorImages,
        };
    }
}

export class SpinTheWheelInitialData {
    campaignId: string;
    wheelCenterImageUrl: string;
    wheelBorderColour: string;
    wheelTickerImageUrl: string;
}

export class SpinTheWheelGameData {
    campaignId: string;
    legendEnabled: boolean;
    numberOfSectors: number;
    numberOfPrizes: number;
    retryEnabled: boolean;
    wheelCenterImageUrl: string;
    wheelBorderColour: string;
    wheelTickerImageUrl: string;
    outcomeImageUrl: string;
    prizes: Prize[];
    sectors: Sector[];
}

export class Prize {
    id: string;
    outcomes: Outcome[];
    campaignId: string;
    prizeLimit: number;
    isUnlimited: boolean;
    numberOfGivenPrizes: number;
    prizeDistributionGive: number;
    prizeDistributionPerSpins: number;
    time: string;
}

export class Sector {
    id: string;
    campaignId: string;
    prizeId: string;
    isRetry: boolean;
    isDefault: boolean;
    sectorContent: string;
    sectorTitle: string;
    sectorColour: string;
    imageUrl: string;
    texts: Text[];
    textColour: string;
    textFont: string;
    textSize: string;
    orderNumber: number;
}

export class Text {
    id: string;
    text: string;
    languageId: string;
    parentId: string;
}

export class PrizeSector {
    orderNumber: number;
    imageUrl: string;
    sectorTitle: string;
    sectorColour: string;
    isRetry: boolean;
    isDefault: boolean;
    prizeId: string;
    prize: Prize;
    sectorContent: string;
}

export class SpinTheWheelUpdateRetryData {
    campaignId: string;
    numberOfSectors: number;
    numberOfPrizes: number;
    retryEnabled: boolean;
    wheelCenterImageUrl: string;
    wheelBorderColour: string;
    wheelTickerImageUrl: string;
}
