import { NgModule } from "@angular/core";
import { SecondsToMinutesPipe } from "./minute-seconds-pipe.pipe";
import { TrimWordPipe } from "./trim-word.pipe";
import { UnderscoreToWhitespacePipe } from "./underscore-to-whitespace.pipe";

@NgModule({
    declarations: [
        SecondsToMinutesPipe,
        TrimWordPipe,
        UnderscoreToWhitespacePipe,
    ],
    exports: [SecondsToMinutesPipe, TrimWordPipe, UnderscoreToWhitespacePipe],
})
export class PipesModule {}
