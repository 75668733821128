import { Component, Input, Output, EventEmitter, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

@Component({
    selector: "app-toggle-icon",
    templateUrl: "toggle-icon.component.html",
    styleUrls: ["toggle-icon.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleIconComponent),
            multi: true,
        },
    ],
})
export class ToggleIconComponent implements ControlValueAccessor {
    @Input() iconSrc: string;
    @Input() modalOpened = false;
    @Output() click: EventEmitter<any> = new EventEmitter(); // toggle click
    onChange: any = () => { };
    onTouch: any = () => { };
    val = false;

    constructor() { }

    toggle($event) {
        this.val = !this.val;
        this.onChange(this.val);
        this.click.emit();
        $event.stopPropagation();
    }

    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }
    writeValue(value: any) {
        this.value = value;
    }
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}
