<div class="app-modal-header">
    <h1 class="app-modal-title">{{ title | translate }}</h1>
</div>

<div class="app-modal-content">
    <p class="text-center">{{ data }}</p>
</div>

<div class="app-modal-footer justify-content-center">
    <app-button
        [text]="'cancel' | translate"
        [styles]="'light'"
        (output)="onCancelClick()"
    >
    </app-button>
    <app-button
    [text]="isRemoveModal ? 'Remove' : 'confirm' | translate"
    [styles]="isRemoveModal ? 'red' : 'primary'"
    (output)="onConfirmClick()"
>
</app-button>
    
</div>