<div *ngIf="message" class="toast-notification">
    <div class="toast-alert">
        <img alt="x" src="assets/builder/y_warning.png">
    </div>
    <div>
       <p class="toast-message"> {{ message }} </p>
    </div>
    <div class="close-btn" (click)="closeToast()">
        <img alt="x" src="assets/builder/svg/close.svg">
    </div>
</div>