import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validators } from "@angular/forms";

@Directive({
    selector: "[minValue]",
    providers: [{ provide: NG_VALIDATORS, useExisting: MinValueDirective, multi: true }],
})
export class MinValueDirective {
    updateValidator = () => {};

    private min = 0;

    @Input("minValue") set minValue(value: number) {
        this.setMinvalue(value);
    }

    constructor() {}

    validate(control: AbstractControl): { [key: string]: any } {
        return Validators.min(this.min)(control);
    }

    private setMinvalue(value: number) {
        this.min = value;
        this.updateValidator();
    }
}
