import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { map } from "rxjs/operators";

import { AdvancedSpinTheWheelGameData } from "@models/game-data/AdvancedSpinTheWheelGameData";
import { ResponseDTO } from "@models/ResponseDTO";

import { environment } from "../../../../environments/environment";

type GameDataResponseDTO = ResponseDTO<AdvancedSpinTheWheelGameData>;
type GameDataRequestDTO = AdvancedSpinTheWheelGameData & { poolIds?: number[] };

@Injectable({ providedIn: "root" })
export class AdvancedSpinTheWheelService {
    private readonly API_BASE_URL = `${environment.advancedSpinTheWheelApiBaseUrl}/game-settings`;

    constructor(private http: HttpClient) {}

    getGameData(campaignId: string) {
        return this.http.get<GameDataResponseDTO>(`${this.API_BASE_URL}/${campaignId}`).pipe(
            map(({ data }) => data)
        );
    }

    createGameData(gameData: AdvancedSpinTheWheelGameData) {
        return this.http.post<GameDataResponseDTO>(this.API_BASE_URL, { ...gameData, poolIds: [] }).pipe(
            map(({ data }) => data)
        );
    }

    updateGameData(gameData: GameDataRequestDTO) {
        const { campaignId, ...data } = gameData;

        if (!data.multiRegistration && data.playsPerDay > 1) {
            data.playsPerDay = 1;
        }

        return this.http.put<GameDataResponseDTO>(`${this.API_BASE_URL}/${campaignId}`, data).pipe(
            map(({ data }) => data)
        );
    }
}
