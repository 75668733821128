import { Injectable } from "@angular/core";
import { TileElementSettings } from "@models/game-data/TileElementSettings";
import {
    readTileCover,
    readTileNumberFont,
    readTileNumberPosition,
    setupTileCover,
    setupTileNumberFont,
    setupTileNumberPosition,
} from "@bramesaas/game-controllers/advent-calendar/rendering.service";
import { UtilService } from "src/app/core/util-services/util.service";

@Injectable()
export class AdventCalendarParsingService {
    constructor(private utilService: UtilService) {}

    /**
     * Get settings for element's computed style
     *
     * @param element HTML Element
     */
    getTileSettings(element: HTMLElement): TileElementSettings {
        const settings = new TileElementSettings();
        const tileCover = readTileCover(element);
        const numFont = readTileNumberFont(element);
        settings.fontFamily.name = numFont.fontFamily;
        settings.fontFamily.value = this.utilService.formatFontValue(
            numFont.fontFamily
        );
        settings.fontSize = parseInt(numFont.fontSize, 10);
        settings.fontColor = numFont.fontColor;
        settings.coverImg = tileCover.coverImg;
        settings.coverColor =
            tileCover.coverColor === "rgba(0, 0, 0, 0)"
                ? "rgba(255, 255, 255, 1)"
                : tileCover.coverColor;
        settings.coverType = tileCover.type;
        settings.position = readTileNumberPosition(element);

        return settings;
    }

    setTileSettings(element: HTMLElement, settings: TileElementSettings) {
        setupTileCover(element, {
            coverType: settings.coverType,
            coverImg: settings.coverImg,
            coverColor: settings.coverColor,
        });
        setupTileNumberFont(element, {
            fontFamily: settings.fontFamily.name,
            fontSize: settings.fontSize,
            fontColor: settings.fontColor,
        });
        setupTileNumberPosition(element, settings.position);
        element.setAttribute("data-styles-changed", "true");
    }

    removeTileSettings(
        element: HTMLElement,
        id: number,
        templateId: string,
        campaignId: string
    ): void {
        const tilesConfig = JSON.parse(localStorage.getItem(templateId));

        const tileConfig = tilesConfig.filter(
            (config) => parseInt(config.tileId) === id
        )[0];
        tileConfig.coverImg = this.utilService.replaceCampaignIdInAssetUrl(
            tileConfig.coverImg,
            campaignId
        );

        const coverType = tileConfig.coverImg ? "image" : "color";
        setupTileCover(element, {
            coverType,
            coverImg: tileConfig.coverImg,
            coverColor: tileConfig.coverColor,
        });

        setupTileNumberFont(element, {
            fontFamily: tileConfig.fontFamily.name,
            fontSize: parseInt(tileConfig.fontSize, 10),
            fontColor: tileConfig.fontColor,
        });
        setupTileNumberPosition(element, tileConfig.position);
        element.removeAttribute("data-styles-changed");
    }

    parseHtmlForDefaultStyles(html: string) {
        const doc = document.createElement("html");
        doc.innerHTML = html;
        const tiles = Array.from(doc.querySelectorAll("[data-advent-tile]"));
        const tilesConfig: TileElementSettings[] = [];
        tiles.map((tile: HTMLElement) => {
            const tileConfig: any = {};
            const tileChild = tile.querySelector("[class*=num-pos]");
            const classes = tileChild.className.split(" ");
            const positionClass = classes.filter((item) =>
                /\d+$/.exec(item)
            )[0];
            const position = positionClass.substring(positionClass.length - 1);
            const date: HTMLElement = tile.querySelector(".date");
            tileConfig.fontFamily = {
                name: date.style.fontFamily,
                value: this.utilService.formatFontValue(date.style.fontFamily),
            };
            tileConfig.fontSize = date.style.fontSize;
            tileConfig.fontColor = date.style.color;
            tileConfig.coverImg = tile.style.backgroundImage.slice(5, -2);
            tileConfig.coverType = tileConfig.coverImg ? "image" : "color";
            tileConfig.coverColor =
                tile.style.backgroundColor === "rgba(0, 0, 0, 0)"
                    ? "rgba(255, 255, 255, 1)"
                    : tile.style.backgroundColor;
            tileConfig.position = position;
            tileConfig.tileId = tile.dataset.adventTile;
            tilesConfig.push(tileConfig);
        });

        return tilesConfig;
    }
}
