import {Component, EventEmitter, Input, Output, HostBinding} from "@angular/core";

@Component({
    selector: "app-switch",
    templateUrl: "./switch.component.html",
    styleUrls: ["./switch.component.scss"],
})
export class SwitchComponent {
    @Input() isChecked: boolean;
    @Input() isDisabled?: boolean;
    @Output() output: EventEmitter<boolean> = new EventEmitter();

    constructor() { }

    @HostBinding("class") get class() {
        if (this.isDisabled) {
            return "disabled";
        } else {
            return "";
        }
    }

    toggle() {
        this.isChecked = !this.isChecked;
        this.output.next(this.isChecked);
    }
}
