<div class="app-modal-header">
  <h1 class="app-modal-title">{{ 'errorModal.modalTitle' | translate }}</h1>
</div>

<div class="app-modal-content">
  <p class="text-center">
    <img src="assets/shared/svg/wiz-failure.svg" alt="">
  </p>
  <p class="text-center">{{ data.text }}</p>
</div>

<div class="app-modal-footer justify-content-center">
  <app-button
      [text]="'sharedComponents.openDashboard' | translate"
      [styles]="'primary'"
      (output)="onOpenDashboard()"
  >
  </app-button>
  <app-button
      *ngIf="showCloseBtn"
      [text]="'backgroundModal.closeBtn' | translate"
      [styles]="'light'"
      (output)="onClose()"
      >
  </app-button>
</div>