import {Component, OnInit} from "@angular/core";

@Component({
    selector: "advent-banner",
    templateUrl: "advent-banner.component.html",
})

export class AdventBannerComponent implements OnInit {
    private now: Date;
    showBanner = false;

    constructor() {
    }

    ngOnInit() {
        this.now = new Date();
    }

    isWeekend() {
        const saturday = 6;
        const sunday = 0;
        return this.now.getDay() === saturday || this.now.getDay() === sunday;
    }

    isAfterWorkingHours() {
        const startTime = "17:00:00";
        const endTime = "08:00:00";

        const startDate = new Date(this.now.getTime());
        startDate.setHours(Number(startTime.split(":")[0]));
        startDate.setMinutes(Number(startTime.split(":")[1]));
        startDate.setSeconds(Number(startTime.split(":")[2]));

        const endDate = new Date(this.now.getTime());
        endDate.setHours(Number(endTime.split(":")[0]));
        endDate.setMinutes(Number(endTime.split(":")[1]));
        endDate.setSeconds(Number(endTime.split(":")[2]));

        return this.now >= startDate || this.now <= endDate;
    }

    closeBanner() {
        this.showBanner = false;
    }
}
