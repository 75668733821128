import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { ContextService } from "@services/context.service";
import { tap } from "rxjs/operators";

@Injectable()
export class UserManagementGuard implements CanActivate {
    constructor(
        private router: Router,
        private context: ContextService
    ) {}

    canActivate() {
        return this.context.canManageUsers().pipe(
            tap((canManageUsers) => !canManageUsers && void this.router.navigate(["/"]))
        );
    }
}
