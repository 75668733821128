import {Component, EventEmitter, HostListener, Input, Output} from "@angular/core";
import { CampaignLanguage } from "@models/CampaignLanguage";

@Component({
    selector: "app-language-popup",
    templateUrl: "./language-popup.component.html",
    styleUrls: ["./language-popup.component.scss"],
})
export class LanguagePopupComponent {
    @Input() languages: CampaignLanguage[] = [];
    @Input() x: number;
    @Input() y: number;
    @Input() show = false;

    @Output()
    languageSelected = new EventEmitter<CampaignLanguage>();

    @Output()
    hideEvent = new EventEmitter<boolean>();

    onLanguageSelected(language: CampaignLanguage): void {
        this.languageSelected.next(language);
    }

    @HostListener("document:click", ["$event"])
    clickedOutside($event) {
        if (!$event.target.classList.contains("btn-edit")) {
            if (this.show) {
                this.show = false;
                this.hideEvent.emit(this.show);
            }
        }
    }
}
