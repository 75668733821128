import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import { environment } from "src/environments/environment";
import {AuthGuard} from "./core/auth/auth.guard";
import {AdminGuard} from "./core/auth/admin.guard";
import {SalesGuard} from "./core/auth/sales.guard";
import { UserManagementGuard } from "./core/auth/user-management.guard";
import { WizardGuard } from "./core/auth/wizard.guard";
import { CampaignsGuard } from "./core/auth/campaigns.guard";
import { RolesChangedGuard } from "./core/auth/roles-changed.guard";
import { DemoGuard } from "./core/auth/demo.guard";

const routes: Routes = [
    {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
    },
    {
        path: "register",
        loadChildren: () => import("./features/register-user/register-user.module").then((m) => m.RegisterUserModule),
    },
    {
        path: "register-demo",
        loadChildren: () => import("./features/register-trial/register-trial.module").then((m) => m.RegisterTrialModule),
        canActivate: [DemoGuard],
        data: {
            offer: {},
        },
    },
    {
        path: "login",
        loadChildren: () => import("./features/login/login.module").then((m) => m.LoginModule),
    },
    {
        path: "forgot-password",
        loadChildren: () => import("./features/forgot-password/forgot-password.module").then((m) => m.ForgotPasswordModule),
    },
    {
        path: "account-status",
        loadChildren: () => import("./features/account-status/account-status.module").then((m) => m.AccountStatusModule),
    },
    {
        path: "admin-dashboard",
        canActivate: [AuthGuard, RolesChangedGuard, AdminGuard],
        loadChildren: () => import("./features/admin/admin.module").then((m) => m.AdminModule),
    },
    {
        path: "dashboard",
        canActivate: [AuthGuard, RolesChangedGuard, CampaignsGuard],
        loadChildren: () => import("./features/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path: "account",
        canActivate: [AuthGuard, RolesChangedGuard],
        loadChildren: () => import("./features/account/account.module").then((m) => m.AccountModule),
    },
    {
        path: "others",
        loadChildren: () => import("./features/others/others.module").then((m) => m.OthersModule),
    },
    {
        path: "wizard",
        canActivate: [AuthGuard, RolesChangedGuard, WizardGuard],
        loadChildren: () => import("./features/wizard/wizard.module").then((m) => m.WizardModule),
    },
    {
        path: "builder/:id",
        canActivate: [AuthGuard, RolesChangedGuard, WizardGuard],
        loadChildren: () => import("./features/builder/builder.module").then((m) => m.BuilderModule),
    },
    {
        path: "campaigns",
        canActivate: [AuthGuard, RolesChangedGuard, CampaignsGuard],
        loadChildren: () => import("./features/campaign/campaign-listing/campaign-listing.module").then((m) => m.CampaignListingModule),
    },
    {
        path: "campaign/details/:id",
        canActivate: [AuthGuard, RolesChangedGuard, CampaignsGuard],
        loadChildren: () => import("./features/campaign/campaign-details/campaign-details.module").then((m) => m.CampaignDetailsModule),
    },
    {
        path: "sales-dashboard",
        canActivate: [AuthGuard, RolesChangedGuard, SalesGuard],
        loadChildren: () => import("./features/sales/sales.module").then((m) => m.SalesModule),
    },
    {
        path: "sales-dashboard/register",
        canActivate: [AuthGuard, RolesChangedGuard, SalesGuard],
        loadChildren: () => import("./features/register/register.module").then((m) => m.RegisterModule),
    },
    {
        path: "users",
        canActivate: [AuthGuard, RolesChangedGuard, UserManagementGuard],
        loadChildren: () => import("./features/user-management/user-management.module").then((m) => m.UserManagementModule),
    },
    {
        path: "support",
        canActivate: [AuthGuard, RolesChangedGuard],
        loadChildren: () => import("./features/support/support.module").then((m) => m.SupportModule),
    },
    {
        path: "integrations",
        canActivate: [AuthGuard, RolesChangedGuard],
        loadChildren: () => import("./features/integrations/integrations.module").then((m) => m.IntegrationsModule),
    },
    {
        path: "**",
        redirectTo: "others/404",
    },
];

if (!environment.enableDemo) {
    routes.splice(routes.findIndex((r) => r.path === "register-demo"), 1);
}

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled", relativeLinkResolution: "legacy"})],
    exports: [RouterModule],
})
export class RoutingModule {
}
