import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: "app-step-buttons",
    templateUrl: "step-buttons.component.html",
    styleUrls: ["step-buttons.component.scss"],
})

export class StepButtonsComponent {

    @Input() isDisabled: boolean;
    @Input() activeStep: number;
    @Input() nextText: string;
    @Input() previousText: string;
    @Input() lastStepText: string;
    @Input() stepCount: number;
    @Output() output: EventEmitter<"previous" | "next"> = new EventEmitter();

    // keep until new dashboardd
    @Input() showBack: boolean;

    constructor() {
    }


    changeStep(direction: "previous" | "next") {
        this.output.next(direction);
    }
}
