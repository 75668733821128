<div class="wrapper {{ customClass ? customClass : ''}}">
    <label class="label">{{ labelText }}</label>
    <input #inputElement name="x"
           [(colorPicker)]="val"
           [cpDisabled]="true"
           [cpPosition]="options.cpPosition"
           [cpOutputFormat]="'rgba'"
           autocomplete="off"
           (colorPickerChange)="onChange($event)"
    >

    <div
        class="color"
        [ngStyle]="{'background-color': val}"
        (click)="toggle()"
    ></div>

</div>