import { Component, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ModalService } from "../../modal/modal.service";
import { DeleteAnalyticsConfirmation } from "./models/delete-analytics-confirm";

@Component({
    selector: "app-clear-analytics-confirmation",
    templateUrl: "./clear-analytics-confirmation.component.html",
    styleUrls: ["./clear-analytics-confirmation.component.scss"],
})
export class ClearAnalyticsConfirmationDialogComponent {
    @Input() data: DeleteAnalyticsConfirmation;
    @Output() output: Subject<boolean> = new Subject<boolean>();

    constructor(private modalService: ModalService) {}

    onCloseClick() {
        this.modalService.close("confirmation-modal");
    }

    onSaveClick() {
        this.output.next(true);
        this.modalService.close("confirmation-modal");
    }
}
