import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { map } from "rxjs/operators";

import { ResponseDTO } from "@models/ResponseDTO";
import { Ad, AdDTO } from "@models/Ad";

import {PennyPenaltyGameData} from "@models/game-data/PennyPenaltyGameData";
import { environment } from "../../../../environments/environment";

type GameDataResponseDTO = ResponseDTO<PennyPenaltyGameData>;
type GameDataRequestDTO = PennyPenaltyGameData & { poolIds?: number[] };
type AdsResponseDTO = ResponseDTO<AdDTO[]>;

const mapAdImage = (src: string, campaignId: string) => {
    if (!src.includes(environment.campaignDataStorage)) {
        return `${environment.campaignDataStorage as string}/${campaignId}/${src.replace("./", "")}`;
    }

    return src;
};

@Injectable({ providedIn: "root" })
export class PennyPenaltyService {
    private readonly API_BASE_URL = `${environment.pennyPenaltyApiBaseUrl}/game-settings`;

    constructor(private http: HttpClient) {}

    getGameData(campaignId: string) {
        return this.http.get<GameDataResponseDTO>(`${this.API_BASE_URL}/${campaignId}`).pipe(
            map(({ data }) => data)
        );
    }

    createGameData(gameData: PennyPenaltyGameData) {
        return this.http.post<GameDataResponseDTO>(this.API_BASE_URL, { ...gameData, poolIds: [] }).pipe(
            map(({ data }) => data)
        );
    }

    updateGameData(gameData: GameDataRequestDTO) {
        const { campaignId, ...data } = gameData;

        if (!data.multiRegistration && data.playsPerDay > 1) {
            data.playsPerDay = 1;
        }

        return this.http.put<GameDataResponseDTO>(`${this.API_BASE_URL}/${campaignId}`, data).pipe(
            map(({ data }) => data)
        );
    }

    getAds(campaignId: string) {
        return this.http.get<AdsResponseDTO>(`${environment.pennyPenaltyApiBaseUrl}/campaigns/${campaignId}/ads`).pipe(
            map(({ data }) => data.map((ad) => ({
                ...ad,
                image: mapAdImage(ad.image, campaignId),
            })))
        );
    }

    saveAds(campaignId: string, ads: Ad[]) {
        return this.http.put<AdsResponseDTO>(`${environment.pennyPenaltyApiBaseUrl}/campaigns/${campaignId}/ads`, ads)
            .pipe(
                map(({ data }) => data)
            );
    }
}
