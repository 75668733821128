<div class="app-modal-header">
  <h1 class="app-modal-title">Change email data</h1>
</div>

<form class="app-modal-content" #form="ngForm">
  <app-text-input
    [labelText]="'Email subject'"
    [showClear]="false"
    [(ngModel)]="data.subject"
    name="emailSubject"
    required
  ></app-text-input>

  <app-text-input
    *ngIf="data.attachmentUrl != null"
    [labelText]="'Attachment name'"
    [showClear]="false"
    [(ngModel)]="data.attachmentFilename"
    name="attachmentFilename"
    [customValidator]="'^[0-9 A-Z a-z _]+$'"
    required
  ></app-text-input>

  <app-file-upload
    [(ngModel)]="data.attachmentUrl"
    [labelText]="'Attachment Upload'"
    name="attachmentFile"
    imageName="file"
  ></app-file-upload>
</form>

<div class="app-modal-footer">
  <app-button
      [text]="'Cancel'"
      [styles]="'light'"
      (output)="onCloseClick()"
    >
    </app-button>
    <app-button
      [text]="'Save'"
      [styles]="'primary'"
      (output)="onSaveClick()"
      [isDisabled]="form.invalid"
    >
    </app-button>
</div>