import { Component, Input, forwardRef, Output, EventEmitter, ViewChild, ElementRef, HostListener } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ColorPickerDirective } from "ngx-color-picker";

@Component({
    selector: "app-question-color-picker",
    templateUrl: "question-color-picker.component.html",
    styleUrls: ["question-color-picker.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionColorPickerComponent),
            multi: true,
        },
    ],
})
export class QuestionColorPickerComponent implements ControlValueAccessor {

    @Input() labelText: string;
    @Input() options: {cpPosition: string} = { cpPosition: "auto" };
    @Input() customClass: string;
    @Input() disabled: boolean;
    @Output() change: EventEmitter<any> = new EventEmitter();

    @ViewChild(ColorPickerDirective) picker = null;
    @HostListener("document:click", ["$event"]) onClick() {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.open = false;
        }
    }

    open = false;

    constructor(private eRef: ElementRef) { }

    toggle() {
        if (this.disabled) {
            return;
        }
        this.open = !this.open;

        if (this.open) {
            this.picker.openDialog();
        } else {
            this.picker.closeDialog();
        }
    }


    onChange: any = () => { };
    onTouch: any = () => { };
    val = ""; // this is the updated value that the class accesses

    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }


    // this method sets the value programmatically
    writeValue(value: any) {
        this.value = value;
        this.val = value;
        this.onChange(value);
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}
