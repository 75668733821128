<div class="date-picker">
    <label class="label" *ngIf="labelText">{{ labelText }}</label>
    <input class="form-control" placeholder="{{ placeholder ? placeholder : '' }}" [disabled]="disabled" [minDate]="minDate" [maxDate]="maxDate"
           [name]="name" [(ngModel)]="currentDate" ngbDatepicker #d="ngbDatepicker" [footerTemplate]="t" (ngModelChange)="onInputChange($event)" [id]="name">
    <button class="date-picker__btn" (click)="d.toggle()" type="button">
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66667 8.55H3.77778V10.45H5.66667V8.55ZM9.44444 8.55H7.55556V10.45H9.44444V8.55ZM13.2222 8.55H11.3333V10.45H13.2222V8.55ZM15.1111 1.9H14.1667V0H12.2778V1.9H4.72222V0H2.83333V1.9H1.88889C0.840555 1.9 0.00944444 2.755 0.00944444 3.8L0 17.1C0 18.145 0.840555 19 1.88889 19H15.1111C16.15 19 17 18.145 17 17.1V3.8C17 2.755 16.15 1.9 15.1111 1.9ZM15.1111 17.1H1.88889V6.65H15.1111V17.1Z" fill="black" fill-opacity="0.5"/>
        </svg>
    </button>
</div>

<ng-template #t>
    <div class="date-picker__footer" *ngIf="currentDate">
        <app-button
            styles="primary"
            text="Clear"
            (output)="clearDate(d)"
        >
        </app-button>
    </div>
</ng-template>