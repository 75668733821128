import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CampaignType, CampaignTypeMetadata } from "@models/CampaignTypes";
import { Asset } from "src/app/features/builder/shared/models/Asset";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ResourcesService {

    constructor(private http: HttpClient) { }

    // TEMPLATE PREVIEW
    getTemplatePreviewImages(templateId: string, gameType: string, gameMode: string) {

        const data = {
            templateId,
            gameType,
            gameMode,
        };

        return this.http.post(environment.resourceServiceApiUrlPrefix + "/api/resources/template-preview", data);
    }

    // TEMPLATES METADATA
    getAllTemplateTypes() {
        return this.http.get(environment.resourceServiceApiUrlPrefix + "/api/resources/templateTypes");
    }

    getCompanyTemplates(companyId: string) {
        return this.http.get(environment.resourceServiceApiUrlPrefix + `/api/resources/companyTemplates/${companyId}`);
    }

    // CAMPAIGN TYPES METADATA
    getAllCampaignTypes(companyId: string): Observable<CampaignTypeMetadata[]> {
        return this.http.get(environment.resourceServiceApiUrlPrefix + `/api/resources/campaignTypes/${companyId}`) as Observable<CampaignTypeMetadata[]>;
    }

    // DEFAULT ASSETS PER TEMPLATE
    getDefaultAsset(campaignType: CampaignType, templateId: string): Observable<Asset[]> {
        return this.http.get(environment.resourceServiceApiUrlPrefix + `/api/resources/default-assets/${templateId}/${campaignType}`) as Observable<Asset[]>;
    }

    getGameAssets(campaignType: CampaignType): Observable<Asset[]> {
        return this.http.get(environment.resourceServiceApiUrlPrefix + `/api/resources/game-assets/${campaignType}`) as Observable<Asset[]>;
    }

    // MAINCSS
    getMainCssTemplate() {
        return this.http.get(environment.resourceServiceApiUrlPrefix + "/api/resources/other/maincss");
    }

}
