import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validators } from "@angular/forms";

@Directive({
    selector: "[maxValue]",
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxValueDirective, multi: true }],
})
export class MaxValueDirective {
    updateValidator = () => {};

    private max = 10000;

    @Input("maxValue") set maxValue(value: number){
        this.setMaxvalue(value);
    }

    constructor() {}

    validate(control: AbstractControl): { [key: string]: any } {
        return Validators.max(this.max)(control);
    }

    private setMaxvalue(value: number) {
        this.max = value;
        this.updateValidator();
    }
}
