<div class="wrapper" *ngIf="!mini">

    <label class="label"> {{labelText}} </label>

    <div class="input" style="padding-top: 0.7rem;">
        {{ val }}
    </div>

    <input #inputElement class="input test picker" name="x"
        [(colorPicker)]="val"
        [cpDisabled]="true"
        [cpPosition]="options.cpPosition"
        [cpOutputFormat]="'rgba'"
        autocomplete="off"
        (click)="toggle()"
        (colorPickerChange)="onColorChange($event)">

    <div *ngIf="val" class="clear-btn">
        <img [style.backgroundColor]="val">
    </div>

</div>

<div
    *ngIf="mini"
    class="app-icon {{ !showLabel ? 'no-label' : ''}} position-relative"
    (click)="toggle()"
    placement="bottom"
    ngbTooltip="{{ tooltipText ? tooltipText : ''}}"
    tooltipClass="green-tooltip"
>
    <span *ngIf="showLabel">color</span>
    <div class="app-icon__color" [style.backgroundColor]="val"></div>
    <input #inputElement class="do-not-show picker position-absolute"
        [(colorPicker)]="val"
        [cpDisabled]="true"
        [cpPosition]="options.cpPosition"
        [cpOutputFormat]="colorFormat"
        autocomplete="off"
        (click)="toggle()"
        (colorPickerChange)="onColorChange($event)">
</div>
