import { Component, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ModalService } from "../../modal/modal.service";

@Component({
    selector: "app-select-item-modal",
    templateUrl: "./select-item-modal.component.html",
    styleUrls: ["./select-item-modal.component.scss"],
})
export class SelectItemModal implements OnInit {
    static readonly dialogName: string = "select-item-modal";

    @Input() data: any;
    @Output() output: Subject<any> = new Subject<any>();
    title = "";
    selectedItem = null;
    options = null;
    valueField = null;
    showField = null;
    labelText = "";

    constructor(private modalService: ModalService) { }

    ngOnInit() {
        this.title = this.data.title;
        this.selectedItem = this.data.selectedItem;
        this.options = this.data.options;
        this.showField = this.data.showField;
        this.valueField = this.data.valueField;
        this.labelText = this.data.labelText;
    }

    onCloseClick() {
        this.modalService.close(SelectItemModal.dialogName);
    }

    onSelectClick()  {
        this.output.next(this.selectedItem);
        this.modalService.close(SelectItemModal.dialogName);
    }
}
