export enum AnswerTypes {
    SCALE = "SCALE",
    SINGLE = "SINGLE_CHOICE",
    MULTIPLE = "MULTI_CHOICE",
    FREE_TEXT = "FREE_TEXT"
}

export interface AnswerTypeOption {
    id: string;
    text: string;
}

export class AnswerTypeFactory {
    static getAnswerTypes(): AnswerTypeOption[] {
        return [
            {
                id: AnswerTypes.SINGLE,
                text: "Single answers selection",
            },
            {
                id: AnswerTypes.MULTIPLE,
                text: "Multiple answers selection",
            },
            {
                id: AnswerTypes.SCALE,
                text: "Scale rating answer",
            },
            {
                id: AnswerTypes.FREE_TEXT,
                text: "Free text answer",
            },
        ];
    }
}
