import { Component, Input } from "@angular/core";

@Component({
    selector: "app-edit-toolbar-checkbox",
    templateUrl: "edit-toolbar-checkbox.component.html",
    styleUrls: ["edit-toolbar-checkbox.component.scss"],
})
export class EditToolbarCheckboxComponent {
    @Input() iconSrc = "";
    @Input() isChecked: boolean;
    @Input() resetStyles: () => void;
    @Input() isDisabled: boolean;
    @Input() tooltipText = "Revert changes";

    constructor() { }
}
