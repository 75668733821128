import {Component, Input, OnInit, Output} from "@angular/core";
import {QuestionPopup} from "@models/game-data/QuestionPopup";
import {Subject} from "rxjs";
import {ModalService} from "../../modal/modal.service";

@Component({
    selector: "app-question-popup",
    templateUrl: "./question-popup.component.html",
    styleUrls: ["./question-popup.component.scss"],
})
export class QuestionPopupComponent implements OnInit {
    @Input() data: QuestionPopup;
    @Output() output: Subject<QuestionPopup> = new Subject<QuestionPopup>();

    imageUrl: string;
    successUrl = "assets/shared/success.png";
    failUrl = "assets/shared/fail.png";
    result: "success" | "fail";
    title: string;
    text: string;
    newData: QuestionPopup;
    titleError = "";
    textError = "";

    constructor(private modalService: ModalService) { }

    ngOnInit() {
        this.title = this.data.title;
        this.text = this.data.text;
        this.result = this.data.result;
        this.imageUrl = this.result === "success" ? this.successUrl : this.failUrl;
    }

    handleTitleChange($event) {
        const title = $event.target.innerText;
        if (title.length >= 255) {
            this.titleError = "Text must be shorter than 255 characters";
            return;
        }
        this.titleError = "";
        this.title = title;
    }

    handleTextChange($event) {
        const text = $event.target.innerText;
        if (text.length > 1000) {
            this.textError = "Text must be shorter than 1000 characters";
            return;
        }
        this.textError = "";
        this.text = text;
    }

    isDisabled() {
        return !(!this.titleError && !this.textError);
    }

    close() {
        this.newData = {
            title: this.title,
            text: this.text,
            result: this.result,
        };

        this.output.next(this.newData);
        this.modalService.close("question-popup");
    }
}
