import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { CampaignLanguage } from "@models/CampaignLanguage";
import { map, tap } from "rxjs/operators";
import { ResponseDTO } from "@models/ResponseDTO";

@Injectable({
    providedIn: "root",
})
export class CampaignLanguageService {

    languages: CampaignLanguage[] = null;

    constructor(private http: HttpClient) {
        const x = this.getLanguages().subscribe((languages) => {
            this.languages = languages;
            x.unsubscribe();
        });
    }

    getLanguages(): Observable<CampaignLanguage[]> {
        return this.http.get(environment.apiUrlPrefix + "/campaign/languages/").pipe(
            map((response: ResponseDTO) => response.data),
            tap((languages) => this.languages = languages)
        );
    }

    getByShortName(shortName: string): CampaignLanguage {
        return this.languages.find((l) => l.shortName === shortName);
    }

    getByShortNameList(list: string[]): CampaignLanguage[] {
        return this.languages.filter((l) => list.includes(l.shortName));
    }
}
