import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from "@angular/core";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { AddressComponent } from "ngx-google-places-autocomplete/objects/addressComponent";
import { Place } from "@models/Place";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "app-address-input",
    templateUrl: "./address-input.component.html",
    styleUrls: ["./address-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressInputComponent),
            multi: true,
        },
    ],
})
export class AddressInputComponent {
    @Input() label: string;
    @Input() disabled = false;
    @Input() placeholder = "";
    @Input() customClass?: string;
    @Input() customErrorClass?: string;
    @Input() autoFocus?: boolean;
    @Input() invalid? = false;
    @Output() error: EventEmitter<string> = new EventEmitter<string>();

    place = new Place();
    val: Place; // this is the updated value that the class accesses

    constructor() {}

    onChange: any = () => {};
    onTouch: any = () => {};

    set value(val) {
        // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.value = value;
    }

    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    changePlace(place: Address) {
        this.place = new Place();
        this.place.formattedAddress = place.formatted_address;
        place.address_components.map((component: AddressComponent) => {
            const componentType = component.types[0];

            switch (componentType) {
            case "street_number":
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                this.place.address = `${this.place.address} ${component.long_name}`;
                break;
            case "route":
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                this.place.address = `${component.long_name} ${this.place.address}`;
                break;
            case "postal_code":
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                this.place.zip = `${component.long_name}${this.place.zip}`;
                break;
            case "postal_code_suffix":
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                this.place.zip = `${this.place.zip}-${component.long_name}`;
                break;
            case "locality":
                this.place.city = component.long_name;
                break;
            case "country":
                this.place.country = component.long_name;
                break;
            }
        });

        this.place.address.replace(/\s+/g, " ").trim();
        const invalidFields: string[] = [];

        //*  address validation currently disabled *//

        // Object.keys(this.place).map((item) => {
        //     if (item !== "zip") {
        //         if (!this.place[item]) {
        //             invalidFields.push(item);
        //         }
        //     }
        // });

        if (invalidFields.length > 0) {
            this.val = null;
            this.error.next(`Please enter a valid ${invalidFields[0]}`);
        } else {
            this.error.next("");
            this.val = this.place;
            this.onChange(this.val);
        }
    }
}
