import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class LoadingSpinnerService {

    private sub = new BehaviorSubject<boolean>(false);

    constructor() { }

    returnAsObservable() {
        return this.sub.asObservable();
    }

    show() {
        this.sub.next(true);
    }

    hide(): void {
        this.sub.next(false);
    }
}
