<div class="wrapper">
  <div class="form-group hidden">
    <div class="input-group">
      <input
        name="datepicker"
        class="date-range-input-placeholder"
        ngbDatepicker
        #datepicker="ngbDatepicker"
        [autoClose]="'outside'"
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="2"
        [dayTemplate]="t"
        outsideDays="hidden"
        [startDate]="fromDate"
        tabindex="-1"
        [maxDate]="maxDate"
      />
      <ng-template #t let-date let-focused="focused">
        <span
          class="custom-day"
          [class.focused]="focused"
          [class.range]="isRange(date)"
          [class.faded]="isHovered(date) || isInside(date)"
          (mouseenter)="hoveredDate = date"
          (mouseleave)="hoveredDate = null"
        >
          {{ date.day }}
        </span>
      </ng-template>
    </div>
  </div>
  <div class="start-end-date" (click)="datepicker.toggle();$event.stopPropagation();">
    <div class="input-group from-date-input-group">
      <input
        #dpFromDate
        class="form-control from-date"
        placeholder="dd.mm.yyyy"
        name="dpFromDate"
        [value]="formatter.format(fromDate)"
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        disabled
      />
    </div>

    <div class="input-group">
      <input
        #dpToDate
        class="form-control to-date"
        placeholder="dd.mm.yyyy"
        name="dpToDate"
        [value]="formatter.format(toDate)"
        (input)="toDate = validateInput(toDate, dpToDate.value)"
        disabled
      />
    </div>
  </div>
</div>
