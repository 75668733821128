<div class="wrapper">
  <label class="label">{{ labelText }}</label>

  <div class="holder">
    <span class="date-holder-first" (click)="toogleFontDropDown()">
      <div class="input">
        {{ selectedFontFamilyItem ? selectedFontFamilyItem : '' }}
      </div>
      <div *ngIf="dropDownFontOpened" class="app-select-menu_font">
        <div
          *ngFor="let o of fontOptions"
          class="app-select-menu-item_font"
          (click)="onFontItemSelected(o); $event.stopPropagation()"
        >
          {{ showFontSizeField ? o[showFontSizeField] : o }}
        </div>
      </div>
      <div class="toggle-btn">
        <img src="assets/builder/svg/arrow-down-icon.svg" />
      </div>
    </span>
    <span class="date-holder-second" (click)="toogleFontSizeDropDown()">
      <div class="input" [appTextOverflow]="'ellipsis'">
        {{
          selectedselectedFontSizeItem
            ? selectedselectedFontSizeItem + ' px'
            : ''
        }}
      </div>
      <div *ngIf="dropDownFontSizeOpened" class="app-select-menu_fontsize">
        <div
          class="app-select-menu-item_fontsize"
          *ngFor="let o of fontSizes"
          (click)="onFontSizeItemSelected(o); $event.stopPropagation()"
        >
          {{ showFontOptionField ? o[showFontOptionField] : o }}
        </div>
      </div>
      <div class="toggle-btn">
        <img src="assets/builder/svg/arrow-down-icon.svg" />
      </div>
    </span>
  </div>
  <div class="error">
    <ng-content></ng-content>
  </div>
</div>
