<div class="app-modal-header">{{ 'tutorialsDialog.header' | translate }}</div>
<div class="app-modal-content">{{ 'tutorialsDialog.message' | translate }}</div>
<div class="app-modal-footer">
    <app-button
        [text]="'tutorialsDialog.skip' | translate"
        [styles]="'light'"
        (output)="close()"
    ></app-button>
    <app-button
        [text]="'tutorialsDialog.watch' | translate"
        [styles]="'primary'"
        (output)="redirectToSupportPages()"
    ></app-button>
</div>