import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "app-button",
    templateUrl: "button.component.html",
    styleUrls: ["button.component.scss"],
})
export class ButtonComponent {
    @Input() type: "button" | "link" = "button";
    @Input() styles: "primary" | "light" | "red" | "switch" | "primary-new";
    @Input() url?: string;
    @Input() text: string;
    @Input() isDisabled = false;
    @Input() customClass?: string;
    @Input() isLoading? = false;
    @Input() icon? = null;
    @Input() dataTestId?: string;
    @Output() output: EventEmitter<MouseEvent> = new EventEmitter();

    constructor() {}

    onBtnClick($event: MouseEvent) {
        this.output.emit($event);
    }

    getClass() {
        if (this.isDisabled && this.isLoading) {
            return "btn-br-primary";
        }
        switch (this.styles) {
        case "primary":
            return "btn-br-primary";
        case "light":
            return "btn-br-light";
        case "red":
            return "btn-br-red";
        case "switch":
            return "btn-br-switch";
        case "primary-new":
            return "btn-br-primary-new";
        }
    }
}
