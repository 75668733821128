import { Component, OnInit } from "@angular/core";
import { LoadingSpinnerService } from "./service/loading-spinner.service";

@Component({
    selector: "app-loading-spinner",
    templateUrl: "./loading-spinner.component.html",
    styleUrls: ["./loading-spinner.component.scss"],
})

export class LoadingSpinnerComponent implements OnInit {
    isVisible = true;

    constructor(private spinnerService: LoadingSpinnerService) { }

    ngOnInit() {
        this.spinnerService.returnAsObservable().subscribe((subs) => {
            this.isVisible = subs;
        });
    }
}
