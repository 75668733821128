import { Injectable } from "@angular/core";
import { UtilService } from "src/app/core/util-services/util.service";
import { CampaignLanguageService } from "@services/http/campaign-languages.service";
import { injectInMainCssTemplate } from "@bramesaas/framework-util";
import { Asset } from "@builder-models/Asset";
import { FetchDataService } from "@builder-services/http/fetch-all-data.service";
import { LocalizationData } from "@builder-models/LocalizationData";
import { map, tap } from "rxjs/operators";
import { EmailSettingsService } from "@services/http/email-settings.service";
import { BuilderData } from "../../models/BuilderData";
import { BuilderStateService } from "./builder-state.service";

@Injectable()
export class Service extends BuilderData {
    /**
     * This service keeps all the campaign data used in BUILDER
     * all other services refer to this one when manipulating the data
     */

    metadataBackup = null;
    configBackup = null;

    // ?????????????
    fontsData = null;

    builderData: BuilderData = null;

    constructor(
        private fetch: FetchDataService,
        private campaignLanguages: CampaignLanguageService,
        private state: BuilderStateService,
        private util: UtilService,
        private emailSettingsService: EmailSettingsService
    ) {
        super();
    }

    initData(data: BuilderData) {
        // copy data fetched from backed
        this.builderData = data;
        Object.keys(data).forEach((key) => {
            this[key] = data[key];
        });

        // other stuff
        this.metadataBackup = this.util.copy(data.metadata);
        this.configBackup = this.util.copy(data.config);

        // plazicu popravi ovo
        if (this.assets.fonts[0]) {
            this.assets.fonts[0] = this.util.parseFonts(
                this.assets.fonts[0]
            );
            this.fontsData = this.util.generateFontFaceCss(this.assets.fonts);
        }
        this.fontsData = this.util.generateFontFaceCss(this.assets.fonts);
        this.mainCss = this.createMainCss(
            this.config.styleOptions,
            this.mainCssTemplate,
            this.fontsData || ""
        );
        this.loadEmailSettings().subscribe();
    }

    reloadLocalizationData() {
        return this.fetch
            .fetchLocalizationFiles(
                this.campaignId,
                this.getCampaignLanguages()
            )
            .pipe(map((i18n: LocalizationData[]) => (this.i18n = i18n)));
    }

    setTermsAndPrivacyLinks(key, newUrl) {
        let externalLinksConfig: { links: any; source?: any };

        if (this.config && this.config.externalLinks) {
            if (!this.config.externalLinks[key]) {
                this.config.externalLinks[key] = { links: [] };
            }
            externalLinksConfig = this.config.externalLinks[key];
        } else {
            externalLinksConfig = { links: [] };
        }

        const lang = this.config?.languageOptions?.languages || [];
        if (!Array.isArray(lang) || lang.length === 0) {
            console.error("Language options are not properly configured");
            return;
        }

        if (!externalLinksConfig.links || !Array.isArray(externalLinksConfig.links) || externalLinksConfig.links.length !== lang.length) {
            externalLinksConfig.links = lang.map((language) => ({
                languageId: language,
                url: newUrl.trim() !== "" ? newUrl : "",
            }));
        } else {
            externalLinksConfig.links.forEach((link) => {
                link.url = newUrl;
            });
        }
        externalLinksConfig.source = newUrl.trim() !== "" ? "link" : "external";

        this.state.$configurationModified.next(true);
    }

    getCampaignLanguages() {
        return this.campaignLanguages.getByShortNameList(
            this.config?.languageOptions.languages
        );
    }

    /// ///////////////////////////////////////////////////////////////////////////////////////////////
    /// ///////////////////////////////////////////////////////////////////////////////////////////////
    /// ///////// :( util
    getAssetByPath(path): Asset {
        if (!path) {
            return;
        }

        if (path.startsWith("http")) {
            path = path.replace(
                `https://brame-campaign-resources.s3.amazonaws.com/campaign-data/${String(
                    this.campaignId
                )}/`,
                ""
            );
        }
        const tokens = path.split("/");

        const category = tokens[1] === "game-default" ? "gameAssets" : tokens[1];
        const id = tokens[2];

        return this.assets[category].find((el) => el.id === id);
    }

    makePathOutOfAsset(asset) {
        if (!asset) {
            return;
        }
        const category = asset.category === "default" ? "defaults" : asset.category;
        return `assets/${String(category)}/${String(asset.id)}`;
    }

    // this is actually important stuff
    createMainCss(styleOptions, mainCssTemplate, fontsData) {
        const mainCssTemp = this.parseMainCssTemplate(mainCssTemplate);
        const styleOpts = this.util.copy(this.config.styleOptions);
        styleOpts.logoImage = this.getAssetByPath(styleOptions.logoImage)?.url;
        styleOpts.backgroundImage = this.getAssetByPath(
            styleOptions.backgroundImage
        ).url;
        styleOpts.backgroundImageMobile = this.getAssetByPath(
            styleOptions.backgroundImageMobile
        ).url;
        styleOpts.fontsData = fontsData;

        return injectInMainCssTemplate(mainCssTemp, styleOpts, fontsData);
    }

    parseMainCssTemplate(mainCssTemplate: string) {
        return mainCssTemplate.replace(/url\("..\/__inject/g, "url(\"__inject");
    }

    loadEmailSettings() {
        return this.emailSettingsService
            .getMailSettings(
                this.campaignId,
                this.builderData.config.campaignType
            )
            .pipe(
                tap((emailSettings) =>
                    this.state.$emailSettings.next(emailSettings)
                )
            );
    }
}
