import { CampaignLanguage } from "@models/CampaignLanguage";
import { Asset } from "@builder-models/Asset";
import { environment } from "src/environments/environment";

export interface BipaSpinTheWheelSectorsCount {
    [key: number]: number;
}

export interface BipaSpinTheWheelGameData {
    campaignId: string;
    noWinIcon: string;
    wheelSpeed: number;
    idleSpinningSpeed: number;
    multiRegistration: boolean;
    playsPerDay: number;
    showWinPageAfter: number;
    surveyEnabled: boolean;
    showSurveyAfter: number;
    showIncompleteSurveyAfter: number;
    showConfettiSnow: boolean;
    showConfettiFirework: boolean;
    language?: string;
    unknownCustomersSettings?: { playsPerDay: number };
    countryCode?: string;
    prizeWalletEnabled?: boolean;
    sectorsCount?: BipaSpinTheWheelSectorsCount;
    isNewsletterSubscriptionEnabled?: boolean;
    disablePrizeRedemptionEmails?: boolean;
    showAdAfterWin?: boolean;
}

export class BipaSpinTheWheelGameDataFactory {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getInitial(campaignId: string, languages: CampaignLanguage[], templateConfig: any, assets: Asset[]): BipaSpinTheWheelGameData {
        return {
            campaignId,
            noWinIcon: BipaSpinTheWheelGameDataFactory.getNoWinIcon(campaignId, assets),
            wheelSpeed: 1,
            idleSpinningSpeed: 0,
            multiRegistration: false,
            playsPerDay: 10000,
            showWinPageAfter: 2,
            surveyEnabled: true,
            showSurveyAfter: 4,
            showIncompleteSurveyAfter: 7,
            showConfettiSnow: false,
            showConfettiFirework: false,
            prizeWalletEnabled: false,
            isNewsletterSubscriptionEnabled: false,
            disablePrizeRedemptionEmails: false,
            showAdAfterWin: false,
            countryCode: "bipa",
            unknownCustomersSettings: {
                playsPerDay: 1,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    static getNoWinIcon(campaignId: string, assets: Asset[]) {
        // TODO: extract from assets once it is available
        return `${environment.campaignPreviewBaseUrl}/${campaignId}/assets/images/awards/img-prize-0.png`;
    }
}
