import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { MemoryGameData } from "@models/game-data/MemoryGameData";

@Injectable({
    providedIn: "root",
})
export class MemoryService {
    private readonly apiBaseUrl = environment.memoryApiBaseUrl;

    constructor(private http: HttpClient) { }

    /**
     * get complete game data for memory game ,
     *
     * @param campaignId
     */
    getGameData(campaignId: string): Observable<MemoryGameData> {
        const url = `${this.apiBaseUrl}/game-settings/${campaignId}`;
        return this.http.get<MemoryGameData>(url);
    }

    createMemoryGame(campaignId: string, memoryGame: any): Observable<any> {
        const url = `${this.apiBaseUrl}/game-settings`;
        return this.http.post(url, { campaignId, ...memoryGame });
    }

    updateGameData(campaignId: string, gameData: any): Observable<any> {
        const url = `${this.apiBaseUrl}/game-settings/${campaignId}`;
        return this.http.patch(url, gameData);
    }
}
