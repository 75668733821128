import { Component, Input} from "@angular/core";

import { ModalService } from "../../modal/modal.service";

@Component({
    selector: "app-alert-dialog",
    templateUrl: "./alert-dialog.component.html",
    styleUrls: ["./alert-dialog.component.scss"],
})
export class AlertModalComponent {
    @Input() data: {
        title: string;
        message: string;
        customClass?: string;
        btnClass?: any;
    };

    static readonly dialogName: string = "alert-dialog";

    constructor(private modalService: ModalService) { }

    onCloseClick() {
        this.modalService.close(AlertModalComponent.dialogName);
    }

}
