import {Component, Input, Output} from "@angular/core";
import Cropper from "cropperjs";
import {Subject} from "rxjs";
import {Asset} from "@builder-models/Asset";
import {ModalService} from "../../modal/modal.service";

@Component({
    selector: "app-image-editor",
    templateUrl: "image-editor.component.html",
    styleUrls: ["image-editor.component.scss"],
})

export class ImageEditorComponent {
    static readonly dialogName = "image-editor";

    @Input() data: Asset;
    @Output() output: Subject<{ data: string; name: string }> = new Subject<{ data: string; name: string }>();

    cropper: Cropper;

    constructor(
        private modalService: ModalService
    ) {

    }

    initializeCropper() {
        const image: HTMLImageElement = document.getElementById("image") as HTMLImageElement;
        this.cropper = new Cropper(image, {
            checkCrossOrigin: false,
            checkOrientation: false,
            data: {
                height: 360,
            },
        });
    }

    rotate(direction: "left" | "right") {
        const degree = direction === "left" ? -90 : 90;
        this.cropper.rotate(degree);
    }

    save() {
        const image = this.cropper.getCroppedCanvas().toDataURL("image/png");
        this.output.next({data: image, name: this.data.id});
        this.close();
    }

    close() {
        this.cropper.destroy();
        this.modalService.close(ImageEditorComponent.dialogName);
    }
}
