<div class="wrapper {{ customClass ? customClass : '' }}">
    <label *ngIf="label"> {{label}} </label>

    <input
        type="tel"
        id="phone"
        [disabled]="disabled"
        [class.full-width]="!label"
        [class.invalid]="invalid"
        (input)="onInputChange()"
        (focus)="onTouch()"
    >

    <div class="error {{ customErrorClass ? customErrorClass : ''}}">
        <ng-content></ng-content>
    </div>
</div>