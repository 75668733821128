<div *ngIf="variant === 1" class="wrapper">

    <label class="label" for="x"> {{labelText}} </label>

    <input *ngIf="!isInfinity" id="x" name="x" class="input"
        type="number"
        [value]="val"
        (input)="onChange($event.target.value)"
        (blur)="onTouch()"
        autocomplete="off"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [min]='minValue'
        [max]='maxValue'
        [step]="stepValue"
        (keypress)="onKeyPressed($event.key)">

        <input *ngIf="isInfinity"  name="x" class="input"
        type="text" [value]="'infinity'"
        [placeholder]="placeholder"
        [disabled]="true">

    <div *ngIf="showClear" class="clear-btn" (click)="clearCallback()">
        <img src="assets/builder/close.png">
    </div>

    <div class="error">
        <ng-content></ng-content>
    </div>
</div>

<div *ngIf="variant === 2" class="app-number-input app-number-input--variant-2" [class.disabled]="isDisabled">
    <button class="app-number-input__decrement"
        [disabled]="+val === minValue"
        [class.disabled]="isDisabled || +val === minValue"
        (click)="decrement()"
    >
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.07105 11.4084L1.07105 6.40845L6.07104 1.40845" stroke="#197b6f" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>

    <input class="app-number-input__input"
        type="number"
        [value]="val"
        [disabled]="true"
        (blur)="onTouch()"
    />


    <button class="app-number-input__increment"
        [disabled]="+val === +maxValue"
        [class.disabled]="isDisabled || +val === +maxValue"
        (click)="increment()"
    >
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.07104 1.40845L6.07104 6.40845L1.07104 11.4084" stroke="#197b6f" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
</div>