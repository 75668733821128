import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { ResponseDTO } from "@models/ResponseDTO";
import { TimeZone } from "@models/TimeZone";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class TimeZonesService {
    private readonly timeZonesBaseUrl = `${environment.timeZonesApiBaseUrl}`;

    constructor(private http: HttpClient) {}

    getAllTimeZones() {
        return this.http.get<Array<{ zone_name: string }>>(`${this.timeZonesBaseUrl}/distinct`)
            .pipe(
                map((zones) => zones.map((zone) => zone.zone_name))
            );
    }

    getTimeZoneDetails(timeZoneName: string) {
        return this.http.post<ResponseDTO<TimeZone>>(`${this.timeZonesBaseUrl}/offset`, {timeZoneName})
            .pipe(map(({ data }) => data));
    }

    getTimeZoneChanges(payload: { timeZoneName: string; from: number; to: number }) {
        return this.http.post<ResponseDTO<{ timeZoneName: string; changes: Array<{ offset: number; start: number }> }>>(
            `${this.timeZonesBaseUrl}/changes`,
            payload
        );
    }
}
