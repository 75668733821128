<div class="card analytics-container">
    <div class="d-flex section-heading flex-row align-items-center">
        <ng-content select="img"></ng-content>
        <ng-content select="[appSimpleAnalyticsCardTitle]"></ng-content>
    </div>
    <hr class="section-divider"/>
    <div class="section-data" [style.padding]="contentPadding">
        <ng-content select=[appSimpleAnalyticsCardContent]></ng-content>
    </div>
</div>
