<div class="warning-wrapper">
    <div class="app-modal-content">
      <p class="text-center">
        <img src="/assets/analytics/images/yellow-warning.png" alt="warning" />
      </p>
      <h3 class="text-center title">{{data.title}}</h3>
      <p class="text-center">{{ data.message }}</p>
      <p class="text-center">{{ data.message1 }}</p>
    </div>
    <div class="app-modal-footer-center">
      <app-button
      [text]="'No'"
      [styles]="'light'"
      (output)="onCloseClick()"
      >
    </app-button>
    <app-button
    [text]="'Yes'"
    [styles]="'light'"
    (output)="onSaveClick()"
    >
  </app-button>
  </div>
</div>