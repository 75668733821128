<div class="wrapper {{ customClass ? customClass : '' }}">
    <label *ngIf="label"> {{label}} </label>

    <input  #inputElement
        class="input"
        [value]="val"
        (input)="onInputChange($event)"
        (blur)="onTouch()"
        [type]="type"
        autocomplete="off"
        [pattern]="pattern"
        [class.full-width]="!label"
        [disabled]="disabled"
        [maxLength]="maxChars"
        [placeholder]="placeholder"
        [class.invalid]="invalid"
        [min]="minDate"
    >

    <div class="clear-text-btn" (click)="clear()" *ngIf="clearText">
        <svg-icon src="assets/shared/svg/x-2.svg"></svg-icon>
    </div>

    <div class="see-password" *ngIf="isPassword" (click)="changeInputType()">
        <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 0.00012207C6 0.00012207 1.73 3.11012 0 7.50012C1.73 11.8901 6 15.0001 11 15.0001C16 15.0001 20.27 11.8901 22 7.50012C20.27 3.11012 16 0.00012207 11 0.00012207ZM11 12.5001C8.24 12.5001 6 10.2601 6 7.50012C6 4.74012 8.24 2.50012 11 2.50012C13.76 2.50012 16 4.74012 16 7.50012C16 10.2601 13.76 12.5001 11 12.5001ZM11 4.50012C9.34 4.50012 8 5.84012 8 7.50012C8 9.16012 9.34 10.5001 11 10.5001C12.66 10.5001 14 9.16012 14 7.50012C14 5.84012 12.66 4.50012 11 4.50012Z" fill="black" fill-opacity="0.3"/>
        </svg>
    </div>

    <div class="error {{ customErrorClass ? customErrorClass : ''}}">
        <ng-content></ng-content>
    </div>
</div>