import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ScratchCardGameData, ScratchCardInitialData } from "@models/game-data/ScratchCardGameData";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ResponseDTO} from "@models/ResponseDTO";

@Injectable({
    providedIn: "root",
})

export class ScratchCardService {
    private readonly apiBaseUrl = environment.scratchCardApiBaseUrl;
    apiPrefix = environment.apiUrlPrefix + "/scratch-card";

    constructor(private http: HttpClient) { }

    createGameData(campaignId: string, data: ScratchCardInitialData) {
        const url = `${this.apiBaseUrl}/game-settings`;
        return this.http.post(url, { campaignId, ...data });
    }

    getGameData(campaignId: string) {
        const url = `${this.apiBaseUrl}/game-settings/${campaignId}`;
        return this.http.get<ResponseDTO>(url).pipe(map((response: ResponseDTO) => response.data));
    }

    updateGameData(gameData: ScratchCardGameData): Observable<any> {
        const url = `${this.apiBaseUrl}/game-settings`;
        return this.http.put(url, gameData);
    }
}
