<div class="loyalty-program" *ngIf="hasIntegrations">
    <form class="loyalty-program__form" [formGroup]="loyaltyForm">
        <div class="loyalty-program__integration-wrapper">
        <div class="loyalty-program__input loyalty-program__input--integration {{ customClass ? customClass : '' }}">
            <label for="integration">Integrations</label>
            <select class="form-control" id="integration" formControlName="integration">
                <option [ngValue]="null" disabled selected hidden>Select Integration</option>
                <option [ngValue]="''">No Integration</option>
                <option *ngFor="let integration of companyIntegrations" [ngValue]="integration">
                    {{ integration.loyaltyIntegration.key }}
                </option>
            </select>
        </div>
        </div>
        <div *ngIf="loyaltyForm.get('integration').value" class="loyalty-program__input__wrap">

            <div class="loyalty-program__input first">
                <label for="identifier">Identifier</label>
                <input type="text" id="identifier"  placeholder="e.g. e2e87bfb-9130-..." formControlName="identifier" class="loyalty-program__input--text">
            </div>

            <div class="loyalty-program__input second">
                <label for="points">Value</label>
                <input type="text" id="points" formControlName="points" class="loyalty-program__input--number">
            </div>

        </div>
    </form>
    <div *ngIf="loyaltyForm.get('integration').value" class="loyalty-program__message__wrap">
        <p class="loyalty-program__message">
            Input your loyalty points ID along with the corresponding total value. <br>
            Be sure to spell the identifier correctly to align with your loyalty system.
        </p>
    </div>
</div>