import { FormSettings } from "src/app/features/builder/shared/models/FormSettings";
import { ActionList } from "src/app/features/builder/shared/models/Actions";
import { ElementType } from "./ElementType";

export class Page {
    key = "";
    type: PageType = PageType.PAGE;
    included?: boolean = true;
    nextPage?: string = null;
    ctaButtonOptions?: ButtonPerOutcome;
    shareOnSocialMedia?: SocialMediaShare;
    bindEvents?: [BindEventsSettings] = null;
    renderData?: [RenderDataOptions] = null;
    resolveData?: [ResolveDataOptions] = null;
    formOptions?: FormSettings = null;
    usesCampaignTypeController?: boolean;
    protected?: boolean;
}

export enum PageType {
    PAGE = "page",
    EXTERNAL = "external",
    EMAIL = "email",
}

export class ButtonPerOutcome {
    buttonPerOutcome: boolean;
}

export class SocialMediaShare {
    active: boolean;
    contentPerOutcome: boolean;
    elementId: string;
    type: string;
}

// not done
export class BindEventsSettings {
    elementId: string;
    type: EventType;
    action: ActionList;
}

// not done
export enum EventType {
    CLICK = "click",
}

// not done
export class RenderDataOptions {
    elementId: string;
    type: ElementType;
    name?: string;
    dataKey?: string;
    value?: string | number | boolean;
}

// not done
export class ResolveDataOptions {
    name: string;
    fromEndpoint: string;
    pathParams: [string];
}

export const OUTCOME_RENDER_DATA: RenderDataOptions[] = [
    {
        elementId: "outcome-title",
        type: ElementType.TEXT,
        name: "outcome",
        dataKey: "text",
    },
    {
        elementId: "outcome-image",
        type: ElementType.IMAGE,
        name: "outcome",
        dataKey: "imageUrl",
    },
    {
        elementId: "outcome-text",
        type: ElementType.TEXT,
        name: "outcome",
        dataKey: "description",
    },
];

export const PAGE_PREFIXES = {
    BIPA: "Bipa",
    BILLA: "Billa",
    PENNY: "Penny",
};
