import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: "root",
})

export class InternalAnalyticsService {

    constructor(
        private http: HttpClient,
    ) {}

    triggerBrameEvent(event: string, email?: string, name?: string, campaignId?: string) {
        const url = environment.serverlessApiBaseUrl + "/analytics/api/internal-analytics";
        return this.http.post(url, {
            name,
            email,
            appVersion: "v1",
            eventName: event,
            campaignId,
        });
    }
}
