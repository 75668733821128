<div class="app-modal-header">
  <h1 class="app-modal-title">{{ 'confirmationTitle' | translate }}</h1>
</div>

<div class="app-modal-content">
  <p class="text-center">
    <svg-icon src="assets/shared/svg/xcircle.svg" class="delete-icon"></svg-icon>
  </p>
  <h3 class="text-center margin-bottom-class">{{data.title}}</h3>
  <p class="text-center">{{ data.message }}</p>
</div>

<div class="app-modal-footer">
  <app-button
    [text]="'Cancel'"
    [styles]="'light'"
    (output)="onCloseClick()"
  >
  </app-button>
  <app-button
    [text]="'Delete'"
    [styles]="'red'"
    (output)="onSaveClick()"
  >
  </app-button>
</div>
