<div class="warr">
    <div class="app-modal-content">
        <p class="text-center">
            <img src="/assets/analytics/images/yellow-warning.png" alt="warrning" />
        </p>
        <h3 class="text-center title">{{ data.title }}</h3>
        <p class="text-center">{{ data.message }}</p>
        <p class="text-center" id="prevent-select">{{ data.message1 }}</p>
    </div>
    <div class="deletion-input">
        <app-input name="campaignName" [(ngModel)]="campaignName" placeholder="{{this.data.campaignName}}"> </app-input>
    </div>

    <div class="app-modal-footer-center">
        <app-button [text]="'No'" [styles]="'light'" (output)="onCloseClick()"> </app-button>
        <app-button [text]="'Yes'" [styles]="'light'" (output)="onSaveClick()" [isDisabled]="deleteDisabled()"> </app-button>
    </div>
</div>
