import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { LocalStorageKeys } from "@models/LocalStorageKeys";
import { UserRoles } from "@models/UserRoles";
import { ContextService } from "@services/context.service";
import { map, tap } from "rxjs/operators";
import { LocalStorageService } from "src/app/core/util-services/local-storage.service";
import { UtilService } from "src/app/core/util-services/util.service";
import { AuthService } from "./auth.service";

// TODO: move this to some shared/parent/utils module
type RolesSortingComaprer = (r1: UserRoles, r2: UserRoles) => -1 | 1 | 0;

const rolesSortingComaprer: RolesSortingComaprer = (r1, r2) => {
    if (r1 === r2) {
        return 0;
    }

    return r1 < r2 ? -1 : 1;
};

@Injectable()
export class RolesChangedGuard implements CanActivate {
    constructor(
        private router: Router,
        private context: ContextService,
        private storage: LocalStorageService,
        private utils: UtilService,
        private auth: AuthService
    ) {}

    canActivate() {
        return this.context.getLoggedInUserRole().pipe(
            map((response: { data: UserRoles[] }) => response.data),
            map((roles) => {
                const currentRoles = this.storage.getItem(
                    LocalStorageKeys.USER_ROLE,
                    []
                ) as UserRoles[];

                roles.sort(rolesSortingComaprer);
                currentRoles.sort(rolesSortingComaprer);

                return this.utils.isEqual(roles, currentRoles);
            }),
            tap((noChanges) => {
                if (!noChanges) {
                    this.auth.logout();
                    void this.router.navigate(["/login"]);
                }
            })
        );
    }
}
