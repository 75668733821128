import { Component, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ModalService } from "../../modal/modal.service";
import { Confirmation } from "./models/confirmation";

@Component({
    selector: "app-confirmation-dialog",
    templateUrl: "./confirmation-dialog.component.html",
    styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
    @Input() data: Confirmation;
    @Output() output: Subject<boolean> = new Subject<boolean>();

    constructor(private modalService: ModalService) { }

    onCloseClick() {
        this.modalService.close("confirmation-modal");
    }

    onSaveClick() {
        this.output.next(true);
        this.modalService.close("confirmation-modal");
    }

}
