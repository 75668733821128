import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ContextService } from "@services/context.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";
import { ErrorTailorModule } from "@ngneat/error-tailor";
import { TranslateService } from "@ngx-translate/core";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { SharedModule } from "./shared/shared.module";
import { CoreModule } from "./core/core.module";
import { RoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";
import { NgbDateCustomParserFormatter } from "./shared/components/date-range-input/NgbDateCustomParserFormatter";

@NgModule({
    declarations: [AppComponent],
    imports: [
        AngularSvgIconModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        CoreModule,
        SharedModule, // :(
        BrowserModule,
        NgbModule,
        BrowserAnimationsModule,
        RoutingModule,
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
                deps: [HttpClient],
            },
        }),
        ErrorTailorModule.forRoot({
            errors: {
                useFactory: (service: TranslateService) => ({
                    required: () => service.instant("formInputError.required"),
                    minTrimmedCharacters: ({ min }) =>
                        service.instant("formInputError.minTrimmedCharacters", {
                            min,
                        }),
                    maxChars: ({ max }) =>
                        service.instant("formInputError.maxChars", { max }),
                }),
                deps: [TranslateService],
            },
            blurPredicate: (element) =>
                element.tagName === "INPUT" ||
                element.tagName === "SELECT" ||
                element.tagName === "APP-ASSET-SELECT" ||
                element.hasAttribute("ng-reflect-model"),
        }),
    ],
    providers: [
        ContextService,
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
