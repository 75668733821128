import { Component, Input } from "@angular/core";
import { ErrorMessage } from "@models/ErrorMessage";

import { ModalService } from "../../modal/modal.service";

@Component({
    selector: "app-render-failed-modal",
    templateUrl: "./render-failed-modal.component.html",
    styleUrls: ["./render-failed-modal.component.scss"],
})
export class RenderFailedModalComponent {
    @Input() data: ErrorMessage;

    static readonly dialogName: string = "render-failed-modal";

    constructor(
        private modalService: ModalService
    ) { }

    onRefresh() {
        this.modalService.close(RenderFailedModalComponent.dialogName);
        window.location.reload();
    }

}
