export enum UserRoles {
    USER = "USER",
    SALES_MANAGER = "SALES_MANAGER",
    USER_ADMIN = "USER_ADMIN",
    SUPER_ADMIN = "SUPER_ADMIN",
    SUPER_USER_ADMIN = "SUPER_USER_ADMIN",
    SUCCESS_MANAGER = "SUCCESS_MANAGER",
    EDITOR = "EDITOR",
    VIEWER = "VIEWER",
    CREATOR = "CREATOR",
    SALES_OFFER_SENDER = "SALES_OFFER_SENDER",
}
