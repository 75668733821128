import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { CampaignType } from "@models/CampaignTypes";
import { CampaignLanguage } from "@models/CampaignLanguage";
import { BuilderDataConfig } from "@builder-models/BuilderDataConfig";

@Injectable({
    providedIn: "root",
})
export class CampaignDataService {

    constructor(
        private http: HttpClient
    ) {
    }

    initializeCampaignData(data: any) {
        return this.http.post(environment.campaignDataUrlPrefix + "/api/campaigns", data);
    }

    getCampaignConfiguration(campaignId: string) {
        return this.http.get(environment.campaignDataUrlPrefix + "/api/campaigns/" + campaignId);
    }


    putCampaignConfiguration(campaignId: string, config: BuilderDataConfig) {
        return this.http.put(environment.campaignDataUrlPrefix + `/api/campaigns/${campaignId}`, config);
    }

    // TODO
    getControllerTemplate(campaignId: string, campaignType: CampaignType): Observable<string> {
        return this.http.get(environment.campaignDataUrlPrefix + `/api/controllerTemplates/${campaignId}/${campaignType}`) as Observable<string>;
    }
    getPageHTMLData(campaignId: string, pageId: string): Observable<string> {
        return this.http.get(environment.campaignDataUrlPrefix + `/api/html/${campaignId}/${pageId}`) as Observable<string>;
    }

    updatePageHtmlData(campaignId: string, pageId: string, rawHtml: string) {
        return this.http.put(environment.campaignDataUrlPrefix + "/api/html/", { campaignId, pageId, rawHtml });
    }

    getCssData(campaignId: string) {
        return this.http.get(environment.campaignDataUrlPrefix + `/api/css/index/${campaignId}`);
    }

    getLanguageFile(campaignId: string, langId: string) {
        return this.http.get(environment.campaignDataUrlPrefix + `/api/i18n/${campaignId}/${langId}`);
    }

    createLanguageFile(campaignId: string, language: CampaignLanguage, jsonObject: any) {
        const data = { campaignId, langId: language.shortName, jsonObject };
        return this.http.post(environment.campaignDataUrlPrefix + "/api/i18n/", data);
    }


    downloadCampaignData(campaignId: string) {
        return this.http.get(`${environment.campaignDataUrlPrefix}/api/download/campaignData/${campaignId}`, { responseType: "blob" });
    }

}
