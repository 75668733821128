import { Component, Input, forwardRef, ElementRef, Output, EventEmitter, HostListener } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
    selector: "app-fontpicker-input",
    templateUrl: "fontpicker-input.component.html",
    styleUrls: ["fontpicker-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FontPickerComponent),
            multi: true,
        },
    ],
})
export class FontPickerComponent implements ControlValueAccessor {
    constructor(private eRef: ElementRef) { }

    @Input() labelText: string;
    @Input() fontOptions: any;
    @Input() fontSizes: any = [{ id: "1", name: "10 px", value: "" },
        { id: "2", name: "20 px", value: "" },
        { id: "4", name: "30 px", value: "" }];
    @Input() showFontSizeField: string;
    @Input() showFontOptionField = "name";
    @Input() selectedFontFamilyItem = null;
    @Input() selectedselectedFontSizeItem = null;

    @Output() onFontChange = new EventEmitter<string>();
    @Output() onSizeChange = new EventEmitter<string>();
    @HostListener("document:click", ["$event"]) onClick() {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.dropDownFontOpened = false;
            this.dropDownFontSizeOpened = false;
        }
    }


    val: any = ""; // this is the updated value that the class accesses
    filename = "";

    dropDownFontOpened = false;
    dropDownFontSizeOpened = false;

    onChange: any = () => { };
    onTouch: any = () => { };

    set value(val) {
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }

    get value() {
        return this.val;
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.value = value;
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    toogleFontDropDown() {
        this.dropDownFontOpened = !this.dropDownFontOpened;
    }

    onFontItemSelected(event) {
        this.selectedFontFamilyItem = event.name;
        this.toogleFontDropDown();
        this.onFontChange.emit(event.name);
    }

    toogleFontSizeDropDown() {
        this.dropDownFontSizeOpened = !this.dropDownFontSizeOpened;
    }

    onFontSizeItemSelected(event) {
        this.selectedselectedFontSizeItem = event.value;
        this.toogleFontSizeDropDown();
        this.onSizeChange.emit(event.name);
    }
}
