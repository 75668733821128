export class TileElementSettings {
    fontFamily: {
        name: string;
        value: string;
    };
    fontSize: number;
    fontColor: string;
    coverImg: string;
    coverColor: string;
    position: number;
    coverType: string;

    constructor() {
        this.fontFamily = {
            name: "",
            value: "",
        };
        this.fontSize = null;
        this.fontColor = null;
        this.coverImg = "";
        this.coverColor = "";
        this.position = null;
        this.coverType = "";
    }

}


