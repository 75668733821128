<div class="app-modal-header">
    <h1 class="app-modal-title"> {{ title }}</h1>
  </div>
  
  <div class="app-modal-content">
    <app-select-input
      [labelText]="labelText"
      [options]="options"
      [(ngModel)]="selectedItem"
      [valueField]="valueField"
      [showField]="showField"
    ></app-select-input>
  
  </div>
  
  <div class="app-modal-footer">
    <app-button
      [text]="'cancel' | translate"
      [styles]="'light'"
      (output)="onCloseClick()"
    >
    </app-button>
    <app-button
      [text]="'select' | translate"
      [styles]="'primary'"
      (output)="onSelectClick()"
    >
    </app-button>
  </div>