export interface IAsset {
    key: string;
    ext: string;
    type: AssetType;
    data?: string;
    url: string;

    id?: string;
    category?: AssetCategory;
}

export class Asset {
    id?: string;
    key: string;
    ext: string;
    type: AssetType;
    url: string;
    data?: string;
    category?: AssetCategory;
}

export enum AssetType {
    JPEG = "image/jpeg",
    PNG = "image/png",
    ICON = "image/x-icon",
    SVG = "image/svg+xml",
    OTF_FONT = "application/x-font-opentype",
    WOFF_FONT = "application/font-woff",
    WOFF2_FONT = "font/woff2",
    TTF_FONT = "font/ttf",
}

export enum AssetCategory {
    IMAGE = "images",
    ICON = "icons",
    FONT = "fonts",
    DEFAULT = "defaults",
    GAME = "gameAssets"
}

export const ALLOWED_TYPES = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "image/webp",
    "image/svg+xml",
    "image/vnd.microsoft.icon",
    "image/x-icon",
    "application/pdf",
    "application/x-font-opentype",
    "application/font-woff",
    "font/woff",
    "font/woff2",
    "font/ttf",
];

export const ALLOWED_EXTENSIONS = [
    "png",
    "jpeg",
    "jpg",
    "gif",
    "svg",
    "pdf",
    "otf",
    "woff",
    "woff2",
    "ttf",
    "ico",
    "webp",
];

export class FilterColors {
    static getFilterColors() {
        return [
            { id: "b", name: "Black", value: "#333333D9" },
            { id: "w", name: "White", value: "#FFFFFFF2" },
            { id: "r", name: "Red", value: "#FF0000" },
            { id: "o", name: "Orange", value: "#FC6404" },
            { id: "y", name: "Yellow", value: "#FCD444" },
            { id: "lg", name: "Lightgreen", value: "#B4EF61" },
            { id: "g", name: "Green", value: "#029658" },
            { id: "t", name: "Turquoise", value: "#1ABC9C" },
            { id: "lb", name: "Lightblue", value: "#5BC0DE" },
            { id: "bl", name: "Blue", value: "#2D81FF" },
            { id: "v", name: "Violet", value: "#6454AC" },
            { id: "p", name: "Pink", value: "#FA38AC" },
        ];
    }

}
