<div class="pagination">
    <div class="results">
        <span>{{ items.length }}/{{ totalItems }} items</span>
    </div>

    <div class="pages">
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="prevPage()">
            <path d="M5.45543 3.63262L5.45543 1.26894C5.45543 0.868062 4.8679 0.667301 4.52427 0.950767L0.386186 4.36444C0.173171 4.54016 0.173171 4.82507 0.386187 5.0008L4.52427 8.41447C4.86789 8.69794 5.45543 8.49717 5.45543 8.09629L5.45543 5.73262L5.45543 3.63262Z" fill="black" fill-opacity="0.15"/>
        </svg>
        <div *ngFor="let page of getArrayFromPages(); let i = index" (click)="goToPage(i + 1)" class="page {{ currentPage === i + 1 ? 'active' : ''}}">{{ i + 1 }}</div>
        <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="nextPage()">
            <path d="M0.364879 3.63262L0.36488 1.26894C0.36488 0.868062 0.952417 0.667301 1.29604 0.950767L5.43413 4.36444C5.64714 4.54016 5.64714 4.82507 5.43413 5.0008L1.29604 8.41447C0.952418 8.69794 0.36488 8.49717 0.36488 8.09629L0.36488 5.73262L0.364879 3.63262Z" fill="black" fill-opacity="0.15"/>
        </svg>
    </div>
</div>