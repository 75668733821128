import { CampaignLanguage } from "@models/CampaignLanguage";
import { Asset } from "@builder-models/Asset";
import { UtilService } from "src/app/core/util-services/util.service";
import * as constants from "../../constants";
import { Outcome } from "./Outcome";

const util = new UtilService();

export enum PersonalityTestGameDataInteractionType {
    CLICK = "click",
    SWIPE = "swipe",
}

export class PersonalityTestGameDataFactory {
    static getInitial(
        quizType: PersonalityTestGameDataInteractionType,
        images: Asset[],
        languages: CampaignLanguage[]
    ): PersonalityTestGameData {
        const thankYouImage = images.find((elem) => elem.key === "thank-you");
        images = images.slice(0, 3);
        return {
            interactionType: quizType,
            questions: images.map((image, idx) => ({
                id: String(idx),
                questions: languages.map((l, jdx) => ({
                    id: String(jdx) + "-" + String(idx),
                    parentId: String(idx),
                    languageId: l.id,
                    text: `${l.name} Question ${idx} Text`,
                    imageUrl: image.url,
                })),
                answers: [
                    {
                        id: "1",
                        answers: languages.map((l, jdx) => ({
                            id: "1" + String(jdx),
                            parentId: "1",
                            imageUrl: "",
                            languageId: l.id,
                            text: `${l.name} Answer 1${idx} Text`,
                        })),
                        personalityId: "",
                    },
                    {
                        id: "2",
                        answers: languages.map((l, jdx) => ({
                            id: "2" + String(jdx),
                            parentId: "2",
                            imageUrl: "",
                            languageId: l.id,
                            text: `${l.name} Answer 2${idx} Text`,
                        })),
                        personalityId: "",
                    },
                ],
                correctAnswerId: null,
                correctAnswerIndex: null,
            })),
            outcomes: [{ outcomes: [{ image: thankYouImage.url }] }],
        };
    }
}

export class PersonalityTestGameData {
    interactionType: PersonalityTestGameDataInteractionType;
    questions: Question[];
    outcomes: any[];
}

export class Question {
    id: string = null;
    questions: DQuestion[] = [];
    answers: Answer[] = [];
    correctAnswerId: string = null;
    correctAnswerIndex: any = null;

    static getDefault(languages: CampaignLanguage[]): Question {
        return {
            id: "NEWID_" + util.generateRandomString(30),
            questions: languages.map((lang) => DQuestion.getDefault(lang)),
            answers: [],
            correctAnswerId: null,
            correctAnswerIndex: null,
        };
    }

    /** normal is without translation objects,
     * is used just for rendering question on page */
    static toNormal(q: Question, languageId: string): NormalQuestion {
        return {
            id: q.id,
            image: q.questions.find((qq) => qq.languageId === languageId)
                .imageUrl,
            text: q.questions.find((qq) => qq.languageId === languageId).text,
            answers: q.answers.map((a) => Answer.toNormal(a, languageId)),
        };
    }
}
/** normal is without translation objects,
 * is used just for rendering question on page */
export class NormalQuestion {
    id: string;
    image: string;
    text: string;
    answers: NormalAnswer[];
}

export class DQuestion {
    id: string;
    languageId: string;
    parentId: string;
    text: string;
    imageUrl: string;

    constructor(id, languageId, parentId, text, image) {
        this.id = id;
        this.languageId = languageId;
        this.parentId = parentId;
        this.text = text;
        this.imageUrl = image;
    }

    static getDefault(language: CampaignLanguage) {
        return {
            id: null,
            languageId: language.id,
            parentId: null,
            text: "Dummy question",
            imageUrl: constants.defaultImage,
        };
    }
}

export class Answer {
    id: string;
    answers: DAnswer[];
    personalityId: string;

    static getDefault(languages: CampaignLanguage[]): Answer {
        return {
            id: null,
            answers: languages.map((lang) => DAnswer.getDefault(lang)),
            personalityId: null,
        };
    }

    /** normal is without translation objects,
     * is used just for rendering question on page */
    static toNormal(a: Answer, languageId: string): NormalAnswer {
        return {
            id: a.id,
            text: a.answers.find((aa) => aa.languageId === languageId).text,
        };
    }
}

/** normal is without translation objects,
 * is used just for rendering question on page */
export class NormalAnswer {
    id: string;
    text: string;
}

export class DAnswer {
    id: string;
    languageId: string;
    parentId: string;
    text: string;

    constructor(id, languageId, parentId, text) {
        this.id = id;
        this.languageId = languageId;
        this.parentId = parentId;
        this.text = text;
    }

    static getDefault(language: CampaignLanguage) {
        return {
            id: null,
            languageId: language.id,
            parentId: null,
            text: "Dummy answer",
        };
    }
}

export class PersonalityTestOutcome {
    id: string;
    downThreshold: number;
    upThreshold: number;
    outcomes: Outcome[];
    orderNumber?: number;
}
