import { CampaignLanguage } from "@models/CampaignLanguage";
import { Asset } from "@builder-models/Asset";
import { Outcome } from "@models/game-data/Outcome";
import { UtilService } from "src/app/core/util-services/util.service";
import * as constants from "../../constants";

const util = new UtilService();

export enum GuessThePictureGameDataInteractionType {
    CLICK = "click",
}

export class GuessThePictureGameDataFactory {
    static getInitial(
        type: GuessThePictureGameDataInteractionType,
        images: Asset[],
        languages: CampaignLanguage[]
    ): GuessThePictureGameData {
        const thankYouImage = images.find((elem) => elem.key === "thank-you");
        images = images.slice(0, 3);
        return {
            interactionType: type,
            correctAnswerColor: "rgba(111, 189, 51, 1)",
            incorrectAnswerColor: "rgba(255, 95, 109, 1)",
            questions: images.map((image, idx) => ({
                id: String(idx),
                orderNumber: null,
                questions: languages.map((l, jdx) => ({
                    id: String(jdx) + "-" + String(idx),
                    parentId: String(idx),
                    languageId: l.id,
                    imageUrl: image.url,
                    correctPopupText: null,
                    correctPopupTitle: null,
                    incorrectPopupText: null,
                    incorrectPopupTitle: null,
                })),
                answers: [
                    {
                        id: "1",
                        answers: languages.map((l, jdx) => ({
                            id: "1" + String(jdx),
                            parentId: "1",
                            imageUrl: "",
                            languageId: l.id,
                            text: `${l.name} Answer 1${idx} Text`,
                        })),
                    },
                    {
                        id: "2",
                        answers: languages.map((l, jdx) => ({
                            id: "2" + String(jdx),
                            parentId: "2",
                            imageUrl: "",
                            languageId: l.id,
                            text: `${l.name} Answer 2${idx} Text`,
                        })),
                    },
                ],
                correctAnswerId: null,
                correctAnswerIndex: null,
            })),
            outcomes: [{ outcomes: [{ image: thankYouImage.url }] }],
            popupsEnabled: false,
            questionsLimit: 1,
            questionsLimitEnabled: false,
            shuffleAnswers: false,
            shuffleQuestions: false,
        };
    }
}

export class GuessThePictureGameData {
    interactionType: GuessThePictureGameDataInteractionType;
    correctAnswerColor: string;
    incorrectAnswerColor: string;
    questions: Question[];
    outcomes: any[];
    popupsEnabled: boolean;
    questionsLimit: number;
    questionsLimitEnabled: boolean;
    shuffleAnswers: boolean;
    shuffleQuestions: boolean;
}

export class Question {
    id: string = null;
    orderNumber: number = null;
    questions: DQuestion[] = [];
    answers: Answer[] = [];
    correctAnswerId: string = null;
    correctAnswerIndex: any = null;

    static getDefault(languages: CampaignLanguage[]): Question {
        return {
            id: "NEWID_" + util.generateRandomString(30),
            orderNumber: 0,
            questions: languages.map((lang) => DQuestion.getDefault(lang)),
            answers: [],
            correctAnswerId: null,
            correctAnswerIndex: null,
        };
    }

    /** normal is without translation objects,
     * is used just for rendering question on page */
    static toNormal(q: Question, languageId: string): NormalQuestion {
        return {
            id: q.id,
            image: q.questions.find((qq) => qq.languageId === languageId)
                .imageUrl,
            answers: q.answers.map((a) => Answer.toNormal(a, languageId)),
        };
    }
}

/** normal is without translation objects,
 * is used just for rendering question on page */
export class NormalQuestion {
    id: string;
    image: string;
    answers: NormalAnswer[];
}

export class DQuestion {
    id: string;
    languageId: string;
    parentId: string;
    imageUrl: string;
    correctPopupText: string = null;
    correctPopupTitle: string = null;
    incorrectPopupText: string = null;
    incorrectPopupTitle: string = null;

    constructor(id, languageId, parentId, image) {
        this.id = id;
        this.languageId = languageId;
        this.parentId = parentId;
        this.imageUrl = image;
    }

    static getDefault(language: CampaignLanguage) {
        return {
            id: null,
            languageId: language.id,
            parentId: null,
            imageUrl: constants.defaultImage,
            correctPopupText: null,
            correctPopupTitle: null,
            incorrectPopupText: null,
            incorrectPopupTitle: null,
        };
    }
}

export class Answer {
    id: string;
    answers: DAnswer[];

    static getDefault(languages: CampaignLanguage[]): Answer {
        return {
            id: null,
            answers: languages.map((lang) => DAnswer.getDefault(lang)),
        };
    }

    /** normal is without translation objects,
     * is used just for rendering question on page */
    static toNormal(a: Answer, languageId: string): NormalAnswer {
        return {
            id: a.id,
            text: a.answers.find((aa) => aa.languageId === languageId).text,
        };
    }
}

/** normal is without translation objects,
 * is used just for rendering question on page */
export class NormalAnswer {
    id: string;
    text: string;
}

export class DAnswer {
    id: string;
    languageId: string;
    parentId: string;
    text: string;

    constructor(id, languageId, parentId, text) {
        this.id = id;
        this.languageId = languageId;
        this.parentId = parentId;
        this.text = text;
    }

    static getDefault(language: CampaignLanguage) {
        return {
            id: null,
            languageId: language.id,
            parentId: null,
            text: "Dummy answer",
        };
    }
}

export class GuessThePictureOutcome {
    id: string;
    downThreshold: number;
    upThreshold: number;
    outcomes: Outcome[];
    protected?: boolean;
    orderNumber?: number;
}
