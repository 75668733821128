export class Place {
    address: string;
    zip: string;
    city: string;
    country: string;
    formattedAddress: string;

    constructor() {
        this.address = "";
        this.zip = "";
        this.city = "";
        this.country = "";
        this.formattedAddress = "";
    }
}
